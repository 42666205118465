import React, { useEffect, useRef, useState } from "react";
import "./Movimentazione.scss";
import { useNavigate } from "react-router-dom";
import DataTableSubHeader from "../DataTableSubHeader/DataTableSubHeader.jsx";
import DataDropdown from "../DataDropdown/index.jsx";
import WorkInProgress from "../WorkInProgress/index.jsx";
import { AddIcon, CloudIcon, DeleteIcon, DownloadIcon, EditIcon, EyeIcon, FilterIcon } from "../../icons/index.js";
import { SubHeaderMobileOption } from "../SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { toast } from "react-toastify";
import config from "../../config.js";

const XLSX = require("xlsx");
let sortDirection = "asc";


function Movimentazione() {
  const navigate = useNavigate();
  const [isMenuActive, setMenuActive] = useState(false);
  useState(false);

// ********************************************************** */
// NAVIGAATION

  const breadcrumbsData = [
    { id: 1, label: "Dati Archeologici", iconUrl: true},
    { id: 2, label: "Movimentazione", iconUtl: true}
  ];

  const subHeaderRightNavOptions = {
    icons: [
      { icon: <CloudIcon />, onClick: () => { console.log("Upload") }, label: "Upload" },
      { icon: <AddIcon />, label: "Add", onClick: () => { navigate("/cassette/add"); return null; }},
      { icon: <FilterIcon />, onClick:  () => { console.log("Filter") }, label: "Filter"},
      { icon: <DownloadIcon />, label: "Download", onClick:  () => { console.log("Download") }},
    ],
    components: [
      <DataDropdown defaultSelected = "PUBBLICAZIONI" />
    ]
  };

  //* ********************************************************** */

  return (
    <>
      <div className="container">
        <main className="record-main-content">
          <DataTableSubHeader
            title={"MOVIMENTAZIONI"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <WorkInProgress defaultSelected="PUBBLICAZIONI"/>
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            components={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </div>
    </>
  );
}

export default Movimentazione;
