import React, { useState, useEffect  } from "react";
import { useNavigate, suseLocation } from "react-router-dom";
import {DeleteIcon, MapIcon } from "../../../icons";
import { useAppContext } from "../../Context/AppContext";
import CustomTooltip from "../../CustomTooltip";
import "../Provenienze.scss";



const headers = [
  { key: "tipo", label: "Layer", width: "20%" },
  { key: "chiave", label: "Identificativo", width: "20%" },
  { key: "valore", label: "Valore", width: "20%" },
  ({ key: "action", label: "", width: "5%" })
]


const ProvenienzeTable = ({provenienze, handleDeleteProvenienza, edit}) => {
    const navigate = useNavigate();
    const { mapViewTarget, setMapViewTarget  } = useAppContext();

    const handleMapView = (e) => {
      const target = { layer: e.tipo, key: e.chiave, value: e.valore };
      setMapViewTarget(target);
      navigate("/MapView");
    }

    /* ADD ACTION BUTTON */
    provenienze?.forEach((provenienza) => {
      provenienza["action"]= edit 
        ? (<CustomTooltip text="Elimina provenienza">
             <button className="provenienze-icons" onClick={() => handleDeleteProvenienza(provenienza)}>
               <DeleteIcon/>
             </button>
           </CustomTooltip>)
        : (<CustomTooltip text="Visualizza su mappa">
             <button className="provenienze-icons" onClick={() => handleMapView(provenienza)}>
               <MapIcon fillColor="#e15615" />
             </button>
          </CustomTooltip>);
    });
    
    return (
      <>
        <div className="provenienze-table-container">
        <div className="">
          <header className="provenienze-table-header">           
              {headers?.map((header, index) => (
                <div
                  key={index}
                  className="provenienze-header-item" 
                  style={{maxWidth: `${header.width}`, minWidth: `${header.width}`}}
                >
                  <div className="provenienze-header-item-text">{ header.label }</div>
                </div>
              ))}       
           </header>
            { provenienze && provenienze.length > 0 
              ? 
              provenienze.map((item, index) => {
                return (
                    <div className={`provenienze-row`} key={index}>
                      {headers?.map((header, headerIndex) => (
                        <div
                          key={index + headerIndex}
                          className={`provenienze-row-item ${header.className || ""}`}
                          style={{ width: `${header.width}`}}
                        >
                          {item[header.key]}
                        </div>
                      ))}
                    </div>
                  );
              })
              :
              <div className="provenienze-row">
                <div className="provenienze-row-item" style={{width: "100%"}}>Nessun dato presente</div>
              </div>
            }
        </div>
        </div>
      </> 
    );
  }


export default ProvenienzeTable;