import { useEffect, useState } from "react";
import { associateRepertiJson } from "../constant";
import InputAndSelect from "../InputAndSelect/InputAndSelect";

import "./AssociationSection.scss";

const AssociationSection = ({ headers, handleChange }) => {
  const [assocData, setAssocData] = useState({});

  const onChange = (selectedOption, label) => {
    setAssocData({ ...assocData, [label]: { index: selectedOption?.index } });
    console.log("selected-assoc---->>>", {
      ...assocData,
      [label]: { index: selectedOption?.index },
    });
  };

  const onInputChange = (e, label) => {
    setAssocData({ ...assocData, [label]: { value: e.target.value } });
    console.log("input---->>>", {
      ...assocData,
      [label]: { value: e.target.value },
    });
  };

  useEffect(() => {
    handleChange(assocData);
  }, [assocData, handleChange]);

  return (
    <div className="association-section-container">
      {associateRepertiJson.map((assoc, index) => (
        <div key={index} className="association-section-wrapper">
          <InputAndSelect
            options={headers}
            displayAttr="value"
            label={assoc.label}
            attr={assoc.attr}
            placeholder={`Select ${assoc.label}`}
            onChange={onChange}
            onInputChange={onInputChange}
          />
        </div>
      ))}
    </div>
  );
};

export default AssociationSection;
