export const LogOutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.88023 12.07C7.88023 11.66 8.22023 11.32 8.63023 11.32H14.1102V2.86C14.1002 2.38 13.7202 2 13.2402 2C7.35023 2 3.24023 6.11 3.24023 12C3.24023 17.89 7.35023 22 13.2402 22C13.7102 22 14.1002 21.62 14.1002 21.14V12.81H8.63023C8.21023 12.82 7.88023 12.48 7.88023 12.07Z"
      fill="#6E6E6E"
    />
    <path
      d="M20.5396 11.54L17.6996 8.69003C17.4096 8.40003 16.9296 8.40003 16.6396 8.69003C16.3496 8.98003 16.3496 9.46003 16.6396 9.75003L18.1996 11.31H14.0996V12.81H18.1896L16.6296 14.37C16.3396 14.66 16.3396 15.14 16.6296 15.43C16.7796 15.58 16.9696 15.65 17.1596 15.65C17.3496 15.65 17.5396 15.58 17.6896 15.43L20.5296 12.58C20.8296 12.3 20.8296 11.83 20.5396 11.54Z"
      fill="#6E6E6E"
    />
  </svg>
);
