import React, { useState, useEffect  } from "react";
import ThreeScene from "../ThreeScene/ThreeScene";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import  config from "../../config";
import { AddIcon, CrossIcon, EyeIcon } from "../../icons";
import ImageView from "./ImageView";

const ImageComponent = ({ src, imgClass, alt }) => (
  <div className="image-wrapper">
    <img loading="lazy" src={src} className={imgClass} alt={alt} />
  </div>
);


export default function ImageGallery({
      disabled,
      immagini,
      send,
      repertoId
}) {
    const [immagineId, setImmagineId] = useState(null);
    const [immagineIds, setImmagineIds] = useState([]);
    const [images, setImages] = useState([]);
    const [imageId, setImageId] = useState(null);
    const [imageIds, setImageIds] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [nonImageUrls, setNonImageUrls] = useState([]);
    const [enlargedImage, setEnlargedImage] = useState({});
    const [blobObject, setBlobObject] = useState([]);
    const [isBlobLoaded, setIsBlobLoaded] = useState(false);
    const [imageLink, setImageLink] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const navigate = useNavigate();


    const handleEnlargeImage = (idx) => {
      setEnlargedImage(idx);
      setModalVisible(true);
    };

    const handleClose = () => {
      setModalVisible(false);
    };


      //***** TEMPORARY */

    useEffect(() => {
      let ids = [];
       if(Array.isArray(immagini)) immagini.forEach((item) => {
        const id = item.immagineId.replace(/^"|"$/g, "");
        ids.push({
          id: item.id,
          immagineId: item.immagineId,
          materialId: item.immagineId,
        });
      });
      setImageIds(ids);
      
      }, []);
   //******* */

    useEffect(() => {
      if (isBlobLoaded) return;
      const apiUrl = config.archeoBaseUrl + "/api/private/v3/file/download/";
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
          accept: "*/*",
        },
        redirect: "follow",
      };
  
      if (imageIds.length === 0) return;
  
      const fetchBlobData = async () => {
        const blobs = [];
        const imageUrls = [];
        const hyphenUrls = [];
        const noHyphenUrls = [];
  
        try {
          for (const id of imageIds) {
            const response = await fetch(
              `${apiUrl}${id.immagineId}`,
              requestOptions
            );
  
            const url = response.url;
            imageUrls.push(url);
  
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
  
            const result = await response.text();
            blobs.push(result);
  
            noHyphenUrls.push({
              url: url,
              immagineId: id.immagineId,
              imageId: id.id,
              materialId: id.materialId,
            });
          }
  
          setBlobObject(blobs);
          setIsBlobLoaded(true);
          setImageLink(noHyphenUrls);

        } catch (error) {
          toast.error("Error fetching blob data:", error);
        }
      };
  
      fetchBlobData();
    }, [imageIds/* , isBlobLoaded */]);
  
    useEffect(() => {
      if (send) {
        saveImageReference();
      }
    }, [send]);
  
    function handleFileUpload(ev) {
      const files = ev.target.files;
      if (files.length) {
        Array.from(files).forEach((file) => {
          const reader = new FileReader();
          reader.onload = function (e) {
            setUploadedImages((currentImagesArray) => [
              ...currentImagesArray,
              e.target.result,
            ]);
          };
          reader.readAsDataURL(file);
        });
      }
  
      const apiKey = localStorage.getItem("authToken");
  
      const myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${apiKey}`);
  
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append("file", file);
      });
  
      formData.append("idCassetta", 0);
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };
  
      fetch(config.archeoBaseUrl + "/api/private/v3/file/upload", requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            navigate("/");
            throw new Error("Your session has ended. Redirecting you to the login page...");
          } else {
            throw new Error("Network response was not ok");
          }
        }
        return response.json(); // Make sure to return the response JSON if successful
      })
        .then((result) => {
          const immagineId = result.id;
          setImmagineId(immagineId);
          setImmagineIds((prevIds) => [...(prevIds || []), immagineId]);
          toast.success("Image uploaded successfully!");
        })
        .catch((error) => {
          toast.error("Error uploading Image:", error);
        });
    }
  
    const apiKey = localStorage.getItem("authToken");
    useEffect(() => {
      if (imageId !== null) {
        const myHeaders = new Headers();
        myHeaders.append("accept", "*/*");
        myHeaders.append("Authorization", `Bearer ${apiKey}`);
  
        const requestOptions = {
          method: "DELETE",
          headers: myHeaders,
          redirect: "follow",
        };
  
        fetch(
          config.archeoBaseUrl + `/api/private/v3/immagine/reperto/delete/${imageId}`,
          requestOptions
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            toast.success(`Image with image id ${imageId} deleted successfully`);
          })
          .catch((error) => {
            toast.error("Error deleting image:", error);
          });
      }
    }, [imageId]);
  
    // Rendering Images
    function saveImageReference() {
      if (immagineId) {
        const apiKey = localStorage.getItem("authToken");
  
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${apiKey}`);
  
        immagineIds.forEach((immagineId) => {
          const body = JSON.stringify({
            immagineId: immagineId,
            repertoId
          });
  
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: body,
            redirect: "follow",
          };
  
          return fetch(
            config.archeoBaseUrl + "/api/private/v3/immagine/reperto/save",
            requestOptions
          )
            .then((response) => {
              if (!response.ok) {
                if(response.status === 401){
                  navigate("/");
                  throw new Error("Your session has ended. Redirecting you to the login page...");
                }else{
                  throw new Error("Network response was not ok");
                }
              }
            })
            .then((data) => {
              return data;
            })
            .catch((error) => {
              toast.error("Error saving image:", error);
            });
        });
      }
    }
  
    useEffect(() => {
      if (!isBlobLoaded) return;
      if (!apiKey) return;
  
      const fetchImages = async () => {
        // Use a for...of loop to handle asynchronous operations correctly
        setImages([]); // Clear the images array
        for (const item of imageLink) {

          try {
            const response = await fetch(item.url, {
              headers: {
                Authorization: `Bearer ${apiKey}`,
              },
            });
  
            if (!response.ok) {
              throw new Error(`Failed to fetch image from ${item.url}`);
            }
  
            const arrayBuffer = await response.arrayBuffer();
            const blob = new Blob([arrayBuffer]);
  
            // Check the first few bytes of the blob to determine if it's an image
            const fileHeader = new Uint8Array(
              await blob.slice(0, 4).arrayBuffer()
            );
  
            // PNG file signature: 89 50 4E 47
            const isPNG =
              fileHeader[0] === 0x89 &&
              fileHeader[1] === 0x50 &&
              fileHeader[2] === 0x4e &&
              fileHeader[3] === 0x47;
  
            // JPG file signature: FF D8 FF
            const isJPG =
              fileHeader[0] === 0xff &&
              fileHeader[1] === 0xd8 &&
              fileHeader[2] === 0xff;
  
            // Extract immagineId from URL (strip quotes)
            const baseUrl =
              config.archeoBaseUrl + "/api/private/v3/file/download/";
            const urlId = item.url.replace(baseUrl, "").replace(/"/g, "");
  
            // Determine the file type
            let fileType = "unknown";
            if (isPNG || isJPG) {
              fileType = "image";
            } else if (
              fileHeader[0] === 0x4f &&
              fileHeader[1] === 0x62 &&
              fileHeader[2] === 0x6a &&
              fileHeader[3] === 0x20
            ) {
              // Check for OBJ file signature (example, may vary)
              fileType = "obj";
            }
  
            // Find matching metadata
            const matchingMetadata = imageLink.find(
              ({ immagineId }) => immagineId.replace(/"/g, "") === urlId
            );
  
            if (isPNG || isJPG) {
              if (matchingMetadata) {
                const imageUrl = URL.createObjectURL(blob);
  
                setImages((prev) => [
                  ...prev,
                  {
                    url: imageUrl,
                    imageId: item.imageId,
                    immagineId: matchingMetadata.immagineId,
                    fileType: fileType,
                  },
                ]);
              }
            } else {
              if (matchingMetadata) {
                setNonImageUrls((prev) => [
                  ...prev,
                  {
                    url: item.url,
                    imageId: item.imageId,
                    immagineId: matchingMetadata.immagineId,
                    fileType: fileType,
                  },
                ]);
              } else {
                console.log(
                  "No matching metadata found for non-image file, skipping."
                );
              }
            }
          } catch (error) {
            toast.error("Error fetching images:", error);
          }
        }
      };
      if (imageLink && apiKey) {
        fetchImages();
      }
    }, [/* isBlobLoaded, */ imageLink /*, apiKey */ ]);
  
    const handleDeleteImage = (imageId, index) => {
      setImages((currentImagesList) => {
        const updatedImages = [...currentImagesList];
        updatedImages.splice(index, 1);
        return updatedImages;
      });
      setImageId(imageId);
    };
  
    const handleDeleteUpload = (index) => {
      setUploadedImages((currentImagesList) => {
        const updatedImages = [...currentImagesList];
        updatedImages.splice(index, 1);
        return updatedImages;
      });
      setImmagineId(null);
    };
  
    return (
      <>
        <section className="main-section">
          <p className="instructions">
            Images{" "}
            {!disabled && (
              <span>
                (allowed file formats are .png, .jpg and .zip maximum upload size:
                3 mb)
              </span>
            )}
          </p>
          <div className="images-container">
            {uploadedImages.map((imageLink, imageIdx) => (
              <div key={imageIdx} className="image-set">
                <ImageComponent
                  src={imageLink}
                  imgClass="main-image"
                  alt={`upload-${imageIdx + 1}`}
                />
                {!disabled && (
                  <div
                    className="icon"
                    onClick={() => handleDeleteUpload(imageId, imageIdx)}
                  ><CrossIcon/></div>
                )}
              </div>
            ))}
            {images.map((url, idx) => (
              <div
                key={idx}
                style={{
                  width: "120px",
                  height: "120px",
                }}
                className="image-set"
              >
                <button
                  onClick={() => handleEnlargeImage(idx)}
                  style={{
                    border: "none",
                    position: "absolute",
                    top: -10,
                    left: -10,
                    cursor: "pointer",
                    zIndex: 1,
                    backgroundColor: "transparent",
                  }}
                >
                  <EyeIcon height="30px" width="30px" />
                </button>
                <img
                  src={url.url}
                  style={{
                    width:  "120px",
                    height:"120px",
                  }}
                  className="main-image"
                  alt={`Image ${imageIds[idx]}`}
                />
  
                {!disabled && (
                  <div
                    className="icon"
                    onClick={() => handleDeleteImage(images[idx].imageId, idx)}
                  ><CrossIcon/></div>
                )}
              </div>
            ))}
  
            
            <ThreeScene
              imageLink={imageLink}
              imageIdWithoutHyphen={imageIds}
              nonImageUrls={nonImageUrls}
            />
  
  
            {!disabled && (
              <div className="single-image-wrapper">
                <label htmlFor="reperti-image">
                  <div
                    className="last-image"
                  ><AddIcon/></div>
                </label>
                <input
                  id="reperti-image"
                  hidden
                  type="file"
                  onChange={handleFileUpload}
                  accept="image/*,.zip"
                  multiple
                />
              </div>
            )}
          </div>
        </section>
        <ImageView show={modalVisible} handleClose={handleClose} image={images[enlargedImage]} imageId={enlargedImage} />
  
        <style jsx>{`
          .images-divider {
            border-top: 1px solid #dddddd;
            align-self: stretch;
            width: 100%;
          }
          .main-section {
            display: flex;
            width: 100%;
            flex-direction: column;
            padding: 24px 18px;
          }
          .instructions {
            color: #000;
            letter-spacing: 0.36px;
            width: 100%;
            font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica,
              sans-serif;
          }
          @media (max-width: 520px) {
            .instructions {
              max-width: 100%;
            }
          }
          .images-container {
            align-self: start;
            display: flex;
            padding: 20px;
            gap: 16px;
            width: 100%;
            flex-wrap: wrap;
          }
          .image-set {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            border-radius: 6px;
            border: 1px solid rgba(221, 221, 221, 1);
            position: relative;
            width: 120px;
            height: 120px;
            padding: 16px 20px;
          }
          .main-image {
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
          .icon {
            aspect-ratio: 1;
            object-fit: auto;
            object-position: center;
            position: absolute;
            top: -10px;
            right: -12px;
            width: 24px;
            height: 24px;
            z-index: 1;
            cursor: pointer;
          }
          .single-image-wrapper {
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            border: 1px solid rgba(221, 221, 221, 1);
            background-color: #fff;
            display: flex;
            width: 120px;
            height: 120px;
            padding: 16px 20px;
          }
          .last-image {
            aspect-ratio: 1;
            object-fit: auto;
            object-position: center;
            width: 16px;
            fill: #e15615;
            cursor: pointer;
          }
        `}</style>
      </>
    );
  }
  

  