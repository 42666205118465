export const InfoIcon = ({
  height = "24px",
  width = "24px",
  fillColor = "#fff"

}) => {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="none"
      height={height}
      width={width}
    >
      <path 
        fill={fillColor}
        fillRule="evenodd"
        d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256s114.6 256 256 256zm-40-176h24v-64h-24c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-80c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z" 
        clipRule="evenodd"
        />
    </svg>
  );
};
