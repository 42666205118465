// import regex from "../../regex";

const validateRaccolte = (data) => {
  let issues = [];
  if(!Array.isArray(data) || data.length === 0) {
    issues.push("La cassetta deve contenere almeno una raccolta");
  } else {
    const weak = data.find(raccolta => typeof raccolta.nome !== "string" || raccolta.nome.length === 0);
    if(weak) issues.push(`Le raccolte devono avere un  'nome'`); 
    const weak2 = data.find( raccolta => Array.isArray(raccolta.provenienze) === false || raccolta.provenienze.kenght === 0);
    if(weak2) issues.push(`Le raccolte devono contenere almeno un dato di provenienza`); 
  }
  return issues;
}

export default validateRaccolte;






