import { useNProgress } from '@tanem/react-nprogress'
import React from 'react'
import './ProgressBar.css'
import Bar from './Bar'
import Container from './Container'
import Spinner from './Spinner'

export const ProgressBar = ({ isAnimating }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  })

  return (
    <Container animationDuration={animationDuration} isFinished={isFinished}>
      <Bar animationDuration={animationDuration} progress={progress} />
      <Spinner />
    </Container>
  )
}
