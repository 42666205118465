import React, { useEffect, useState } from "react";
import "./ItemsTable.scss";
import Pagination from "../Pagination/Pagination";
import { FiltersIcon } from "../../icons";

function ItemsTable({
  headers,
  data,
  classNames,
  isEditing = false,
  filterValues,
  currentPage,
  recordsPerPage,
  totalRecords,
  onPageChange,
  onRecordsPerPageChange,
  callBackSort,
}) {
  const {
    cassetteRowClass = "",
    cassetteHeaderClass = "",
    cassetteRowContainerClass = "",
  } = classNames || {};

  const [tableData, setTableData] = useState(data || []);
  const [isSortActive, setSortActive] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentHeaderKey, setCurrentHeaderKey] = useState("");
  const [currentHeaderLid, setCurrentHeaderLid] = useState("");
  const [isHeaderLidPresent, setHeaderLidPresent] = useState(false);

  useEffect(() => {
    data && setTableData([...data]);
  }, [data]);

  // If no headers or data are available, return null
  if (!headers || !data || data.length === 0) {
    // Remove "actions" from headers
    const filteredHeaders = headers.filter(
      (header) => header.key !== "actions"
    );
    return (
      <div className="dt-container">
        <div className="dt-sub-container">
          <p className="no-data-message">No data found!</p>
        </div>
      </div>
    );
  }

  const handleSort = (key = "") => {
    if (data) {
      callBackSort(key);
    }
    // setTableData([...data]);
  };
  function handlePopUp(headerKey, lidvalue) {
    setPopupOpen(!isPopupOpen);
    setCurrentHeaderKey(headerKey);
    setCurrentHeaderLid(lidvalue);
  }

  function handleClosePopup() {
    setPopupOpen(false);
  }

  return (
    <div className="tbl-container">
      <div className="tbl-scrollable-content">
        <div className="tbl-sub-container">
          <header className="tbl-header">
            {headers?.map((header, index) => (
              <div
                key={index}
                className="tbl-header-item"
                style={{
                  maxWidth: `${header.width}`,
                  minWidth: `${header.width}`,
                }}
              >
                <div className="tbl-header-item-text">{header.label}</div>
                {header.sortable === true && (
                  <div
                    loading="lazy"
                    //src={FiltersIcon}
                    alt=""
                    className="tbl-header-item-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSort(header?.key)}
                  >
                    <FiltersIcon />
                  </div>
                )}
              </div>
            ))}
          </header>
          {tableData?.map((item, index) => {
            return item[filterValues?.label]?.value === filterValues?.value ? (
              <div key={index} className="tbl-row">
                {isEditing && (
                  <div className="checkbox-wrapper">
                    <div className="checkbox" />
                  </div>
                )}
                {headers?.map((header, headerIndex) => (
                  <div
                    key={`${item.id || index}-${headerIndex}`}
                    className={`tbl-row-item ${header.className || ""}`}
                    style={{
                      maxWidth: `${header.width}`,
                      minWidth: `${header.width}`,
                    }}
                  >
                    {item[header.key]?.component
                      ? item[header.key]?.component
                      : item[header.key]?.value}
                    {header.isGisReference === true && // GIS REFERENCE ONLY
                      item[header.key]?.value !== "" && (
                        <div
                          className="tbl-row-item-icon"
                          onClick={() =>
                            handlePopUp(header.key, item[header.key].value)
                          }
                          style={{ cursor: "pointer" }} // Change cursor to hand pointer
                        >
                          {/*<EyeIcon/> */}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            ) : (
              <></>
            );
          })}
        </div>{" "}
        {/* tbl-sub-container */}
      </div>{" "}
      {/* tbl-scrollable-content */}
      <div className="tbl-fixed-bottom">
        <Pagination
          currentPage={currentPage}
          recordsPerPage={recordsPerPage}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
          onRecordsPerPageChange={onRecordsPerPageChange}
        />
      </div>{" "}
      {/* tbl-fixed-bottom */}
    </div>
  );
}

export default ItemsTable;
