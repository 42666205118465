export const HandIcon = ({
  height = "24px",
  width = "24px",
  fillColor = "#fff",
}) => {
  return (
    <svg
      style={{ height, width }}
      viewBox="0 0 24 24"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.37501 0.984375C8.88751 1.10625 8.45626 1.47187 8.23126 1.95938C8.10939 2.22187 8.10939 2.27812 8.08126 7.84688C8.06251 10.9313 8.04376 13.425 8.03439 13.3688C8.02501 13.3219 7.99689 12.9938 7.95939 12.6469C7.86564 11.6344 7.75314 11.475 6.61876 10.5563C5.95314 10.0219 5.41876 9.90938 4.76251 10.1438C4.09689 10.3875 3.69376 10.9969 3.68439 11.7188C3.68439 12.2344 3.80626 12.5156 4.26564 13.0594C4.60314 13.4531 4.63126 13.5 5.16564 14.9625C5.85939 16.8563 6.39376 18.1125 6.69376 18.5719C6.90939 18.9094 7.32189 19.3406 8.13751 20.0813C8.34376 20.2688 8.77501 20.6719 9.10314 20.9906L9.69376 21.5625V22.05C9.69376 22.3875 9.72189 22.5938 9.78751 22.7156C9.96564 23.0625 9.89064 23.0531 14.1938 23.0531C17.6438 23.0531 18.1406 23.0438 18.3094 22.9594C18.5906 22.8281 18.6844 22.5844 18.6844 21.9656V21.4406L19.0781 20.6719C19.4625 19.9125 19.8656 18.8438 20.0344 18.0938C20.3344 16.7719 20.4 14.9438 20.2219 12.45C20.1656 11.7094 20.1094 10.6219 20.0813 10.0313C20.025 8.84063 19.9781 8.6625 19.5656 8.23125C19.275 7.92188 18.9656 7.77188 18.525 7.74375C18.0938 7.71563 17.7563 7.79063 17.475 7.9875C17.25 8.14687 17.2031 8.12813 17.2031 7.9125C17.2031 7.67813 16.95 7.20938 16.7063 6.975C16.2188 6.50625 15.4688 6.36563 14.8688 6.6375C14.7094 6.70313 14.5406 6.80625 14.4844 6.85313C14.4281 6.90938 14.3719 6.9375 14.3625 6.91875C14.3531 6.90938 14.2781 6.77813 14.1938 6.6375C13.6594 5.71875 12.45 5.49375 11.6719 6.15L11.4375 6.35625V4.37813C11.4375 1.99688 11.4281 1.95 10.9219 1.44375C10.4625 1.00312 9.95626 0.84375 9.37501 0.984375Z"
        fill={fillColor}
      />
    </svg>
  );
};
