export const MapIcon = ({
  height = "24px",
  width = "24px",
  fillColor = "#fff",
}) => {
  return (
    <svg
      style={{ height, width }}
      viewBox="0 0 24 24"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.15 7.48999C8.59 7.48999 8.15 7.93999 8.15 8.48999C8.15 9.03999 8.6 9.48999 9.15 9.48999C9.7 9.48999 10.15 9.03999 10.15 8.48999C10.15 7.93999 9.7 7.48999 9.15 7.48999Z"
        fill={fillColor}
      />
      <path
        d="M21.46 5.04C20.62 3.09 18.77 2 16.19 2H7.81C4.6 2 2 4.6 2 7.81V16.19C2 18.77 3.09 20.62 5.04 21.46C5.23 21.54 5.45 21.49 5.59 21.35L21.35 5.59C21.5 5.44 21.55 5.22 21.46 5.04ZM10.53 12.24C10.14 12.62 9.63 12.8 9.12 12.8C8.61 12.8 8.1 12.61 7.71 12.24C6.69 11.28 5.57 9.75 6 7.93C6.38 6.28 7.84 5.54 9.12 5.54C10.4 5.54 11.86 6.28 12.24 7.94C12.66 9.75 11.54 11.28 10.53 12.24Z"
        fill={fillColor}
      />
      <path
        d="M19.47 20.53C19.69 20.75 19.66 21.11 19.39 21.26C18.51 21.75 17.44 22 16.19 22H7.81C7.52 22 7.4 21.66 7.6 21.46L13.64 15.42C13.84 15.22 14.15 15.22 14.35 15.42L19.47 20.53Z"
        fill={fillColor}
      />
      <path
        d="M22 7.81002V16.19C22 17.44 21.75 18.52 21.26 19.39C21.11 19.66 20.75 19.68 20.53 19.47L15.41 14.35C15.21 14.15 15.21 13.84 15.41 13.64L21.45 7.60002C21.66 7.40002 22 7.52002 22 7.81002Z"
        fill={fillColor}
      />
    </svg>
  );
};
