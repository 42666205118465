import { OptionCard } from "../optionCard/optionCard";

export const SubHeaderMobileOption = ({
  components = [],
  icons = [],
  buttons = [],
  onCrossClick = () => {},
  title = "",
}) => {
  return (
    <OptionCard
      className="subheader-right-navbar-options-wrapper"
      onCrossClick={onCrossClick}
      title={title}
    >
      {Boolean(components?.length) && (
        <div className="subheader-right-navbar-component">
          {[...components]}
        </div>
      )}
      {Boolean(icons?.length) && (
        <div className="subheader-right-navbar-icons">
          {icons.map(({ icon, onClick = () => {}, label }) => (
            <span className="subheader-right-navbar-options" onClick={onClick}>
              {icon}
              {label}
            </span>
          ))}
        </div>
      )}
      {Boolean(icons?.length) && Boolean(buttons?.length) && <div className="separator-line"></div>}
      {Boolean(buttons?.length) && (
        <div className="subheader-right-navbar-component">
          {[...buttons]}
        </div>
      )}
    </OptionCard>
  );
};
