import regex from "../../regex";

const validateCassetta = (data) => {
  let issues = [];
  if(regex.cassettaId.test(data["id"]) === false) issues.push("Il campo 'ID' della cassetta è assente o non valido");
  if(!Array.isArray(data.groups) || data.groups.length === 0) issues.push("La cassetta deve essere associata ad almeno un gruppo di lavoro");
  return issues;
}

export default validateCassetta;
