import Select from "react-select";
import "./LayerMatchSection.scss";

const LayerMatchSection = ({
  selectedComune,
  chiave,
  tipo,
  selectedCrs,
  handleCrsChange,
  handleInputChange,
}) => {
  const crsArray = ["EPSG:4326", "EPSG:32632", "EPSG:32633", "EPSG:3857"];
  return (
    <div className="layer-match-container">
      <div className="comune-wrapper">
        <p>Comune/sito: </p>
        <p>{selectedComune?.nome}</p>
      </div>

      <div className="chiave-wrapper">
        <label htmlFor="chiave">Chiave di associazione GIS </label>
        <input type="text" name="chiave" value={chiave} onChange={handleInputChange} />
      </div>

      <div className="crs-wrapper">
        <p>CRS: </p>
        <div>
          <Select
            options={crsArray.map((crs) => ({ value: crs, label: crs }))}
            value={selectedCrs}
            onChange={handleCrsChange}
          />
        </div>
      </div>

      <div className="tipo-wrapper">
        <label htmlFor="tipo">Tipo: </label>
        <input type="text" name="tipo" value={tipo} onChange={handleInputChange} />
      </div>
    </div>
  );
};

export default LayerMatchSection;
