import regex from "../../regex";

const validateProvenienza = (data) => {
  let issues = [];
  if(!data["comuneId"]) issues.push("É necessario specificare il comune di provenienza");
  if(!data["tipo"]) issues.push("É necessario specificare il tipo di layer");
  if(!data["valore"]) issues.push("É necessario specificare il valoer del campo ${data.chiave}");
  return issues;
}

export default validateProvenienza;
