// ThreeScene.js
import React, { useEffect, useState } from "react";
import ThreeJSContainer from "./ThreeJSContainer";
import JSZip from "jszip";
import { createExtractorFromData } from "node-unrar-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import { useNavigate } from "react-router-dom";

const ThreeScene = ({imageIdWithoutHyphen, nonImageUrls }) => {
  const [objUrls, setObjUrls] = useState([]);
  const [mtlUrls, setMtlUrls] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [imageIdToDelete, setImageIdToDelete] = useState(null);
  const [deleteFunction, setDeleteFunction] = useState(false);
  const [imageId, setImageId] = useState();
  const apiKey = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const fetchAndExtractFiles = async () => {
    try {
      if (Array.isArray(nonImageUrls)) {
        const extractedUrls = await Promise.all(
          nonImageUrls.map(async ({ url, imageId }) => {
            setImageId(imageId);
            
            try {
              const response = await fetch(url, {
                headers: {
                  Authorization: `Bearer ${apiKey}`,
                },
              });
              if (!response.ok) {
                throw new Error(`Failed to fetch archive from ${url}`);
              }

              const blob = await response.blob();
              const fileType = await determineFileType(blob);

              let objUrls = [];
              let mtlUrls = [];
              if (fileType === "zip") {
                const zip = await JSZip.loadAsync(blob);
                const zipEntries = Object.values(zip.files);
                await Promise.all(
                  zipEntries.map(async (zipEntry) => {
                    if (zipEntry.name.endsWith(".obj")) {
                      const objBlob = await zipEntry.async("blob");
                      objUrls.push(URL.createObjectURL(objBlob));
                    } else if (zipEntry.name.endsWith(".mtl")) {
                      const mtlBlob = await zipEntry.async("blob");
                      mtlUrls.push(URL.createObjectURL(mtlBlob));
                    }
                  })
                );
              } else if (fileType === "rar") {
                const extractor = createExtractorFromData(blob);
                const rarEntries = await extractor.extract();
                await Promise.all(
                  rarEntries.map(async (entry) => {
                    if (entry.fileName.endsWith(".obj")) {
                      const objBlob = new Blob([entry.fileData], {
                        type: "model/obj",
                      });
                      objUrls.push(URL.createObjectURL(objBlob));
                    } else if (entry.fileName.endsWith(".mtl")) {
                      const mtlBlob = new Blob([entry.fileData], {
                        type: "model/mtl",
                      });
                      mtlUrls.push(URL.createObjectURL(mtlBlob));
                    }
                  })
                );
              }

              return { objUrls, mtlUrls, imageId };
            } catch (error) {
              console.error(`Error processing URL ${url}`, error);
              return { objUrls: [], mtlUrls: [], imageId };
            }
          })
        );

        const allObjUrls = extractedUrls.flatMap((result) => result.objUrls);
        const allMtlUrls = extractedUrls.flatMap((result) => result.mtlUrls);

        setObjUrls(allObjUrls);
        setMtlUrls(allMtlUrls);
      }
    } catch (error) {
      console.error("Error fetching and extracting files", error);
    }
  };

  useEffect(() => {
    if (nonImageUrls && nonImageUrls.length > 0) {
      fetchAndExtractFiles();
    }
  }, [nonImageUrls]);

  const handleToggleSize = () => {
    setIsExpanded((prev) => !prev);
  };
  
  const handleDeleteImage = (index) => {
    // Get the imageId associated with the index
    const urlToDelete = nonImageUrls[index];
    if (urlToDelete) {
      const immagineIdToDelete = urlToDelete.imageId;
      
      setObjUrls((currentImagesList) => {
        const updatedImages = [...currentImagesList];
        updatedImages.splice(index, 1);
        return updatedImages;
      });
  
      setMtlUrls((currentMtlList) => {
        const updatedMtl = [...currentMtlList];
        updatedMtl.splice(index, 1);
        return updatedMtl;
      });
  
      setImageIdToDelete(immagineIdToDelete);
      setDeleteFunction(true); // Ensure deleteFunction is set to true
    }
  };
  

  // UseEffect hook to call deleteImage when imageIdToDelete changes
  useEffect(() => {
    if (
      imageIdToDelete !== null &&
      imageIdToDelete !== undefined &&
      deleteFunction === true
    ) {
      deleteImage(imageIdToDelete);
    }
  }, [imageIdToDelete, deleteFunction]);

  // Function to delete image from server
  const deleteImage = async (immagineIdToDelete) => {
    
    if (!immagineIdToDelete) return;

    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("Authorization", `Bearer ${apiKey}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        config.archeoBaseUrl + `/api/private/v1/immagine/reperto/delete/${immagineIdToDelete}`,
        requestOptions
      );

      if (!response.ok) {
        if(response.status === 401){
          navigate("/signin");
          throw new Error("Your session has ended. Redirecting you to the login page...");
        }else{
          throw new Error("Network response was not ok");
        }
      }
      toast.success(
        `Image with image id ${immagineIdToDelete} deleted successfully`
      );
    } catch (error) {
      toast.error(`Error deleting image: ${error.message}`);
    }
  };
  
  return (
    <div style={{ display: "flex",  alignItems: "start" }}>
      {objUrls.map((objUrl, index) => (
        <ThreeJSContainer
        key={`${objUrl}-${index}`}
          objUrl={objUrl}
          mtlUrl={mtlUrls[index] || undefined}
          isExpanded={isExpanded}
          onToggleSize={handleToggleSize}
          handleDeleteImage ={handleDeleteImage}
          index ={index}
          imageIdToDelete={imageIdToDelete}
        />
      ))}
    </div>
  );
};

const determineFileType = async (blob) => {
  const buffer = await blob.arrayBuffer();
  const bytes = new Uint8Array(buffer);

  if (bytes[0] === 0x50 && bytes[1] === 0x4b) {
    return "zip"; // ZIP file signature
  } else if (bytes[0] === 0x52 && bytes[1] === 0x61 && bytes[2] === 0x72 && bytes[3] === 0x21) {
    return "rar"; // RAR file signature
  } else {
    return "unknown";
  }
};

export default ThreeScene;
