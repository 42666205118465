import Select from "react-select";
import "./CustomReactSelect.scss";

const CustomReactSelect = ({
  options,
  value,
  displayAttr,
  placeholder,
  label,
  onChange,
}) => {
  return (
    <div className="custom-select-wrapper">
      <label className="custom-select-label" htmlFor={`${label}-input`}>
        {label}
      </label>
      <Select
        options={options}
        formatOptionLabel={(comuneId) => <p>{`${comuneId[displayAttr]}`}</p>}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomReactSelect;
