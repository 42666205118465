export const CopyIcon = ({
  height = "26px",
  width = "26px",
  fillColor = "#fff",
}) => {
  return (
    <svg 
     xmlns="http://www.w3.org/2000/svg" 
     viewBox="-0.5 -0.5 16 16" 
    fill="none" 
    stroke="#000000" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    className="feather feather-copy" 
    id="Copy--Streamline-Feather" 
    height={16} 
    width={16} >
      <desc>{"Copy Streamline Icon: https://streamlinehq.com"}
      </desc>
      <path d="M6.875 5.625h5.625s1.25 0 1.25 1.25v5.625s0 1.25 -1.25 1.25h-5.625s-1.25 0 -1.25 -1.25v-5.625s0 -1.25 1.25 -1.25" strokeWidth={1} />
      <path d="M3.125 9.375H2.5a1.25 1.25 0 0 1 -1.25 -1.25V2.5a1.25 1.25 0 0 1 1.25 -1.25h5.625a1.25 1.25 0 0 1 1.25 1.25v0.625" strokeWidth={1} />
    </svg>
  );
};
