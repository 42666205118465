export const DownloadIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6635 11.55H14.7935V15.8317L16.6252 14C16.9635 13.6617 17.5235 13.6617 17.8618 14C18.2002 14.3384 18.2002 14.8984 17.8618 15.2367L14.5368 18.55C14.1985 18.8884 13.6385 18.8884 13.3002 18.55L9.97516 15.2367C9.80016 15.0617 9.7185 14.84 9.7185 14.6184C9.7185 14.3967 9.81183 14.175 9.98683 14C10.3252 13.6617 10.8852 13.6617 11.2235 14L13.0435 15.8201V11.55H3.33683C2.77683 11.55 2.3335 11.9934 2.3335 12.5534C2.3335 19.425 7.1285 24.2201 14.0002 24.2201C20.8718 24.2201 25.6668 19.425 25.6668 12.5534C25.6668 11.9934 25.2235 11.55 24.6635 11.55Z"
      fill="#E15615"
    />
    <path
      d="M14.793 4.65503C14.793 4.1767 14.3963 3.78003 13.918 3.78003C13.4396 3.78003 13.043 4.1767 13.043 4.65503V11.5384H14.793V4.65503Z"
      fill="#E15615"
    />
  </svg>
);
