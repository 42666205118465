import React, { useState, useEffect } from "react";
import "./AddRoleModal.scss";
import { Select } from "../index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../Context/AppContext";
const AddRoleModal = ({
  isOpen,
  onClose,
  onSave,
  cassetteDropdown,
  repetiDropdown,
  pubblicazioniDropdown,
  getRoleData,
  setEditing,
  itemsToUpdate
}) => {
  const {
    singleSelect,
    multiSelectValue,
    selectCassette,
    selectReperti,
    selectPubblicazioni,
  } = useAppContext();
  const [formData, setFormData] = useState({
    rolename: "",
    authorities: [],
  });

  useEffect(() => {
      // Update authorities based on local storage values
    const authoritiesValue = [];
    // const cassette = JSON.parse(localStorage.getItem("multiSelectedCassette")) || {};
    // const repeti = JSON.parse(localStorage.getItem("multiSelectedRepeti")) || {};
    // const pubblicazioni = JSON.parse(localStorage.getItem("multiSelectedPubblicazioni")) || {};
    const cassette = selectCassette || {};
    const repeti = selectReperti || {};
    const pubblicazioni =selectPubblicazioni || {};
    // Handle cassette permissions
    if (cassette.Read) authoritiesValue.push("CASSETTA_READ");
    if (cassette.Write) authoritiesValue.push("CASSETTA_WRITE");
    if (cassette.Create) authoritiesValue.push("CASSETTA_CREATE");
    if (cassette.Delete) authoritiesValue.push("CASSETTA_DELETE");
    // if (cassette["Select All"]) {
    //   authoritiesValue.push("CASSETTA_CREATE", "CASSETTA_DELETE");
    // }

    // Handle repetit permissions
    if (repeti.Read) authoritiesValue.push("REPERTO_READ");
    if (repeti.Write) authoritiesValue.push("REPERTO_WRITE");
    if (repeti.Create) authoritiesValue.push("REPERTO_CREATE");
    if (repeti.Delete) authoritiesValue.push("REPERTO_DELETE");
    

    // Handle pubblicazioni permissions
    if (pubblicazioni.Read) authoritiesValue.push("PUBBLICAZIONE_READ");
    if (pubblicazioni.Write) authoritiesValue.push("PUBBLICAZIONE_WRITE");
    if (pubblicazioni.Delete) authoritiesValue.push("PUBBLICAZIONE_DELETE");
    if (pubblicazioni.Create) authoritiesValue.push("PUBBLICAZIONE_CREATE");

    // Set authorities in formData
    setFormData(prevData => ({
      ...prevData,
      authorities: authoritiesValue,
    }));
    
    
  }, [singleSelect,multiSelectValue, selectCassette, selectReperti,selectPubblicazioni]);

  useEffect(() => {
    if (isOpen && itemsToUpdate) {
      setFormData({
        rolename: itemsToUpdate.rolename?.value || "",
        cassette: itemsToUpdate.cassette?.value || "",
        repeti: itemsToUpdate.repeti?.value || "",
        pubblicazioni: itemsToUpdate.pubblicazioni?.value || "",
      });
    }
  }, [isOpen, itemsToUpdate]);

  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false); // State for confirmation dialog
  const [actionType, setActionType] = useState(null);

//  // clear out 
//   useEffect(() => {
//     if (isOpen) {
//       localStorage.removeItem("selectedValue");
//       localStorage.removeItem("multiSelectedValues");
//       localStorage.removeItem("multiSelectedRepeti");
//       localStorage.removeItem("multiSelectedCassette");
//       localStorage.removeItem("multiSelectedPubblicazioni");
//     }
//   }, [isOpen]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // saving role data
   const saveUserData = async () => {   
    const url = "https://archeo.loreb.it/api/private/v3/role/save";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: formData. rolename,
          authorities : formData.authorities
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/signin");
          throw new Error(
            "Your session has ended. Redirecting you to the login page..."
          );
        } else {
          throw new Error("Network response was not ok");
        }
      } else if (response.ok) {
        toast.success("Successfully added a User!");
        getRoleData()
        setEditing(false)
      }

      const apiData = await response.json();
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.error(`Error: ${error.message}`);
    }

  };

  const updateUserData = async () => {
    const url = "https://archeo.loreb.it/api/private/v3/role/update";
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: itemsToUpdate.userId,
          name: formData. rolename,
          authorities : formData.authorities
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/signin");
          throw new Error(
            "Your session has ended. Redirecting you to the login page..."
          );
        } else {
          throw new Error("Network response was not ok");
        }
      } else if (response.ok) {
        toast.success("Successfully updated a User!");
        getRoleData()
        setEditing(false)
      }

      const apiData = await response.json();
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.error(`Error: ${error.message}`);
    }

  };

  const handleSave = () => {
    
    // onSave(formData);
    onClose();
    if(itemsToUpdate){
      updateUserData()
    }else{
      saveUserData()
    }
  };

  const handleConfirmation = () => {
    if (actionType === "save") {
      handleSave();
    }
    setConfirmationDialogOpen(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationDialogOpen(false);
  };

  return (
    isOpen && (
      <div className="modal">
        <div className="modal-content">
          <h2>Add New Role</h2>
          <form className="form">
            <label>Rolename:</label>
            <input
              type="text"
              name="rolename"
              value={formData.rolename}
              onChange={handleChange}
            />

            <label>Cassette:</label>
            <Select
              options={cassetteDropdown.props.options}
              multiSelect
              Open={isOpen}
            />
            <label>Repeti:</label>
            <Select
              options={repetiDropdown.props.options}
              multiSelect
              Open={isOpen}
            />
            <label>Pubblicazioni:</label>
            <Select
              options={pubblicazioniDropdown.props.options}
              multiSelect
              Open={isOpen}
            />
          </form>
          <div className="modal-actions">
            <button className="cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button
              className="save-button"
              onClick={() => {
                setActionType("save");
                setConfirmationDialogOpen(true);
              }}
            >
              Save
            </button>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          onConfirm={handleConfirmation}
          onClose={handleCancelConfirmation}
          message={
            actionType === "delete"
              ? "Are you sure you want to delete the selected groups?"
              : "Are you sure you want to save the changes?"
          }
        />
      </div>
    )
  );
};

export default AddRoleModal;
