import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./GroupsDetailsTable.scss";
import ItemsTable from "../ItemsTable/ItemsTable";
import DataTableSubHeader from "../DataTableSubHeader/DataTableSubHeader";
import { FiltersIcon } from "../../icons";
import { Select, SubHeaderMobileOption } from "../index";
import {
  AddIcon,
  RightArrowIcon,
  TrashIcon,
  EditIcon,
  CrossIcon,
} from "../../icons";
import Dropdown from "../Dropdown/Dropdown.jsx";
import AddGroupModal from "./AddGroupModal.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog.jsx";
import axios from 'axios';
import { getGroups } from "../../api";

const selectedHeaders = [
  { key: "groupname", label: "Nome utente", width: "300px", sortable: true }, 
  { key: "members", label: "Members", width: "300px", sortable: false },
];


const groupsDropdown = (
  <Select
    options={[
      { value: "Select All", selectAll: true },
      { value: "Group 1" },
      { value: "Group 2" },
      { value: "Group 3" },
    ]}
    overlap
    multiSelect
  />
);
const data = [
  {
    id: { value: "001", component: "" },
    groupname: { value: "Group Name", component: "" },
    members: {
      value: "John Doe (You), Smith Law, Jason Momoa, Christ Pate",
      component: "",
    },
  },
  {
    id: { value: "002", component: "" },
    groupname: { value: "Group Name", component: "" },
    members: {
      value: "John Doe (You), Smith Law, Jason Momoa, Christ Pate",
      component: "",
    },
  },
  {
    id: { value: "003", component: "" },
    groupname: { value: "Group Name", component: "" },
    members: {
      value: "John Doe (You), Smith Law, Jason Momoa, Christ Pate",
      component: "",
    },
  },
  {
    id: { value: "004", component: "" },
    groupname: { value: "Group Name", component: "" },
    members: {
      value: "John Doe (You), Smith Law, Jason Momoa, Christ Pate",
      component: "",
    },
  },
];

const headers = [
  {
    label: "No.",
    key: "id",
    icon: <FiltersIcon />,
    className: "description",
  },
  {
    label: "Group Name",
    key: "groupname",
    icon: <FiltersIcon />,
    className: "description group-wrapper",
  },
  {
    label: "Members",
    key: "members",
    icon: <FiltersIcon />,
    className: "description group-wrapper",
  },
];

const breadcrumbsData = [
  {
    id: 1,
    label: `Administration ${localStorage.getItem("username")}`,
    iconUrl: true,
  },
  {
    id: 2,
    label: "GROUPS",
    iconUrl: "",
  },
];

// const memberList = (
//   <Select
//     options={[
//       { value: "Select All", selectAll: true, id:"member-0" },
//       { value: "John Doe (you)", id: "member-1" },
//       { value: "Smith Law", id: "member-2" },
//       { value: "Jason Mamoa", id: "member-3" },
//       { value: "Chris Pate", id: "member-4" },
//     ]}
//     overlap
//     multiSelect
//   />
// );

function GroupsDetailsTable() {
  const navigate = useNavigate();
  const [isEditing, setEditing] = useState(false);
  const [tableData, setTableData] = useState(data);
  const [isMenuActive, setMenuActive] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [getAPIData, setGetAPIData] = useState();
  const [isDataFetched, setDataFetched] = useState(false);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false); // State for confirmation dialog
  const [actionType, setActionType] = useState(null);
  const token = localStorage.getItem("authToken");
  const [memberOptions, setMemberOptions] = useState()
  const length =  Object.values(checkedItems).filter(value => value === true).length;
  const groupFlag = true
  // conversion of API response to the binding data structure
  const transformNewData = (groups) => {
    return groups.map((groups, index) => ({
      id: { value: (index + 1).toString().padStart(3, "0"), component: "" },
      groupname: { value: groups.name, component: "" },
    }));
  };

  const listGroups = async () => { 
    try {
      const groups = await getGroups();
      if(Array.isArray(groups)) {
        const transformedData = transformNewData(groups);
        setTableData(transformedData);
        setGetAPIData(transformedData);
          setDataFetched(true);
      } else {
        toast.error(`Unknown error listing groups`);
      }
    } catch (error) {
      if(error.response && error.response.status === 401){
        navigate("/");
        toast.error(`Authorization error`);
      } else{
        console.error("Error listing groups:", error);
        toast.error(`Communication error listing groups`);
      }
    }
  }


  // calling only once when the component mounts
  useEffect(() => {
    listGroups();
  }, [tableData.length]);

  // fetch the memeber data
  const user = localStorage.getItem("username")
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(
          `https://archeo.loreb.it/api/private/v3/user/get?username=${user}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const members = response.data.users;

        const formattedOptions = [
          { value: "Select All", selectAll: true, id: "group-0" },
          ...members.map((member, index) => ({
            value: member.username,
            id: `group-${index + 1}`,
          })),
        ];

        setMemberOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchGroups();
  }, []);
  const memberList = <Select options={memberOptions} overlap multiSelect />;


  // deletion of grops
  const handleDelete = async () => {
    const remainingData = tableData.filter(async (item, index) => {
      if (checkedItems[index]) {
        const groupNameValue = item.groupname.value;
        const url = `https://archeo.loreb.it/api/private/v3/group/delete/${groupNameValue}`;

        try {
          const response = await fetch(url, {
            method: "DELETE",
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            if (response.status === 401) {
              navigate("/signin");
              throw new Error(
                "Your session has ended. Redirecting you to the login page..."
              );
            } else {
              throw new Error("Network response was not ok");
            }
          } else if (response.ok) {
            toast.success(`Successfully deleted a group: ${groupNameValue}`);
            listGroups()
          }
        } catch (error) {
          toast.error(`Error: ${error.message}`);
        }

        return false;
      }
      return true;
    });

    // Update state with the remaining data
    setTableData(remainingData);
    setCheckedItems({});
  };

  const handleAddUser = (newUser) => {
    const newTableData = [
      ...tableData,
      {
        id: {
          value: (tableData.length + 1).toString().padStart(3, "0"),
          component: "",
        },
        groupname: { value: newUser.groupname, component: "" },
        members: { value: newUser.members, component: memberList },
        // description: { value: newUser.groups.join(", "), component: memberList },
      },
    ];
    setTableData(newTableData);
  };
  const editingOptions = (
    <div className="editing-tools-button-wrapper">
      <button
        className="action-button cancel-button"
        onClick={() => setEditing(false)}
      >
        <CrossIcon />
        <span>Cancel</span>
      </button>
      <button
        className="action-button save-button"
        onClick={() => setEditing(false)}
      >
        <AddIcon fillColor="white" />
        <span>Save</span>
      </button>
    </div>
  );

  const subHeaderRightNavOptions = {
    icons: isEditing
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => {},
            onClick: () => setEditing(false),
            label: "Back",
          },
          {
            icon: <AddIcon onClick={() => setModalOpen(true)} />,
            onClick: () => {
              setModalOpen(true);
            },
            label: "Add",
          },
          length>0 &&{
            icon: <TrashIcon onClick={() => {
              setActionType("delete");
              setConfirmationDialogOpen(true);
            }}
       />,
            onClick: () => {},
            label: "Delete",
          },
        ]
      : [
          {
            icon: <EditIcon fillColor="#E15615" height="28" width="28" />,
            onClick: () => setEditing(!isEditing),
            label: "Edit",
          },
        ],

    components: isEditing
      ? [editingOptions]
      : [
          <Dropdown
            dropdownTexts={[
              {
                label: "UTENTI",
                onClick: () => {
                  navigate("/users");
                },
              },
              {
                label: "GROUPS",
                onClick: () => {
                  navigate("/groups");
                },
              },
              {
                label: "ROLES",
                onClick: () => {
                  navigate("/roles");
                },
              },
            ]}
            defaultSelected="GROUPS"
          />,
        ],
  };
  const setComponentInData = () => {
    const newData = tableData.map((info) => ({
      ...info,
      members: { ...info.members, component: memberList },
      description: { ...info.description, component: groupsDropdown },
    }));
    setTableData(newData);
  };
  // useEffect(() => {
  //   isEditing ? setComponentInData() : setTableData(data);
  //   // eslint-disable-next-line
  // }, [isEditing]);
  useEffect(() => {
    if (isDataFetched) {
      if (isEditing) {
        setComponentInData();
      } else {
        setTableData(getAPIData);
        localStorage.removeItem("selectedValue");
        localStorage.removeItem("multiSelectedValues");
      }
    }
    // eslint-disable-next-line
  }, [isEditing]);

  const handleConfirmation = () => {
    if (actionType === "delete") {
      handleDelete();
    } else if (actionType === "save") {
      // Handle save action here
      setEditing(false);
    }
    setConfirmationDialogOpen(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationDialogOpen(false); 
  };
  return (
    <>
      <div className="container">
        <main className="group-detail-main-content">
          <DataTableSubHeader
            title={"GROUPS"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <ItemsTable
            data={tableData}
            headers={selectedHeaders}
            isEditing={isEditing}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            groupFlag={groupFlag}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            components={!isEditing ? subHeaderRightNavOptions?.components : []}
            buttons={isEditing ? [editingOptions] : []}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
        <AddGroupModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleAddUser}
          // rolesDropdown={rolesDropdown}
          memberList={memberList}
          getGroupData={listGroups}
          groupFlag={groupFlag}
          setEditing={setEditing}
        />
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          onConfirm={handleConfirmation}
          onClose={handleCancelConfirmation}
          message={
            actionType === "delete"
              ? "Are you sure you want to delete the selected groups?"
              : "Are you sure you want to save the changes?"
          }
        />
      </div>
    </>
  );
}

export default GroupsDetailsTable;
