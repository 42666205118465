export const LeftIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6664 8.74161L11.0247 7.09994L8.34974 4.42494C7.78307 3.86661 6.81641 4.26661 6.81641 5.06661V10.2583V14.9333C6.81641 15.7333 7.78307 16.1333 8.34974 15.5666L12.6664 11.2499C13.3581 10.5666 13.3581 9.43327 12.6664 8.74161Z"
        fill="#E15615"
      />
    </svg>
  );
};
