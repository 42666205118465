import axios from 'axios';
import config from '../config';

const client = axios.create({
  baseURL: `${config.archeoBaseUrl}/api/private/v3/role/`,
});

client.interceptors.request.use((conf) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    conf.headers.Authorization = `Bearer ${token}`;
    conf.headers.accept = "*/*";
  }
  return conf; // This should return conf, not config
}, (error) => {
  return Promise.reject(error);
});


// GET THE LIST OF USERS
export const getRoles = async () => {
  const response = await client.get(`/get`);
  return response.data.roles;
};

