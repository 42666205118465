const updateNestedData = (nestedData, subData, operation) => {
  let clonedParentData = JSON.parse(JSON.stringify(nestedData));
  let isUpadted = false;

  nestedData.forEach((field) => {
    if (field.acronym === subData.acronym) {
      if (operation === "add") {
        if (!isUpadted) {
          const cloneData =
            subData.duplicatedElements[subData.duplicatedElements.length - 1];
          const cloneIndex = nestedData.children.findIndex(
            (element) => element.acronym === cloneData.acronym
          );
          clonedParentData.splice(cloneIndex + 1, 0, {
            ...cloneData,
            duplicated: true,
          });
        }
        isUpadted = true;
      } else if (operation === "remove") {
        clonedParentData = clonedParentData.filter(
          (element) => element?.id !== subData.id
        );
      }
    } else if (field.children) {
      let clonedMiddleData = JSON.parse(JSON.stringify(field.children));
      field.children.forEach((child) => {
        if (child.acronym === subData.acronym) {
          if (operation === "add") {
            if (!isUpadted) {
              const cloneData =
                subData.duplicatedElements[
                  subData.duplicatedElements.length - 1
                ];
              const cloneIndex = field.children.findIndex(
                (element) => element.acronym === cloneData.acronym
              );
              clonedMiddleData.splice(cloneIndex + 1, 0, {
                ...cloneData,
                duplicated: true,
              });
            }
            isUpadted = true;
          } else if (operation === "remove") {
            clonedMiddleData = clonedMiddleData.filter(
              (element) => element?.id !== subData.id
            );
          }
        } else if (child.children) {
          let clonedInnerData = JSON.parse(JSON.stringify(child.children));
          child.children.forEach((innerChild) => {
            if (innerChild.acronym === subData.acronym) {
              if (operation === "add") {
                if (!isUpadted) {
                  const cloneData =
                    subData.duplicatedElements[
                      subData.duplicatedElements.length - 1
                    ];
                  const cloneIndex = child.children.findIndex(
                    (element) => element.acronym === cloneData.acronym
                  );
                  clonedInnerData.splice(cloneIndex + 1, 0, {
                    ...cloneData,
                    duplicated: true,
                  });
                }
                clonedInnerData.sort((a, b) => a.acronym - b.acronym);
                isUpadted = true;
              } else if (operation === "remove") {
                clonedInnerData = clonedInnerData.filter(
                  (element) => element?.id !== subData.id
                );
              }
            }
          });

          if (clonedInnerData.length !== child.children.length) {
            clonedParentData = clonedParentData.map((subField) => {
              let flag = false;

              if (subField.children) {
                subField.children.forEach((subChild) => {
                  if (subChild.children) {
                    subChild.children.forEach((subInnerChild) => {
                      if (subInnerChild.acronym === subData.acronym) {
                        flag = true;
                        return;
                      }
                    });
                  }
                });
              }

              return {
                ...subField,
                children: subField.children.map((subChild) => {
                  if (subChild.children) {
                    return {
                      ...subChild,
                      children: flag ? clonedInnerData : subChild.children,
                    };
                  } else return subChild;
                }),
              };
            });
          }
        }
      });

      if (clonedMiddleData.length !== field.children.length) {
        clonedParentData = clonedParentData.map((subField) => {
          let flag = false;
          subField.children.forEach((subChild) => {
            if (subChild.acronym === subData.acronym) {
              flag = true;
              return;
            }
          });
          if (flag) {
            return { ...subField, children: clonedMiddleData };
          } else {
            return subField;
          }
        });
      }
    }
  });

  return clonedParentData;
};

const isFieldFilled = (field) => {
  if (field.fieldType === "text-field" && field.hasOwnProperty("value")) {
    const [minLen, maxLen] = field.len.split(",");
    if (field.value.length >= minLen && field.value.length <= maxLen) {
      return true;
    } else return false;
  } else if (
    field.fieldType === "drop-down" &&
    field.hasOwnProperty("selectedOption")
  ) {
    return true;
  } else return false;
};

const checkComplete = (formFields) => {
  let requiredFilled = true;
  let recommendedFilled = true;
  let allFilled = true;

  const traverseFields = (fields, parentUse = "required") => {
    fields?.forEach((field) => {
      if (field.children) {
        traverseFields(field.children, field.use);
      } else {
        if (parentUse === "required") {
          if (field.use === "required" && !isFieldFilled(field)) {
            requiredFilled = false;
          } else if (field.use === "recommended" && !isFieldFilled(field)) {
            recommendedFilled = false;
          } else if (!isFieldFilled(field)) {
            allFilled = false;
          }
        } else {
          if (field.use === "recommended" && !isFieldFilled(field)) {
            recommendedFilled = false;
          } else if (!isFieldFilled(field)) {
            allFilled = false;
          }
        }
      }
    });
  };

  traverseFields(formFields);

  if (allFilled) {
    return "CATALOGO";
  } else if (requiredFilled && recommendedFilled) {
    return "SUFFICIENTE";
  } else if (requiredFilled) {
    return "MINIMO";
  } else {
    return "INCOMPLETO";
  }
};

const badgeColor = (status) => {
  switch (status) {
    case "CATALOGO":
      return "green";
    case "SUFFICIENTE":
      return "blue";
    case "MINIMO":
      return "orange";
    case "INCOMPLETO":
      return "#e15615d3";
    default:
      return "grey";
  }
}

const updateSelectedOption = (data, selectedOption) => {
  let selectedAcronym, selectedValue, selectedId;

  const findSelectedOption = (option) => {
    if (option.acronym) {
      selectedAcronym = option.acronym;
      selectedId = option.id;
      findSelectedOption(option.selectedOption);
    } else {
      selectedValue = option;
    }
  };

  findSelectedOption(selectedOption);

  let updatedData = JSON.parse(JSON.stringify(data));

  const updateOption = (fields) => {
    fields.forEach((field) => {
      if (field.acronym === selectedAcronym) {
        if (field.id && field.id === selectedId) {
          field.selectedOption = selectedValue;
          return;
        } else if (!field.id && !selectedId) {
          field.selectedOption = selectedValue;
          return;
        }
      } else if (field.children) {
        updateOption(field.children);
      }
    });
  };

  updateOption(updatedData.formFields);

  return updatedData;
};

const updateInputValue = (data, value) => {
  let updatedData = JSON.parse(JSON.stringify(data));
  let selectedAcronym, selectedValue, selectedId;

  const findValue = (option) => {
    if (option.acronym) {
      selectedAcronym = option.acronym;
      selectedId = option.id;
      findValue(option.value);
    } else {
      selectedValue = option;
    }
  };

  findValue(value);

  const updateValue = (fields) => {
    fields.forEach((field) => {
      if (field.acronym === selectedAcronym) {
        if (field.id && field.id === selectedId) {
          field.value = selectedValue;
          return;
        } else if (!field.id && !selectedId) {
          field.value = selectedValue;
          return;
        }
      } else if (field.children) {
        updateValue(field.children);
      }
    });
  };

  updateValue(updatedData.formFields);

  return updatedData;
};

const updateToggleStatus = (data, toggleData) => {
  let updatedData = JSON.parse(JSON.stringify(data));

  const updateValue = (fields) => {
    fields.forEach((field) => {
      if (field.acronym === toggleData.acronym) {
        field.active = toggleData.status;
        return;
      } else if (field.children) {
        updateValue(field.children);
      }
    });
  };

  updateValue(updatedData.formFields);

  return updatedData;
};

const exportJson = (data) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = "ICCD.json";

  link.click();
};

export {
  badgeColor,
  updateNestedData,
  exportJson,
  checkComplete,
  updateSelectedOption,
  updateInputValue,
  updateToggleStatus,
};
