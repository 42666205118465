export const FilterIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.033 4.78328V7.34995C24.033 8.28329 23.4497 9.44995 22.8664 10.0333L17.8497 14.4666C17.1497 15.05 16.683 16.2166 16.683 17.15V22.1666C16.683 22.8666 16.2164 23.8 15.633 24.15L13.9997 25.2C12.483 26.1333 10.383 25.0833 10.383 23.2166V17.0333C10.383 16.2166 9.91637 15.1666 9.4497 14.5833L8.90137 14.0116C8.5397 13.6266 8.4697 13.0433 8.76137 12.5883L14.7347 2.99828C14.9447 2.65995 15.318 2.44995 15.7264 2.44995H21.6997C22.983 2.44995 24.033 3.49995 24.033 4.78328Z"
      fill="#E15615"
    />
    <path
      d="M12.0756 4.23495L7.93395 10.8733C7.53729 11.515 6.62729 11.6083 6.10229 11.06L5.01729 9.91662C4.43395 9.33328 3.96729 8.28329 3.96729 7.58328V4.89995C3.96729 3.49995 5.01729 2.44995 6.30062 2.44995H11.084C11.994 2.44995 12.554 3.45328 12.0756 4.23495Z"
      fill="#E15615"
    />
  </svg>
);
