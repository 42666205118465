import { useState } from "react";
import { isEmpty } from "../helpers/utils/utils";

export const useTooltip = (term, initialState = false) => {
  const [visible, setVisible] = useState(initialState);
  const show = () => {
    if (isEmpty(term)) return;
    setVisible(true);
  };
  const hide = () => setVisible(false);
  return { visible, show, hide };
};
