import React from "react";
import "./SubHeaderRightNavOptions.scss";

function SubHeaderRightNavOptions({ icons = [], components = [] }) {
  return (
    <div className="right-container">
      <div className="image-grid">
        {icons.map(({icon=<></>, onClick = ()=>{}, label = ""}, index) => (
          <span
            key={index}
            className="grid-image"
            onClick={onClick}
          >
            {icon}
            <span className="show-preview-labels">{label}</span>
          </span>
        ))}
      </div>
      {icons.length && components.length ? <div className="divider" /> : <></>}
      {components.length ? [...components] : <></>}
    </div>
  );
}

export default SubHeaderRightNavOptions;
