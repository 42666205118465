import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomModal from "../CustomModal/CustomModal";
import Select from "react-select";
import "./style.css";
import { useAppContext } from "../../Context/AppContext";
import { getComuneLayers } from "../../../api";
import { ExclamationTriangleIcon } from "../../../icons";
import validateProvenienza from "../../../helpers/formTools/validations/provenienza/validateProvenienza"

const ProvenienzaAddModal = ({
  show,
  handleClose,
  handleAddProvenienza,
}) => {
  const navigate = useNavigate();
  const { comuni } = useAppContext();
  const [selectedComune, setSelectedComune] = useState(null);
  const [layers, setLayers] = useState([]);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [issues, setIssues] = useState([]);

  /* ALL API CALLS */

  const listComuneLayers = async (comune) => {
    try {
      const data = await getComuneLayers(comune.value);
      setLayers(data.layers);
    } catch (error) {
      if(error.response.status === 401){
        navigate("/");
        console.error(`Authorization error listing layers`);
      } else {
        toast.error(error.response && error.response.detail ? error.response.detail : `Unknown error listing layers`);
        console.error(`Authorization error slisting layers`, error);
      }
    }
  };

  /* ALL EFFECT */

  useEffect(() => {
    if (selectedComune) listComuneLayers(selectedComune);
  }, [selectedComune]);


  /* ALL HANDLERS */

  const onSelectedComune = (comune) => {
    setSelectedComune(comune);
  };

  const onSelectedLayer = (layer) => {
    setSelectedLayer(layer);
  };

  const onSelectedValue = (e) => {
    setSelectedValue(e.target.value);
  };
  /* TRANSFORMATIONS */

  const comuniOptions = comuni.map((item) => ({
    value: `${item.id}`,
    label: `${item.nome}`,
  }));

  const layersOptions = layers.filter(l => l.tipo !== "comune").map((item) => ({
    value: `${item.id}`,
    label: `${item.tipo}`,
    chiave: `${item.chiave}`
  }));


  const onAddProvenienza = (e) => {
    e.preventDefault();
    const provenienza = {
      comuneId: selectedComune.value,
      tipo: selectedLayer.label,
      chiave: selectedLayer.chiave,
      valore: selectedValue
    };
    const issues = validateProvenienza(provenienza);
    if(issues.length > 0){
      setIssues(issues);
      return;
    }
    handleAddProvenienza(provenienza);
  };

  return (
    <CustomModal isVisible={show} onClose={handleClose}>
      <div className="issue-container">
        {
          issues.length > 0 && issues.map((issue, index) => {
            return (
              <div key={index} className="issue">
                <p className="issue-icon"><ExclamationTriangleIcon /> </p>
                { issue }
              </div>
            );
          })
        }  
     </div>
      <form className="class-summary-add-modal" onSubmit={onAddProvenienza}>
        <h2 className="class-summary-add-modal-title">AGGIUNGI PROVENIENZA</h2>
        <div className="class-summary-add-modal-content">
          <div className="class-summary-add-modal-form">
            <label>Comune</label>
            <Select
              options={comuniOptions}
              required
              value={selectedComune ? selectedComune.nome : ""}
              placeholder="Select an option"
              onChange={onSelectedComune}
            ></Select>
          </div>
          <div className="class-summary-add-modal-form">
            <label>Layer</label>
            <Select
              options={layersOptions}
              required
              value={selectedLayer}
              placeholder="Select an option"
              onChange={onSelectedLayer}
            ></Select>
          </div>
          <div className="class-summary-add-modal-form">
            <label htmlFor="value">{ selectedLayer ? selectedLayer.chiave : "..."}  </label>
            <input
              type="string"
              name="value"
              value={selectedValue}
              id="value"
              placeholder=""
              required
              onChange={onSelectedValue}
            />
          </div>
        </div>
        <div className="class-summary-add-modal-btns">
          <button className="btn" type="submit">
            Add
          </button>
          <button className="btn" onClick={handleClose}>
            Cancel
          </button>
        </div> 
      </form>
    </CustomModal>
  );
};

export default ProvenienzaAddModal;
