import React from 'react';
import './CustomTooltip.scss';

const CustomTooltip = ({ children, text }) => {
  return (
    <div className="tooltip-container">
      {children}
      <span className="tooltip-text">{text}</span>
    </div>
  );
};

export default CustomTooltip;