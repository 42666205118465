export const DropdownIcon = ({ fillColor = "#E15615", className="" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.5867 15.8201H12.3567H6.74674C5.78674 15.8201 5.30674 14.6601 5.98674 13.9801L11.1667 8.80007C11.9967 7.97007 13.3467 7.97007 14.1767 8.80007L16.1467 10.7701L19.3567 13.9801C20.0267 14.6601 19.5467 15.8201 18.5867 15.8201Z"
        fill={fillColor}
      />
    </svg>
  );
};
