import httpArcheo from "../helpers/http-archeo";

export const getBibliografia = async () => {
  try {
    const response = await httpArcheo.get("/api/private/v3/bibliografia/get");
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getBibliografiaById = async (id) => {
  try {
    const response = await httpArcheo.get(
      `/api/private/v3/bibliografia/get/${id}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const newBibliografia = async (data) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/bibliografia/save",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
