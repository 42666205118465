import { useState, useCallback } from "react";
import { MinusIcon, CrossIcon } from "../../../icons";
import FileUploadSection from "../../FileUploadSection/FileUploadSection";
import { uploadFile, layerSave, getLayer } from "./apiService";
import { toast } from "react-toastify";
import SpinnerLoading from "../../SpinnerLoading/SpinnerLoading";
import LayerMatchSection from "./LayerMatchSection/LayerMatchSection";
import "./LayerUploadModal.scss";

const LayerUploadModal = ({
  title,
  selectedComune,
  closeModal,
  handleFileUpload,
}) => {
  const [uploadFileId, setUploadFileId] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [matchData, setMatchData] = useState({
    chiave: "",
    tipo: "",
    selectedCrs: { value: "EPSG:32633", label: "EPSG:32633" },
    comuneId: selectedComune?.id,
    crs: "EPSG:32633",
    zipId: null,
  });

  const handleSelectCrsChange = useCallback((selectedCrs) => {
    setMatchData((prev) => ({ ...prev, selectedCrs, crs: selectedCrs.value }));
  }, []);

  const handleInputChange = useCallback((ev) => {
    setMatchData((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  }, []);

  const handleLayerSave = useCallback(async () => {
    setIsFileUploading(true);
    try {
      const layerId = await layerSave(matchData);
      const layer = await getLayer(layerId);

      if (layer)
        toast.success(
          `${layer?.features?.length} feature sono state caricate con successo:`
        );
      console.log("layer", layer);
      closeModal();
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsFileUploading(false);
    }
  }, [matchData, closeModal]);

  const handleCSVXlsUpload = useCallback(async (ev) => {
    const files = ev.target.files;
    if (files.length) {
      setIsFileUploading(true);
      Array.from(files).forEach(async (file) => {
        const reader = new FileReader();
        reader.onload = async function () {
          try {
            const fileId = await uploadFile(file);

            setUploadFileId(fileId);
            setMatchData((prev) => ({ ...prev, zipId: fileId }));

          } catch (error) {
            toast.error(`Error: ${error.message}`);
          } finally {
            setIsFileUploading(false);
          }
        };

        if (file.name.endsWith(".zip")) {
          reader.readAsBinaryString(file);
        } else {
          console.error("Unsupported file format.");
          setIsFileUploading(false);
        }
      });
    }
  }, []);

  return (
    <section className="layer-upload-modal-container">
      <header className="modal-header">
        <div className="modal-header-content">
          <h1 className="modal-title">{title}</h1>
          <div className="modal-icons">
            <div className="modal-icon" onClick={closeModal}>
              <MinusIcon />
            </div>
            <div className="modal-icon" onClick={closeModal}>
              <CrossIcon />
            </div>
          </div>
        </div>
      </header>

      <div className="layer-upload-modal-body">
        <FileUploadSection
          handleCSVXlsUpload={handleCSVXlsUpload}
          acceptType={".zip"}
        />

        {uploadFileId ? (
          <LayerMatchSection
            selectedComune={selectedComune}
            chiave={matchData?.chiave}
            tipo={matchData?.tipo}
            selectedCrs={matchData?.selectedCrs}
            handleCrsChange={handleSelectCrsChange}
            handleInputChange={handleInputChange}
          />
        ) : null}
      </div>

      {/* footer */}
      {uploadFileId ? (
        <footer className="modal-footer">
          <button className="modal-footer-button" onClick={handleLayerSave}>
            Save
          </button>
        </footer>
      ) : null}
      <SpinnerLoading isLoading={isFileUploading} />
    </section>
  );
};

export default LayerUploadModal;
