import "./style.css";

const EditorHeader = ({
  edit = false,
  isView = true,
  title = "",
  version,
  status,
  handleClose,
  handleExport,
  handleImport,
  handleClick,
  handleSave,
  handleChangeView
}) => {
  return (
    <div className="editor-header">
      <div className="editor-header-info">
        <div className="editor-header-title-wrapper">
          <p className="editor-header-title">{title}</p>
          <p className="editor-header-version">{version}</p>
        </div>
        <p className="editor-header-status">
          LIVELLO DI COMPLETAMENTO:{" "}
          <span className="editor-header-status-badge">{status}</span>
        </p>
      </div>
      {edit &&
        (isView ? (
          <div className="editor-header-buttons">
            <button className="editor-header-btn" onClick={handleExport}>
              Esporta
            </button>
            <button className="editor-header-btn" onClick={handleChangeView}>
              Modifica
            </button>
            <button className="editor-header-btn" onClick={handleClose}>
              Chiudi
            </button>
          </div>
        ) : (
          <div className="editor-header-buttons">
            <button className="editor-header-btn" onClick={handleImport}>
              Importa
            </button>
            <button className="editor-header-btn" onClick={handleExport}>
              Esporta
            </button>
            <button className="editor-header-btn" onClick={handleSave}>
              Aggiorna
            </button>
            <button className="editor-header-btn" onClick={handleClose}>
              Annulla
            </button>
          </div>
        ))}
    </div>
  );
};

export default EditorHeader;
