import React from 'react'

const Spinner = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      right: 0,
      top: 0,
      bottom: 0,
      left: 0,
      zIndex: 1031,
    }}
  >
    <div
      style={{
        animation: '400ms linear infinite spinner',
        borderBottom: '8px solid transparent',
        borderLeft: '8px solid #29d',
        borderRadius: '50%',
        borderRight: '8px solid transparent',
        borderTop: '8px solid #29d',
        boxSizing: 'border-box',
        height: 100,
        width: 100,
      }}
    />
  </div>
)

export default Spinner