import httpArcheo from "../../../helpers/http-archeo";
import { toast } from "react-toastify";

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await httpArcheo.post(
      "/api/private/v3/file/upload",
      formData
    );
    return response?.data?.id;
  } catch (error) {
    toast.error(`Error: ${error.message}`);
    throw error;
  }
};

export const layerSave = async (data) => {
  try {
    const response = await httpArcheo.post("/api/private/v3/layer/save", data);
    return response?.data?.id;
  } catch (error) {
    toast.error(`Error: ${error.message}`);
    throw error;
  }
};

export const getLayer = async (id) => {
  try {
    const response = await httpArcheo.get(`/api/private/v3/layer/get/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(`Error: ${error.message}`);
    throw error;
  }
};

export const deleteLayer = async (id) => {
  try {
    const response = await httpArcheo.delete(`/api/private/v3/layer/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(`Error: ${error.message}`);
    throw error;
  }
};
