import React from 'react';
export const PhoneIcons = ({ fillColor = '#000' }) => (
  <svg
    style={{ height: '24', width: '24' }}
    viewBox="0 0 28 28"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8923 17.4423L10.734 19.6007C10.279 20.0557 9.55565 20.0557 9.08898 19.6123C8.96065 19.484 8.83232 19.3673 8.70398 19.239C7.50232 18.0257 6.41732 16.754 5.44898 15.424C4.49232 14.094 3.72232 12.764 3.16232 11.4457C2.61398 10.1157 2.33398 8.84398 2.33398 7.63065C2.33398 6.83732 2.47398 6.07898 2.75398 5.37898C3.03398 4.66732 3.47732 4.01398 4.09565 3.43065C4.84232 2.69565 5.65898 2.33398 6.52232 2.33398C6.84898 2.33398 7.17565 2.40398 7.46732 2.54398C7.77065 2.68398 8.03898 2.89398 8.24898 3.19732L10.9557 7.01232C11.1657 7.30398 11.3173 7.57232 11.4223 7.82898C11.5273 8.07398 11.5857 8.31898 11.5857 8.54065C11.5857 8.82065 11.504 9.10065 11.3407 9.36898C11.189 9.63732 10.9673 9.91732 10.6873 10.1973L9.80065 11.119C9.67232 11.2473 9.61398 11.399 9.61398 11.5857C9.61398 11.679 9.62565 11.7607 9.64899 11.854C9.68399 11.9473 9.71899 12.0173 9.74232 12.0873C9.95232 12.4723 10.314 12.974 10.8273 13.5807C11.3523 14.1873 11.9123 14.8057 12.519 15.424C12.6357 15.5407 12.764 15.6573 12.8807 15.774C13.3473 16.229 13.359 16.9757 12.8923 17.4423Z"
      fill="#E15615"
    />
    <path
      d="M25.6309 21.3839C25.6309 21.7106 25.5726 22.0489 25.4559 22.3756C25.4209 22.4689 25.3859 22.5622 25.3392 22.6556C25.1409 23.0756 24.8842 23.4722 24.5459 23.8456C23.9742 24.4756 23.3442 24.9306 22.6326 25.2222C22.6209 25.2222 22.6092 25.2339 22.5976 25.2339C21.9092 25.5139 21.1626 25.6656 20.3576 25.6656C19.1676 25.6656 17.8959 25.3856 16.5542 24.8139C15.2126 24.2422 13.8709 23.4722 12.5409 22.5039C12.0859 22.1656 11.6309 21.8272 11.1992 21.4656L15.0142 17.6506C15.3409 17.8956 15.6326 18.0822 15.8776 18.2106C15.9359 18.2339 16.0059 18.2689 16.0876 18.3039C16.1809 18.3389 16.2742 18.3506 16.3792 18.3506C16.5776 18.3506 16.7292 18.2806 16.8576 18.1522L17.7442 17.2772C18.0359 16.9856 18.3159 16.7639 18.5842 16.6239C18.8526 16.4606 19.1209 16.3789 19.4126 16.3789C19.6342 16.3789 19.8676 16.4256 20.1242 16.5306C20.3809 16.6356 20.6492 16.7872 20.9409 16.9856L24.8026 19.7272C25.1059 19.9372 25.3159 20.1822 25.4442 20.4739C25.5609 20.7656 25.6309 21.0572 25.6309 21.3839Z"
      fill="#E15615"
    />
  </svg>
);
