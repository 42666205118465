import axios from "axios";
import config from "../config";

const instance = axios.create({
  baseURL: config.archeoBaseUrl,
});

instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("authToken");

    console.log("config", accessToken);
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("authToken");
      window.location.reload();
    } else {
      return Promise.reject(error.response);
    }
  }
);

const get = (url, params, config = {}) =>
  instance.get(url, { params, ...config });
const post = (url, data, config = {}) => instance.post(url, data, config);
const put = (url, data, config = {}) => instance.put(url, data, config);
const del = (url, config = {}) => instance.delete(url, config);

const methods = { get, post, put, delete: del };

export default methods;
