export const LeftArrowIcon = ({
  height = "28px",
  width = "28px",
  fillColor = "#E15615",
}) => {
  return (
    <svg
      style={{ height, width }}
      viewBox="0 0 28 28"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.61199 2.33325H19.3887C23.6353 2.33325 26.167 4.86492 26.167 9.11159V18.8766C26.167 23.1349 23.6353 25.6666 19.3887 25.6666H9.62366C5.37699 25.6666 2.84532 23.1349 2.84532 18.8883V9.11159C2.83366 4.86492 5.36532 2.33325 9.61199 2.33325ZM7.50032 14.8749H19.3887L15.877 18.3866C15.5387 18.7249 15.5387 19.2849 15.877 19.6233C16.052 19.7983 16.2737 19.8799 16.4953 19.8799C16.717 19.8799 16.9387 19.7983 17.1137 19.6233L22.1187 14.6183C22.282 14.4549 22.3753 14.2333 22.3753 13.9999C22.3753 13.7666 22.282 13.5449 22.1187 13.3816L17.1137 8.37659C16.7753 8.03825 16.2153 8.03825 15.877 8.37659C15.5387 8.71492 15.5387 9.27492 15.877 9.61325L19.3887 13.1249H7.50032C7.02199 13.1249 6.62532 13.5216 6.62532 13.9999C6.62532 14.4783 7.02199 14.8749 7.50032 14.8749Z"
        fill="white"
      />
    </svg>
  );
};
