import CustomModal from "../CustomModal";
import { ExclamationTriangleIcon }  from "../../icons";
import "./style.css";

const CustomWarningModal = ({ isOpen }) => {
  return (
    <CustomModal isVisible={isOpen} onClose={() => {}}>
      <h2 className="warning-modal-title">
        <p className="warning-modal-icon">
          <ExclamationTriangleIcon className="size-6" />
        </p>
        Warning
      </h2>
      <div>The form data attribute is invalid.</div>
      {/* <div className="warning-modal-footer">
        <button className="warning-modal-button" onClick={onClose}>
          OK
        </button>
      </div> */}
    </CustomModal>
  );
};

export default CustomWarningModal;
