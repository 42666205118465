export const RightArrowIcon = ({
  className = ""
}) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.9999 2.33325C7.55992 2.33325 2.33325 7.55992 2.33325 13.9999C2.33325 20.4399 7.55992 25.6666 13.9999 25.6666C20.4399 25.6666 25.6666 20.4399 25.6666 13.9999C25.6666 7.55992 20.4399 2.33325 13.9999 2.33325ZM18.7016 14.6183L15.2016 18.1183C15.0266 18.2933 14.8049 18.3749 14.5833 18.3749C14.3616 18.3749 14.1399 18.2933 13.9649 18.1183C13.6266 17.7799 13.6266 17.2199 13.9649 16.8816L15.9716 14.8749H9.91659C9.43825 14.8749 9.04159 14.4783 9.04159 13.9999C9.04159 13.5216 9.43825 13.1249 9.91659 13.1249H15.9716L13.9649 11.1183C13.6266 10.7799 13.6266 10.2199 13.9649 9.88159C14.3033 9.54325 14.8633 9.54325 15.2016 9.88159L18.7016 13.3816C19.0399 13.7199 19.0399 14.2799 18.7016 14.6183Z"
      fill="#E15615"
    />
  </svg>
);
