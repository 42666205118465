import React, { useState, useEffect } from "react";
import "../Reperti.scss";
import "../../LoginPageText/LoginPageText.scss";
import DataTableSubHeader from "../../DataTableSubHeader/DataTableSubHeader.jsx";
import ReactModal from "../../ReactModal/ReactModal.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { AddIcon, CopyIcon, CrossIcon, EditIcon, ExclamationTriangleIcon, RightArrowIcon } from "../../../icons/index.js";
import { SubHeaderMobileOption } from "../../SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormField from "../../FormField/FormField.js";
import IccdEditor from "../IccdEditor/index.jsx";
import Provenienze from "../../Provenienze/index.jsx";
import Repertori from "../../Repertori";
import ImageGallery from "../../ImageGallery/ImageGallery.jsx";
import repertoStructure from "../../../constants/dataStructure/reperto";
import { getRaccolta, getReperto, updateReperto } from "../../../api/index.js";
import { validateReperto } from "../../../helpers/formTools/validations";
import iccdManifest from "../../../assets/json/ICCD-4.00-manifest-v0.2.json";
import { useAppContext } from "../../Context/AppContext";
import GroupsFormField from "../../GroupsFormField";
import regex from "../../../helpers/formTools/regex";

function EditReperto() {
  const { repertoId, repertiFilter, setRepertiFilter } = useAppContext();
  const [isMenuActive, setMenuActive] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState();
  const [send, setsend] = useState(0);
  const [issues, setIssues] = useState([]);
  const [showIssues, setShowIssues] = useState(false);

  let id = localStorage.getItem("repertiId");
 
  const handleFormDataChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  // ****************************************************************************************************
  // ALL API CALLS

  const fetchReperto = async (id) => {
    try {
      let data = await getReperto(id);
      data.provenienze = data.provenienze.map(provenienza => ({...provenienza, comuneId: provenienza.comune.id}));
      setFormData({...data});
    } catch (error) {
      if(error.response && error.response.status === 401){
        navigate("/");
        console.error("Unauthorized. Redirecting you to the login page...");
      } else{
        console.error(`Error fetching "reperto" record`, error);
      }
    }
  };

  const fetchRaccolta = async (id) => {
    try {
      let data = await getRaccolta(id);
      if(data) setFormData((prevData) => ({
        ...prevData,
        ["raccolta"]: {
          raccoltaId: data.id,
          cassettaId: data.cassettaId,
        },
      }));
    } catch (error) {
      if(error.response && error.response.status === 401){
        navigate("/");
        console.error("Unauthorized. Redirecting you to the login page...");
      } else{
        console.error(`Error fetching "raccolta" record`, error);
      }
    }
  };


  const editReperto = async (data) => {
    try {
      await updateReperto(data);
      toast.success("Reperto aggiornato con successo!");
    } catch (error) {
      if (error.respomse && error.response.status === 401){
        navigate("/");
        console.error(`Authorization error updating a "reperto" record`);
      } else {
        toast.error(error.response && error.response.detail ? error.response.detail : `Unknown error updating a "cassetta" record`);
        console.error(`Authorization error updating a "reperto" record`, error);
      }
    }
  };

 //* ****************************************************************************************************
  // ALL EFFECTS

  // FETCHINT THE SELECTED REPERTO FROM BE
  useEffect(() => {
    if(repertoId) fetchReperto(repertoId);
  }, [ repertoId]);
  
  useEffect(() => {
    if(!formData) return;
    let issues = validateReperto(formData);
    setIssues(issues);
  }, [formData]);


  // ****************************************************************************************************
  // ALL HANDLERS

  const handleSave = async () => {
    if(issues.length > 0){ setShowIssues(true); return; }
    navigate(-1);
    let reperto = { 
      ...formData, 
      groups: formData.groups.map(group => group.name),  // Convert groups to array of ids  
      codiciInventario: formData.codiciInventario.map(c => ({ tipo: c.tipo, codice:  c.codice})),
      raccoltaId: formData.raccolta ? formData.raccolta.raccoltaId : null,
    };
    delete reperto.immagini;
    setsend(true);
    console.log("===| Reperto to be saved: ", reperto);
    editReperto(reperto);
    setRepertiFilter([]); // Reset the filter
    navigate(-1);
  }

  const handleRaccoltaChange = (newValue) => {
    if(regex.uuid.test(newValue)) {
      fetchRaccolta(newValue);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        ["raccolta"]: { cassettaId: '?', raccoltaId: newValue },
      }));
    }
  };

  // ****************************************************************************************************

  const disabled = pathname !== "/reperti/edit";
  const repertiRecordDetailsBreadcrumbs = [
    {
      id: 1,
      label: "Dati Archeologici",
      iconUrl:true,
    },
    {
      id: 2,
      label: "REPERTI",
      iconUrl: true,
    },
    { id: 3, label: "Edit REPERTI Record Details", iconUrl: "" },
  ];
  const subHeaderRightNavOptions = {
    icons: disabled
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => {
              navigate(-1);
            },
            label: "Indietro",
          },
          {
            icon: <EditIcon fillColor="#E15615" />,
            onClick: () => {
              navigate("/reperti/edit");
            },
            label: "Edita",
          },
        ]
      : [],

    components: disabled
      ? []
      : [
          <button onClick={() => navigate(-1)} className="cancel-button">
            <div
              className="login-icon"
            ><CrossIcon/></div>
            <span className="login-text">Cancel</span>
          </button>,
          <button
            onClick={() => {
              //navigate(-1);
              handleSave();
              //setsend(send + 1);
              // send
            }}
            className="save-button"
          >
             <AddIcon fillColor="#fff" />
            <span className="login-text">Salva</span>
          </button>,
        ],
  };
  return (
    <>
      <div className="container">
        <main className="record-detail-main-content">
          <DataTableSubHeader
            title={"REPERTI Record Details"}
            breadcrumbsData={repertiRecordDetailsBreadcrumbs}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <RepertiRecordDetailForm
            data={formData}
            setData={setFormData}
            onFieldChange={handleFormDataChange}
            onRaccoltaChange={handleRaccoltaChange} 
            disabled={disabled}
            send={send}
            issues={issues}
            showIssues={showIssues}
           // threeDLink={threeDLink}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            buttons={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </div>
    </>
  );
}



function RepertiRecordDetailForm({
  data,
  setData,  
  onRaccoltaChange,
  disabled,
  send,
  issues,
  showIssues
}) {
  //const [cassettaId, setCassettaId] = useState("");
  //const navigate = useNavigate(); // Use useNavigate hook
  //const location = useLocation();
  const [iccdOpen, setIccdOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  
  const handleFieldChange = (key, newValue) => {
    setData((prevData) => ({
      ...prevData,
      [key]: newValue ,
    }));
  };


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        // setCopySuccess('Copied!');
        // setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
      },
      (err) => {
        setCopySuccess('Failed to copy!');
      }
    );
  };

  if (data && Object.keys(data).length > 0) {
    return (
      <>
        <main className="reperti-main-container">
          <div className="content-wrapper">
          <div className="issue-container">
            {
              !disabled && showIssues && issues.map((issue, index) => {
                return (
                  <div key={index} className="issue">
                    <p className="issue-icon"><ExclamationTriangleIcon /> </p>
                    { issue }
                  </div>
                );
              })
            }  
            </div>
            <div className="blocks-container">
                <div className="form-block" id="generalità">  
                  <p className="section-title"> GENERALITÀ</p>
                  <div className="reperto-id-header">
                    <p className="reperto-id-text"> Codice Identificativo</p>
                    <p className="copy-text" onClick={() => copyToClipboard(data["id"])}>
                        {data["id"]}
                        <CopyIcon/>
                    </p>
                  </div>
                  <GroupsFormField
                      groups={data["groups"]}
                      onChange={(newValue) =>
                        handleFieldChange("groups", newValue)
                      }
                      edit={!disabled} 
                  />
                  <Repertori
                    repertori={data["codiciInventario"]}
                    onChange={(newValue) =>
                      handleFieldChange("codiciInventario", newValue)
                    }
                    edit={!disabled}
                  /> 
                </div>
                <div className="form-block" id="galleria">  
                  <p className="section-title"> GALLERIA IMMAGINI</p>
                  <ImageGallery
                    disabled={disabled}
                    send={send}
                    //imageLink={imageLink}
                    immagini={data.immagini}
                    repertoId={data.id}
                  />
                </div>
                <div className="form-block" id="bibliografia">  
                  <p className="section-title"> BIBLIOGRAFIA</p>
                  <p> ... </p>
                  <p> ... </p>
                  <p> ... </p>
                </div>
                <div className="form-block" id="scheda">  
                  <p className="section-title"> SCHEDA ICCD</p>
                  <div className="iccd-button-container">
                     <button className="class-iccd-btn btn" onClick={() => setIccdOpen(true)}>
                      APRI SCHEDA
                    </button>
                  </div>
                </div>
                 <div className="form-block" id="provenienza">  
                  <p className="section-title"> PROVENIENZA</p>
                  <FormField 
                      fieldStructure={repertoStructure["anno"]}
                      value={data["anno"]}
                      // value={data["anno"] ? data["anno"].value : ""}
                      onChange={(newValue) =>
                        handleFieldChange("anno", newValue)
                      }
                      disabled={disabled}
                  />  
                  <Provenienze
                      provenienze={data["provenienze"]}
                      onChange={(newValue) =>
                        handleFieldChange("provenienze", newValue)
                      }
                      edit={!disabled}
                  />  
                </div>
                <div className="form-block" id="caratteristiche">  
                  <div classname="blockHeader">
                    <p className="section-title"> CARATTERISTICHE</p>
                  </div>
                  <FormField 
                      fieldStructure={repertoStructure["materiale"]}
                      //value={data["materiale"] ? data["materiale"].value : ""}
                      value={data["materiale"]}
                      onChange={(newValue) =>
                        handleFieldChange("materiale", newValue)
                      }
                      disabled={disabled}
                  />  
                   <FormField
                      fieldStructure={repertoStructure["classe"]}
                      value={data["classe"]}
                      onChange={(newValue) =>
                        handleFieldChange("classe", newValue)
                      }
                      disabled={true}
                    />
                  <FormField 
                      fieldStructure={repertoStructure["forma"]}
                      //value={data["forma"] ? data["forma"].value : ""}
                      value={data["forma"]}
                      onChange={(newValue) =>
                        handleFieldChange("forma", newValue)
                      }
                      disabled={disabled}
                  />  
                  <FormField 
                      fieldStructure={repertoStructure["tipo"]}
                      //value={data["tipo"] ? data["tipo"].value : ""}
                      value={data["tipo"]}
                      onChange={(newValue) =>
                        handleFieldChange("tipo", newValue)
                      }
                      disabled={disabled}
                  />  
                   <FormField 
                      fieldStructure={repertoStructure["cronologia"]}
                      //value={data["cronologia"] ? data["cronologia"].value : ""}
                      value={data["cronologia"]}
                      onChange={(newValue) =>
                        handleFieldChange("cronologia", newValue)
                      }
                      disabled={disabled}
                  />  
                </div>
                <div className="form-block" id="collocazione">  
                  <div classname="blockHeader">
                    <p className="section-title">COLLOCAZIONE</p>
                  </div>
                  <FormField 
                      fieldStructure={{
                        "label": "Tipo di Collocazione",
                        "type": "string",
                        "editable": false
                      }}
                      value={data["raccolta"] && data["raccolta"].cassettaId ? "Deposito" : "-"}
                      onChange={() => {}}
                      disabled={true}
                  />  
                  <FormField 
                      fieldStructure={{
                        "label": "Identificativo Cassetta",
                        "type": "string",
                        "editable": true
                      }}
                      value={data["raccolta"] ? data["raccolta"].cassettaId : "-"}
                      onChange={() => {}}
                      disabled={true}
                  />  
                  <FormField 
                      fieldStructure={{
                        "label": "Identificatovo Raccolta",
                        "type": "string",
                        "editable": true
                      }}
                      value={data["raccolta"] ? data["raccolta"].raccoltaId : "-"}
                      onChange={(newValue) =>
                        onRaccoltaChange(newValue)
                      }
                      disabled={disabled}
                  />  
                </div>
              </div>
            </div>
            {/* <div className="description-column">
              <Description text={descriptionText} disabled={disabled} />
            </div> */}
          <ReactModal
            isOpen={iccdOpen}
            onClose={() =>  setIccdOpen(false)}
          >
            <IccdEditor
              manifest = {iccdManifest}
              data = {data["iccd"]}
              edit = { true }
              visibility = { 1 }
              knownData = {{}}
              onSave = { (value) => handleFieldChange("iccd", value) }
              onClose = {() =>  setIccdOpen(false)}
            />
          </ReactModal>
        </main>
      </>
    );
  }
}

export default EditReperto;