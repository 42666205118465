import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./UsersDetailsTable.scss";
import ItemsTable from "../ItemsTable/ItemsTable";
import DataTableSubHeader from "../DataTableSubHeader/DataTableSubHeader";
import Dropdown from "../Dropdown/Dropdown";
import { Select, SubHeaderMobileOption } from "../index";
import axios from "axios";
import {
  AddIcon,
  FiltersIcon,
  RightArrowIcon,
  TrashIcon,
  EditIcon,
  CrossIcon,
} from "../../icons";
import AddUserModal from "./AddUserModal.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog.jsx";
import { useAppContext } from "../Context/AppContext.jsx";
import { getUsers } from "../../api";

const selectedHeaders = [
  { key: "userName", label: "Nome utente", width: "300px", sortable: true }, 
  { key: "roles", label: "Ruoli", width: "300px", sortable: false },
  { key: "groups", label: "Gruppi", width: "300px", sortable:false }
];


const rolesDropdown = (
  <Select
    options={[
      { value: "Super Admin", id: "role-1" },
      { value: "Invoice Manager", id: "role-2" },
      { value: "Data Scientist", id: "role-3" },
    ]}
    overlap
  />
);
const groupsDropdown = (
  <Select
    options={[
      { value: "Select All", selectAll: true, id: "group-0" },
      { value: "Group 1", id: "group-1" },
      { value: "Group 2", id: "group-2" },
      { value: "Group 3", id: "group-3" },
    ]}
    overlap
    multiSelect
  />
);


const breadcrumbsData = [
  {
    id: 1,
    label: `Administration ${localStorage.getItem("username")}`,
    iconUrl: true,
  },
  {
    id: 2,
    label: "USERS",
    iconUrl: "",
  },
];

function UsersDetailsTable() {
  const navigate = useNavigate();
  const [isEditing, setEditing] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMenuActive, setMenuActive] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [getAPIData, setGetAPIData] = useState();
  const [isDataFetched, setDataFetched] = useState(false);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false); // State for confirmation dialog
  const [actionType, setActionType] = useState(null);
  const token = localStorage.getItem("authToken");
  const [rolesOptions, setRolesOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const length = Object.values(checkedItems).filter(
    (value) => value === true
  ).length;
  const flagForUserEdit = true;
  const { multiSelectValueOption} = useAppContext();
  const transformNewData = (users) => {
    return users.map((user, index) => ({
      id: { value: (index + 1).toString().padStart(3, "0"), component: "" },
      userName: { value: user.username, component: "" },

      roles: {
        value:
          user?.roles
            ?.map((role) => `${role.name} (${role.authorities?.join(", ")})`)
            .join(", ") || "No value",
        component: (
          <Dropdown
            dropdownTexts={
              user?.roles?.length
                ? user.roles.map((role) => ({
                    label: role.name || "No value",
                    onClick: () => {
                      console.log(`Role clicked: ${role.name}`);
                    },
                  }))
                : [{ label: "No value", onClick: () => {} }]
            }
            defaultSelected={user?.roles?.[0]?.name || "No value"}
          />
        ),
      },

      groups: {
        value:
          user?.groups?.map((group) => group.name).join(", ") || "No value",
        component: (
          <Dropdown
            dropdownTexts={
              user?.groups?.length
                ? user.groups.map((group) => ({
                    label: group.name || "No value",
                    onClick: () => {
                      console.log(`Group clicked: ${group.name}`);
                    },
                  }))
                : [{ label: "No value", onClick: () => {} }]
            }
            defaultSelected={user?.groups?.[0]?.name || "No value"}
          />
        ),
      },
    //   action: {
    //     value: "",
    //     component: isEditing && flagForUserEdit && (
    //       <button
    //         className="action-button save-button"
    //         style={{ padding: "11px 11px" }}
    //         onClick={() => {
    //           const userName = user.username;
    //           console.log(`Saving role and group settings for ${userName}`);
    //           saveRoleSetting(userName);
    //           saveGroupSetting(userName);
    //         }}
    //       >
    //         <AddIcon fillColor="white" />
    //         <span>Save</span>
    //       </button>
    //     ),
    //   },
    }));
  };
  // Fetch the roles data
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(
          "https://archeo.loreb.it/api/private/v3/role/get",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const roles = response.data.roles;

        const formattedOptions = roles.map((role, index) => ({
          value: role.name,
          id: `role-${index + 1}`,
          uniqueId: role.id,
        }));

        setRolesOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);
  // set the roles into select options
  const rolesDropdown = <Select options={rolesOptions} overlap />;
  // fetch the groups data
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(
          "https://archeo.loreb.it/api/private/v3/group/get",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const groups = response.data.groups;

        const formattedOptions = [
          { value: "Select All", selectAll: true, id: "group-0" },
          ...groups.map((group, index) => ({
            value: group.name,
            id: `group-${index + 1}`,
          })),
        ];

        setGroupOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchGroups();
  }, []);
  const groupsDropdown = <Select options={groupOptions} overlap multiSelect />;

  // getting all the data
  const username = localStorage.getItem("username");

  // GETTING THE LIST OF USERS
  const listUsers = async () => { 
    try {
      const users = await getUsers();
      if(Array.isArray(users)) {
        const transformedData = transformNewData(users);
        setTableData(transformedData);
        setGetAPIData(transformedData);
        setDataFetched(true);
      } else {
        toast.error(`Unknown error listing users`);
      }
    } catch (error) {
      if(error.response && error.response.status === 401){
        navigate("/");
        toast.error(`Authorization error`);
      } else{
        console.error("Error listing users:", error);
        toast.error(`Communication error listing users`);
      }
    }
  }

  useEffect(() => {
     listUsers();
  }, [tableData.length]);

  const handleDelete = async () => {
    const remainingData = tableData.filter(async (item, index) => {
      if (checkedItems[index]) {
        const userNameValue = item.userName.value;
        const url = `https://archeo.loreb.it/api/private/v3/user/delete/${userNameValue}`;

        try {
          const response = await fetch(url, {
            method: "DELETE",
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            if (response.status === 401) {
              navigate("/signin");
              throw new Error(
                "Your session has ended. Redirecting you to the login page..."
              );
            } else {
              throw new Error("Network response was not ok");
            }
          } else if (response.ok) {
            toast.success(`Successfully deleted a user: ${userNameValue}`);
            listUsers();
          }
        } catch (error) {
          toast.error(`Error: ${error.message}`);
        }

        return false;
      }
      return true;
    });

    // Update state with the remaining data
    setTableData(remainingData);
    setCheckedItems({});
  };

  // setting roles for the users
  const saveRoleSetting = async (userName) => {
    const url = "https://archeo.loreb.it/api/private/v3/user/role/set";
  
    try {
      const response = await axios.post(
        url,
        {
          username: userName,
         // roles: [singleSelect.uniqueId],
         roles: []
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(`Successfully set role for user: ${userName}`);
        listUsers()
      } else {
        toast.error(`Failed to set role for user: ${userName}`);
      }
    } catch (error) {
      console.error("Error setting role:", error);
      if (error.response && error.response.status === 401) {
        navigate("/signin");
        toast.error("Your session has ended. Redirecting you to the login page...");
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  };
  

  // setting groups for the users
  const saveGroupSetting = async (userName) => {
    const url = "https://archeo.loreb.it/api/private/v3/user/group/set";
  
    try {
      const response = await axios.post(
        url,
        {
          username: userName,
          groups: multiSelectValueOption,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(`Successfully set group for user: ${userName}`);
        listUsers()
      } else {
        toast.error(`Failed to set group for user: ${userName}`);
      }
    } catch (error) {
      console.error("Error setting group:", error);
      if (error.response && error.response.status === 401) {
        navigate("/signin");
        toast.error("Your session has ended. Redirecting you to the login page...");
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  };
  
  const handleAddUser = (newUser) => {
    const newTableData = [
      ...tableData,
      {
        id: {
          value: (tableData.length + 1).toString().padStart(3, "0"),
          component: "",
        },
        userName: { value: newUser.username, component: "" },
        email: { value: newUser.email, component: "" },
        password: { value: newUser.password, component: "" },
        firstName: { value: newUser.firstName, component: "" },
        lastName: { value: newUser.lastName, component: "" },
        roles: { value: newUser.roles, component: rolesDropdown },
        description: {
          value: newUser.groups.join(", "),
          component: groupsDropdown,
        },
      },
    ];
    setTableData(newTableData);
  };

  const editingOptions = (
    <div className="editing-tools-button-wrapper">
      <button
        className="action-button cancel-button"
        onClick={() => setEditing(false)}
      >
        <CrossIcon />
        <span>Cancel</span>
      </button>
      {/* <button
        className="action-button save-button"
        onClick={() => setEditing(false)}
      >
        <AddIcon fillColor="white" />
        <span>Save</span>
      </button> */}
    </div>
  );

  const subHeaderRightNavOptions = {
    icons: isEditing
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => {},
            onClick: () => setEditing(false),
            label: "Back",
          },
          {
            icon: <AddIcon onClick={() => setModalOpen(true)} />,
            onClick: () => {
              setModalOpen(true);
            },
            label: "Add",
          },
          length > 0 && {
            icon: (
              <TrashIcon
                onClick={() => {
                  setActionType("delete");
                  setConfirmationDialogOpen(true); 
                }}
              />
            ),
            onClick: () => {},
            label: "Delete",
          },
        ]
      : [
          {
            icon: <EditIcon fillColor="#E15615" height="28" width="28" />,
            onClick: () => setEditing(!isEditing),
            label: "Edit",
          },
        ],

    components: isEditing
      ? [editingOptions]
      : [
          <Dropdown
            dropdownTexts={[
              {
                label: "UTENTI",
                onClick: () => {
                  navigate("/users");
                },
              },
              {
                label: "GRUPPI",
                onClick: () => {
                  navigate("/groups");
                },
              },
              {
                label: "RUOLI",
                onClick: () => {
                  navigate("/rolea");
                },
              },
            ]}
            defaultSelected="USERS"
          />,
        ],
  };
  const setComponentInData = () => {
    const newData = tableData.map((info) => ({
      ...info,
      roles: { ...info.roles, component: rolesDropdown },
      description: { ...info.description, component: groupsDropdown },
      // action: { ...info.action, component: action },
    }));
    setTableData(newData);
  };
  useEffect(() => {
    if (isDataFetched) {
      if (isEditing) {
        setComponentInData();
      } else {
        setTableData(getAPIData);
        localStorage.removeItem("selectedValue");
        localStorage.removeItem("multiSelectedValues");
      }
    }
    // eslint-disable-next-line
  }, [isEditing, isDataFetched]);


  const handleConfirmation = () => {
    if (actionType === "delete") {
      handleDelete();
    } else if (actionType === "save") {
      // Handle save action here
      setEditing(false);
    }
    setConfirmationDialogOpen(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationDialogOpen(false);
  };


  return (
    <>
      <div className="container">
        <main className="user-detail-main-content">
          <DataTableSubHeader
            title={"USERS"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <ItemsTable
            data={tableData}
            setEditing={setEditing}
            headers={selectedHeaders}
            isEditing={isEditing}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            saveRoleSetting={saveRoleSetting}
            saveGroupSetting={saveGroupSetting}
            flagForUserEdit={flagForUserEdit}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            components={!isEditing ? subHeaderRightNavOptions?.components : []}
            buttons={isEditing ? [editingOptions] : []}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
        <AddUserModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleAddUser}
          rolesDropdown={rolesDropdown}
          groupsDropdown={groupsDropdown}
          getUserData={listUsers}
          flagForUserEdit={flagForUserEdit}
          setEditing={setEditing}
        />
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          onConfirm={handleConfirmation}
          onClose={handleCancelConfirmation}
          message={
            actionType === "delete"
              ? "Are you sure you want to delete the selected groups?"
              : "Are you sure you want to save the changes?"
          }
        />
      </div>
    </>
  );
}

export default UsersDetailsTable;
