import "./PasswordChangePage.scss";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { InputComponent } from "../InputComponent/InputComponent";
import { InfoCircle, LeftArrowIcon } from "../../icons";
import { CheckedIcon } from "../../icons/checked";
import { CheckedLargeIcon } from "../../icons/checkedLargeIcon";
import { updatePassword } from "../../api";
import { toast } from "react-toastify";

function PasswordChangePage() {
  const [isPasswordWeak, setIsPasswordWeak] = React.useState(false);
  const [isPasswordEqual, setIsPasswordEqual] = React.useState(false);
  const [isPasswordReset, setIsPasswordReset] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();


  const handleOnNewPassword = (e) => {
    setPassword(e?.target?.value);
    if (e?.target?.value?.length < 8) {
      setIsPasswordWeak(true);
    } else {
      setIsPasswordWeak(false);
    }
  };
  const handleOnConfirmPassword = (e) => {
    if (e?.target?.value === password) {
      setIsPasswordEqual(true);
      setPassword(e?.target?.value);
    } else {
      setIsPasswordEqual(false);
    }
  };

  const handleOnResetPassword = () => {
     changePassword(password);
  };


  /* ************************************************************ */
  // ALL API CALLS
  
  const changePassword = async (newPassword) => {
    try {
      const data = await updatePassword(newPassword);
      setIsPasswordReset(true);
    } catch (error) {
      console.error("Error changing password", error);
      toast.error(`Errore nel cambio di password`);
    }
  };

  /* ********************************************************* */


  return (
    <>
      <form
        className="reset-password-container"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="header-container">
          <h1 className="reset-password-title">Modifica Password</h1>
          <p className="reset-password-description">
            Inserisci la nuova password.
          </p>
        </div>
        <InputComponent
          label="Enter New Password"
          placeholder="Enter password"
          type={"password"}
          htmlFor={"new-password"}
          onChange={handleOnNewPassword}
          labelClassName={"input-label"}
          value={password}
        />
        <InputComponent
          label="Confirm New Password"
          placeholder="Enter password"
          type={"password"}
          htmlFor={"confirm-password"}
          onChange={handleOnConfirmPassword}
          labelClassName={"input-label"}
        />

        {isPasswordWeak  && (
          <div className="password-strength">
            <InfoCircle fillColor="#E15615" />
            <div className="strength-text">Password debole</div>
          </div>
        )}
        <button
          onClick={!isPasswordWeak && isPasswordEqual ? handleOnResetPassword : () => {}}
          className={!isPasswordWeak && isPasswordEqual ? "reset-password-button" : "reset-password-button-disabled"}
        >
          <span className="button-text">Cambia password</span>
          { !isPasswordWeak && isPasswordEqual && (<CheckedIcon fillColor="#fff" />)}
        </button>
        {isPasswordReset && (
          <div className="password-status-card">
            <div className="status-card-header">Password Reset Status</div>
            <div className="status-card-body">
              <div className="content-container">
                <CheckedLargeIcon />
                <div className="text-container">
                  Password Reset Successful!
                  <br /> You can now login with new password.
                </div>
                <button
                  className="staus-login-button"
                  onClick={() => {
                    navigate("/signin");
                  }}
                >
                  <span className="button-text">Login</span>
                  <LeftArrowIcon />
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
}

export default PasswordChangePage;
