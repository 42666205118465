import React from 'react';
export const EmailIcons = ({ fillColor = '#000' }) => (
  <svg
    style={{ height: '24', width: '24' }}
    viewBox="0 0 28 28"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.834 4.08398H8.16732C4.66732 4.08398 2.33398 5.83398 2.33398 9.91732V18.084C2.33398 22.1673 4.66732 23.9173 8.16732 23.9173H19.834C23.334 23.9173 25.6673 22.1673 25.6673 18.084V9.91732C25.6673 5.83398 23.334 4.08398 19.834 4.08398ZM20.3823 11.189L16.7307 14.1057C15.9607 14.724 14.9807 15.0273 14.0007 15.0273C13.0207 15.0273 12.029 14.724 11.2707 14.1057L7.61898 11.189C7.24565 10.8857 7.18732 10.3257 7.47898 9.95232C7.78232 9.57898 8.33065 9.50898 8.70398 9.81232L12.3557 12.729C13.2423 13.4407 14.7473 13.4407 15.634 12.729L19.2857 9.81232C19.659 9.50898 20.219 9.56732 20.5107 9.95232C20.814 10.3257 20.7557 10.8857 20.3823 11.189Z"
      fill="#E15615"
    />
  </svg>
);
