import { useState, useEffect } from "react";
import Select from "react-select";
import "./InputAndSelect.scss";

const InputAndSelect = ({
  options,
  displayAttr,
  placeholder,
  label,
  attr,
  onChange,
  onInputChange,
  initSelect,
  initInput,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setSelectedOption(initSelect);
    setInputValue(initInput);
  }, [initInput, initSelect]);

  const handleSelectChange = (option) => {
    onChange(option, attr);
    setSelectedOption(option);
  };

  const handleInputChange = (e) => {
    onInputChange(e, attr);
    setInputValue(e.target.value);
  };
  return (
    <div className="input-select-wrapper">
      <label className="input-select-label" htmlFor={`${label}-input`}>
        {label}
      </label>
      <div>
        <Select
          options={options}
          formatOptionLabel={(comuneId) => <p>{`${comuneId[displayAttr]}`}</p>}
          value={selectedOption}
          placeholder={placeholder}
          onChange={handleSelectChange}
        />
        <input
          type="text"
          id={`${label}-input`}
          name={label}
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default InputAndSelect;
