import "./SpinnerLoading.scss";

const SpinnerLoading = ({ isLoading }) => {
  return (
    <div className={`spinner-container ${isLoading ? "active" : ""}`}>
      <div className="spinner"></div>
    </div>
  );
};

export default SpinnerLoading;
