import axios from 'axios';
import config from '../config';

const client = axios.create({
  baseURL: `${config.archeoBaseUrl}/api/private/v3/user/`,
});

client.interceptors.request.use((conf) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    conf.headers.Authorization = `Bearer ${token}`;
    conf.headers.accept = "*/*";
  }
  return conf; // This should return conf, not config
}, (error) => {
  return Promise.reject(error);
});


// GET THE LIST OF USERS
export const getUsers = async () => {
  const response = await client.get(`/get`);
  return response.data.users;
};

// GET MY PROFILE DATA
export const getMe = async () => {
  const response = await client.get(`/get/me`);
  return response.data.user;
};

// UPDATE USER PASSWORD
export const updatePassword = async (newPassword) => {
  const response = await client.put(`/update/password`, {newPassword});
  return response.data.users;
};
