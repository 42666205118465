export const associateRepertiJson = [
  {
    label: "Cassetta",
    attr: "cassetta",
    index: 0,
    value: null,
  },
  {
    label: "Tipo",
    attr: "tipo",
    index: 0,
    value: null,
  },
  {
    label: "Note",
    attr: "note",
    index: 0,
    value: null,
  },
  {
    label: "Materia",
    attr: "materia",
    index: 0,
    value: null,
  },
  {
    label: "Forma",
    attr: "forma",
    index: 0,
    value: null,
  },
  {
    label: "Confronti",
    attr: "confronti",
    index: 0,
    value: null,
  },
  {
    label: "Classe",
    attr: "classe",
    index: 0,
    value: null,
  },
  {
    label: "Anno di scavo",
    attr: "anno",
    index: 0,
    value: null,
  },
];

export const tipoJson = [
  { label: "area", value: "area" },
  { label: "scavo", value: "scavo" },
  { label: "ambiente", value: "ambiente" },
  { label: "saggio", value: "saggio" },
  { label: "insula", value: "insula" },
  { label: "strada", value: "strada" }, 
  { label: "settore", value: "settore" },
  { label: "quadrato", value: "quadrato" },
  { label: "taglio", value: "taglio" }
];


