import { useState, useEffect } from "react";
import "./style.css";

const CustomToggleButton = ({ initialState = true, onToggle }) => {
  const [isOn, setIsOn] = useState(initialState);

  useEffect(() => {
    setIsOn(initialState);
  }, [initialState]);

  const handleToggle = (e) => {
    const newState = !isOn;
    setIsOn(newState);
    if (onToggle) {
      onToggle(e, newState);
    }
  };

  return (
    <div
      className={`toggle ${isOn ? "toggle-on" : "toggle-off"}`}
      onClick={handleToggle}
    >
      {isOn ? "ON" : "OFF"}
      <div className="toggle-switch"></div>
    </div>
  );
};

export default CustomToggleButton;
