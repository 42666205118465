export const allAcronyms = {
    ACCC: "Altra identificazione",
    ADP: "Profilo di accesso ai dati",
    ALI: "Altre indicazioni",
    AMA: "Ambito di applicazione",
    AN: "Annotazioni",
    AREA_SCAVO: "Area di scavo",
    BIBH: "Sigla per citazione",
    BIBM: "Riferimento bibliografico completo",
    BIBR: "Abbreviazione",
    BIBX: "Genere",
    BIL: "Citazione completa",
    CBC : "Indentificativo univoco",
    CTSF: "Foglio/Data",
    CTSC: "Comune",
    CTSE: "Particelle e altri elementi di confine",
    CTSN: "Particella catastale",
    DES: "Descrizione",
    DSCD: "Data",
    "DSCD - Rif": "Riferimento cronologico",
    DSCH: "Acronimo identificativo",
    DSCJ: "Ente schedatore",
    "DSCQ - Set": "Settore/ambiente/quadrato",
    "DSCV -": "Denominazione scavo",
    DT: "Cronologia",
    DTR: "Riferimento cronologico",
    DTZS: "Frazione cronologica",
    GABOL: "Note",
    GAM: "Metodo di georeferenziazione",
    GAP: "Proiezione e Sistema di riferimento",
    GAQI: "Quota minima s.l.m.",
    GAQS: "Quota massima s.l.m.",
    GAT: "Tecnica di georeferenziazione",
    GEL: "Tipo di localizzazione",
    GEP: "Sistema di riferimento",
    GET: "Tecnica di georeferenziazione",
    GPM: "Grado di precisione del posizionamento",
    GPBB: "Base cartografica di riferimento",
    GPT: "Tecnica di georeferenziazione",
    I_NSC: "Notizie storico critiche",
    LCC: "Comune",
    LCP: "Provincia",
    LCR: "Regione",
    LCS: "Stato",
    "Loc./Sito": "Località o sito",
    MISL: "Larghezza",
    MISN: "Lunghezza",
    MTP: "Materiali presenti",
    NCS: "Notizie storico critiche",
    OGM: "Modalità di individuazione",
    OGTD: "Definizione",
    OGTI: "Sigla",
    OGTX: "Ambiente",
    OGTY: "Settore",
    OGTZ: "Quadrato",
    POS: "Posizione saggio",
    POSN: "Numero area",
    PVCC: "Comune",
    PVCL: "Località",
    PVCR: "Regione",
    PVZ: "Tipo di contesto",
    RE: "Modalità di reperimento",
    SCAN: "Denominazione dello scavo",
    SITO: "Nome del sito",
    nome: "Nome comune",
  }

  export const sitoAcronyms = {
     AREA_SCAVO: "Area di scavo",
     SITO: "Nome del sito",
     "Loc./Sito": "Località o sito",
  }

  export const ambienteAcronyms = {
    CBC: "ACRONIMO bene culturale",
    LCS: "Stato",
    LCR: "Regione",
    LCP: "Provincia",
    LCC: "Comune",
    PVZ: "Tipo di contesto",
    DTR: "Riferimento cronologico",
    DES: "DEFINIZIONE",
    OGM: "Modalità di individuazione",
    GEL: "Tipo di localizzazione",
    GET: "Tecnica di georeferenziazione",
    GEP: "Sistema di riferimento",
    GPT: "Tecnica di georeferenziazione",
    GPM: "Grado di precisione del posizionamento",
    GPBB: "Base cartografica di riferimento",
    DSCJ: "Ente schedatore",
    DSCH: "ACRONIMO identificativo",
    BIBR: "Abbreviazione",
    BIBX: "Genere",
    BIBM: "Riferimento bibliografico completo",
    ADP: "Profilo di accesso ai dati",
    "DSCV -": "Denominazione scavo",
    "DSCD - Rif": "Riferimento cronologico",
    "DSCQ - Set": "Settore/ambiente/quadrato",
    I_NSC: "Notizie storico critiche",
   };

   export const saggiAcronyms = {
    CBC: "Identificativo univoco scheda ICCD",
    ACCC: "Altra identificazione",
    AMA: "Ambito di applicazione",
    OGTD: "Definizione",
    OGTI: "Sigla",
    OGTX: "Ambiente",
    OGTY: "Settore",
    OGTZ: "Quadrato",
    ALI: "Altre indicazioni",
    PVCR: "Regione",
    PVCC: "Comune",
    PVCL: "Località",
    CTSC: "Comune",
    CTSF: "Foglio/Data",
    CTSN: "Particella catastale",
    CTSE: "Particelle e altri elementi di confine",
    GAT: "Tecnica di georeferenziazione",
    GAM: "Metodo di georeferenziazione",
    GAP: "Proiezione e Sistema di riferimento",
    GAQI: "Quota minima s.l.m.",
    GAQS: "Quota massima s.l.m.",
    GABO: "Note",
    POS: "Posizione saggio",
    POSN: "Numero area",
    MISL: "Larghezza",
    MISN: "Lunghezza",
    RE: "Modalità di reperimento",
    SCAN: "Denominazione dello scavo",
    DSCD: "Data",
    DT: "Cronologia",
    DTZS: "Frazione cronologica",
    DES: "Descrizione del saggio",
    NCS: "Notizie storico critiche",
    MTP: "Materiali presenti",
    AN: "Annotazioni",
    BIBH: "Sigla per citazione",
    BIL: "Citazione completa",
   };
