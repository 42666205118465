export const UploadFileIcons = ({ fillColor = "#E15615" }) => (
  <svg
    width="84"
    height="84"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="42" cy="42" r="42" fill="#F8DACC" />
    <path
      d="M46.7551 43.3668C44.1758 40.7421 39.9522 40.7052 37.3274 43.2845C36.6237 43.9761 36.1075 44.7825 35.7982 45.6472C35.7018 45.8915 35.6242 46.1359 35.5655 46.3806C34.9903 48.5535 35.5442 50.9815 37.2451 52.7123C38.946 54.4431 41.3639 55.0393 43.5466 54.5021C43.7922 54.4477 44.038 54.3744 44.2839 54.2823C45.1539 53.9881 45.9691 53.4861 46.6729 52.7946C49.2976 50.2152 49.3345 45.9916 46.7551 43.3668ZM41.8708 50.9707C41.588 50.9682 41.3439 50.8529 41.1663 50.6722C40.9887 50.4915 40.8777 50.2454 40.8802 49.9626L40.8889 48.9633L39.8519 48.9542C39.5691 48.9517 39.3249 48.8365 39.1474 48.6558C38.9698 48.4751 38.8588 48.229 38.8613 47.9462C38.866 47.3993 39.3225 46.9508 39.8693 46.9555L40.9064 46.9646L40.9151 45.9652C40.9199 45.4184 41.3763 44.9699 41.9232 44.9746C42.47 44.9794 42.9185 45.4359 42.9138 45.9827L42.9051 46.982L43.8478 46.9903C44.3946 46.995 44.8432 47.4515 44.8384 47.9983C44.8337 48.5451 44.3772 48.9937 43.8304 48.9889L42.8876 48.9807L42.8789 49.98C42.8646 50.5362 42.4176 50.9754 41.8708 50.9707Z"
      fill="#E15615"
    />
    <path
      d="M52.9333 49.0533C52.4533 49.48 51.9333 49.84 51.3733 50.1333C50.8933 50.3733 50.4133 49.9066 50.4933 49.3733C50.5466 48.9866 50.5733 48.5866 50.5733 48.1733C50.5733 43.4 46.68 39.5066 41.9066 39.5066C37.1333 39.5066 33.24 43.4 33.24 48.1733C33.24 48.7866 33.3066 49.3733 33.4266 49.9466C33.52 50.4133 33.16 50.88 32.6933 50.76C27.4266 49.48 27.4 41.3733 33.2933 40.9466H33.36C29.08 29.0533 47.1333 24.2933 49.2 36.8C54.9733 37.5333 57.3066 45.2266 52.9333 49.0533Z"
      fill="#E15615"
    />
  </svg>
);
