export const MinusIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0002 2.33337C7.57183 2.33337 2.3335 7.57171 2.3335 14C2.3335 20.4284 7.57183 25.6667 14.0002 25.6667C20.4285 25.6667 25.6668 20.4284 25.6668 14C25.6668 7.57171 20.4285 2.33337 14.0002 2.33337ZM18.5735 14.875H9.24016C8.76183 14.875 8.36516 14.4784 8.36516 14C8.36516 13.5217 8.76183 13.125 9.24016 13.125H18.5735C19.0518 13.125 19.4485 13.5217 19.4485 14C19.4485 14.4784 19.0635 14.875 18.5735 14.875Z"
      fill="#EE4B2B"
    />
  </svg>
);
