import { useState } from "react";
import { EyeIcon } from "../../icons/eyeIcon";
import "./InputComponent.scss";
export const InputComponent = ({
  onChange,
  label,
  className,
  type,
  placeholder = "Enter Input",
  inputClassName,
  labelClassName,
  htmlFor,
  value,
}) => {
  const [inputValue, setInputValue] = useState("");
  const isPasswordType = type === "password";
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const onClickOfChangePasswordType = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  const handleOnChange = (e) => {
    setInputValue(e.target.value);
    onChange && onChange(e);
  };
  return (
    <div className={`input-component ${className}`}>
      <div className="label-container-wrapper">
        <label
          htmlFor={htmlFor ?? "input"}
          className={`reset-password-label ${labelClassName}`}
        >
          {label}
        </label>
      </div>
      <div className="password-input-container">
        <input
          className={`reset-password-input ${inputClassName}`}
          type={isPasswordType && isPasswordVisible ? "text" : type}
          placeholder={placeholder}
          id={htmlFor ?? "input"}
          onChange={handleOnChange}
          value={value || value === "" ? value : inputValue}
        />
        {type === "password" && (
          <span
            className="password-visible-icon"
            onClick={onClickOfChangePasswordType}
          >
            <EyeIcon />
          </span>
        )}
      </div>
    </div>
  );
};
