export const CloudUploadIcon = ({
  height = "24px",
  width = "24px",
  fillColor = "#fff",
}) => {
  return (
    <svg
      style={{ height, width }}
      viewBox="0 0 24 24"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5661 13.0255C13.6316 11.0569 10.4638 11.0293 8.49527 12.9638C7.96747 13.4824 7.58034 14.0872 7.34839 14.7357C7.27608 14.919 7.21791 15.1023 7.17388 15.2858C6.74245 16.9155 7.15791 18.7365 8.43356 20.0346C9.70922 21.3327 11.5227 21.7799 13.1596 21.3769C13.3439 21.3361 13.5282 21.2812 13.7126 21.2121C14.3651 20.9915 14.9766 20.615 15.5044 20.0963C17.4729 18.1618 17.5006 14.9941 15.5661 13.0255ZM11.9028 18.7284C11.6907 18.7265 11.5076 18.6401 11.3745 18.5045C11.2413 18.369 11.158 18.1844 11.1599 17.9723L11.1664 17.2228L10.3886 17.216C10.1765 17.2142 9.99341 17.1277 9.86024 16.9922C9.72707 16.8567 9.64382 16.6721 9.64567 16.46C9.64925 16.0499 9.99161 15.7134 10.4017 15.717L11.1795 15.7238L11.186 14.9743C11.1896 14.5642 11.532 14.2278 11.9421 14.2313C12.3522 14.2349 12.6886 14.5773 12.6851 14.9874L12.6785 15.7369L13.3856 15.7431C13.7957 15.7466 14.1321 16.089 14.1286 16.4991C14.125 16.9092 13.7826 17.2456 13.3725 17.2421L12.6654 17.2359L12.6589 17.9854C12.6482 18.4025 12.313 18.7319 11.9028 18.7284Z"
        fill="#E15615"
      />
      <path
        d="M20.1998 17.29C19.8398 17.61 19.4498 17.88 19.0298 18.1C18.6698 18.28 18.3098 17.93 18.3698 17.53C18.4098 17.24 18.4298 16.94 18.4298 16.63C18.4298 13.05 15.5098 10.13 11.9298 10.13C8.34983 10.13 5.42983 13.05 5.42983 16.63C5.42983 17.09 5.47983 17.53 5.56983 17.96C5.63983 18.31 5.36983 18.66 5.01983 18.57C1.06983 17.61 1.04983 11.53 5.46983 11.21H5.51983C2.30983 2.28996 15.8498 -1.28004 17.3998 8.09996C21.7298 8.64996 23.4798 14.42 20.1998 17.29Z"
        fill="#E15615"
      />
    </svg>
  );
};
