import React, { useState, useEffect } from "react";
import "./AddUserModal.scss";
import { Select } from "../index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog.jsx";
import { useNavigate } from "react-router-dom";
const AddUserModal = ({ isOpen, onClose, onSave, rolesDropdown, groupsDropdown, getUserData, flagForUserEdit, setEditing }) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    roles: [],
    groups: []
  });
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false); // State for confirmation dialog
  const [actionType, setActionType] = useState(null);
  flagForUserEdit = true;
  useEffect(() => {
    if (isOpen) {
      localStorage.removeItem("selectedValue");
      localStorage.removeItem("multiSelectedValues");
      localStorage.removeItem("multiSelectedRepeti");
      localStorage.removeItem("multiSelectedCassette");
      localStorage.removeItem("multiSelectedPubblicazioni");
    }
  }, [isOpen])


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  // saving user data
  const saveUserData = async () => {
    const url = "https://archeo.loreb.it/api/private/v3/user/save";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          username: formData.username,
          password: formData.password
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/signin");
          throw new Error(
            "Your session has ended. Redirecting you to the login page..."
          );
        } else {
          throw new Error("Network response was not ok");
        }
      } else if (response.ok) {
        toast.success("Successfully added a User!");
        getUserData()
        setEditing(false)
      }

      const apiData = await response.json();
      

    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.error(`Error: ${error.message}`);
      setEditing(false)
    }

  };


  const handleSave = () => {
    saveUserData()
    onSave(formData);
    onClose();
  };
  const handleConfirmation = () => {
    if (actionType === "save") {
      handleSave();
    }
    setConfirmationDialogOpen(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationDialogOpen(false);
  };
  return (
    isOpen && (
      <div className="modal">
        <div className="modal-content">
          <h2>Add New User</h2>
          <form className="form">
            <label>Username:</label>
            <input type="text" name="username" value={formData.username} onChange={handleChange} />

            {/* <label>Email:</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} /> */}

            <label>Password:</label>
            <input type="password" name="password" value={formData.password} onChange={handleChange} />

            {/* <label>First Name:</label>
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />

            <label>Last Name:</label>
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} /> */}

            {/* <label>Roles:</label>
            <Select
              options={rolesDropdown.props.options}
              value={formData.roles}
              onChange={(value) => setFormData({ ...formData, roles: value })}
              Open={isOpen}
            />
            {console.log(rolesDropdown.props.options)}
            <label>Groups:</label>
            <Select
              options={groupsDropdown.props.options}
              value={formData.groups}
              onChange={(value) => setFormData({ ...formData, groups: value })}
              multiSelect
              Open={isOpen}
            /> */}
          </form>
          <div className="modal-actions">
            <button className="cancel-button" onClick={onClose}>Cancel</button>
            <button className="save-button" onClick={() => {
              setActionType("save");
              setConfirmationDialogOpen(true);
            }}>Save</button>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          onConfirm={handleConfirmation}
          onClose={handleCancelConfirmation}
          message={
            actionType === "delete"
              ? "Are you sure you want to delete the selected groups?"
              : "Are you sure you want to save the changes?"
          }
        />
      </div>
    )
  );
};

export default AddUserModal;
