import { useState, useEffect } from "react";
import CustomModal from "../CustomModal";
import Select from "react-select";
import "./style.css";

const ClassSummaryEditModal = ({
  show,
  classList,
  selectedData,
  handleClose,
  handleEditClass,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (selectedData) {
      setSelectedOption({
        value: selectedData.class,
        label: selectedData.class,
      });
      setQuantity(selectedData.quantity);
    }
  }, [selectedData, classList]);

  const customOptions = classList.map((item) => ({
    value: `${item.description} (${item.value})`,
    label: `${item.description} (${item.value})`,
  }));

  const onSelectedChange = (option) => {
    setSelectedOption(option);
  };

  const onChangeQuantity = (e) => {
    setQuantity(e.target.value);
  };

  const onEditClass = (e) => {
    e.preventDefault();
    handleEditClass(selectedOption, quantity, selectedData.index);
    setSelectedOption(null);
    setQuantity(1);
  };

  return (
    <CustomModal isVisible={show} onClose={handleClose}>
      <form className="class-summary-add-modal" onSubmit={onEditClass}>
        <h2 className="class-summary-add-modal-title">Aggiungi Classe</h2>
        <div className="class-summary-add-modal-content">
          <div className="class-summary-add-modal-form">
            <label>Class</label>
            <Select
              options={customOptions}
              required
              value={selectedOption}
              placeholder="Select an option"
              onChange={onSelectedChange}
            ></Select>
          </div>
          <div className="class-summary-add-modal-form">
            <label htmlFor="quantity">Quantità</label>
            <input
              type="number"
              name="quantity"
              value={quantity}
              id="quantity"
              placeholder="1"
              min={1}
              required
              onChange={onChangeQuantity}
            />
          </div>
        </div>
        <div className="class-summary-add-modal-btns">
          <button className="btn" type="submit">
            Save
          </button>
          <button className="btn" onClick={handleClose}>
            Annulla
          </button>
        </div>
      </form>
    </CustomModal>
  );
};

export default ClassSummaryEditModal;
