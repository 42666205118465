import "./ManageLayers.scss";
import { OptionCard } from "../optionCard/optionCard";
import { CloudUploadIcon } from "../../icons/cloudUploadIcon";
import { DeleteIcon } from "../../icons/deleteIcon";

export const ManageLayers = ({ onCrossClick, onMinimizeClick, onDeleteClick = ()=>{}, onUploadFile = ()=>{} }) => {
  const handleOnCrossClick = () => {
    onCrossClick && onCrossClick();
  };
  const handleOnMinimizeClick = () => {
    onMinimizeClick && onMinimizeClick();
  };
  // const handleOnCloudFileUpload = (e) => {};
  return (
    <OptionCard
      className="create-layer-container"
      onCrossClick={handleOnCrossClick}
      onMinimizeClick={handleOnMinimizeClick}
      title="Create Layer"
    >
      <div className="create-layer-option-body-container">
        <div className="upload-shape-file-container" onClick={onUploadFile}>
          <label htmlFor="upload-shape-file" className="upload-shape-file">
            <CloudUploadIcon />
            <span className="upload-shape-file-text">Upload Shapefile</span>
          </label>
        </div>
        <div className="delete-shape-file-container" onClick={onDeleteClick}>
          <DeleteIcon />
          <span className="delete-shape-file-container">Deleted Layers</span>
        </div>
      </div>
    </OptionCard>
  );
};
