export const CheckedboxIcon = ({
  fillColor = "#E15615",
  isChecked = false,
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.16602"
      y="0.5"
      width="23"
      height="23"
      rx="5.5"
      fill={ isChecked ? fillColor : "white"}
      stroke={ isChecked ? "white" : "#DDDDDD"}
    />
    {isChecked && <path
      d="M11.2435 15.1675C11.0435 15.1675 10.8535 15.0875 10.7135 14.9475L7.88352 12.1175C7.59352 11.8275 7.59352 11.3475 7.88352 11.0575C8.17352 10.7675 8.65352 10.7675 8.94352 11.0575L11.2435 13.3575L16.3835 8.2175C16.6735 7.9275 17.1535 7.9275 17.4435 8.2175C17.7335 8.5075 17.7335 8.9875 17.4435 9.2775L11.7735 14.9475C11.6335 15.0875 11.4435 15.1675 11.2435 15.1675Z"
      fill="white"
    />}
  </svg>
);
