import "./style.css";
import CustomTooltip from "../../../CustomTooltip";
import { AddIcon, ConfirmIcon, EditIcon, UndoIcon }  from "../../../../icons";

const ClassSummaryHeader = ({
  edit = false,
  viewMode = true,
  onSave,
  onClose,
  onEditMode,
  handleAddClass
}) => {
  return (
    <div className="class-summary-header">
      <div>
        <p className="class-summary-header-title">Classi di materiali</p>
      </div>
      {edit && (
        <div className="summary-right">
         { viewMode ? (
              <CustomTooltip text="Modifica elenco">
                <button className="class-button" onClick={onEditMode}>
                  <EditIcon fillColor="#e15615" />
                </button>
              </CustomTooltip>
          ) : (
            <>
              <CustomTooltip text="Conferma">
                <button className="class-button" onClick={onSave}>
                  <ConfirmIcon fillColor="#e15615" />
                </button>
              </CustomTooltip>
              <CustomTooltip text="Aggiungi classe">
                <button className="class-button" onClick={handleAddClass} >
                    <AddIcon fillColor="#e15615"  />
                </button>
              </CustomTooltip> 
              <CustomTooltip text="Annulla">
                <button className="class-button" onClick={onClose}>
                  <UndoIcon fillColor="#e15615" />
                </button>
              </CustomTooltip>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ClassSummaryHeader;
