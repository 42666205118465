import React, { useState } from "react";
import CustomModal from "../CustomModal";
import Select from "react-select";
import { CrossIcon } from "../../icons";

const ImageView = ({
  image,
  imageId,
  show,
  handleClose,
}) => {

  /* *************************************************************** */


  if(!image) return null;

  
  return (
    <CustomModal isVisible={show} onClose={handleClose}>
      <img
        src={image.url}
        style={{
          width:  "500px",
          height: "auto"
        }}
        className="main-image"
        alt={`Image ${image}`}
      />
      <div
        className="icon"
        onClick={() => handleClose()}
      ><CrossIcon/></div>
    </CustomModal>
  );
};

export default ImageView;
