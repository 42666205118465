import React, { createContext, useContext, useState } from "react";
// Create a new context for the selection values

const SelectionContext = createContext();
// Create a provider component

export const AppContext = ({ children }) => {
  const appName = process.env.REACT_APP_NAME;
  const appVersion = process.env.REACT_APP_VERSION;
  const [cassetteFilter, setCassetteFilter] = useState([])
  const [cassettaId, setCassettaId] = useState(null)
  const [comuni, setComuni] = useState([])
  const [layers, setLayers] = useState([])
  const [mapViewTarget, setMapViewTarget] = useState([])
  const [repertiFilter, setRepertiFilter] = useState([])
  const [repertoId, setRepertoId] = useState(null)
  const [userProfile, setUserProfile] = useState(null)
  const [singleSelect, setSingleSelect] = useState("")  // TO BE CHECKED AND POSSIBLY REMOVED
  const [multiSelectValue, setMultiSelectValue] = useState() // TO BE CHECKED AND POSSIBLY REMOVED
  const [selectCassette, setSelectCassette] = useState() // TO BE CHECKED AND POSSIBLY REMOVED
  const [selectReperti, setSelectReperti] = useState() // TO BE CHECKED AND POSSIBLY REMOVED
  const [selectPubblicazioni, setSelectPubblicazioni] = useState() // TO BE CHECKED AND POSSIBLY REMOVED
  const [multiSelectValueOption, setMultiSelectValueOption] = useState([]) // TO BE CHECKED AND POSSIBLY REMOVED


  return (
    <SelectionContext.Provider
      value={{
        appName,
        appVersion,
        cassettaId, setCassettaId,
        cassetteFilter, setCassetteFilter,
        comuni, setComuni,
        layers, setLayers,
        mapViewTarget, setMapViewTarget,
        repertiFilter, setRepertiFilter,
        repertoId, setRepertoId,
        singleSelect, setSingleSelect,   
        multiSelectValue, setMultiSelectValue,
        selectCassette, setSelectCassette,
        selectReperti, setSelectReperti,
        selectPubblicazioni, setSelectPubblicazioni,
        multiSelectValueOption, setMultiSelectValueOption,
        userProfile, setUserProfile
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

// Custom hook to use the selection context
export const useAppContext = () => useContext(SelectionContext);
