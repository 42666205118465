import axios from 'axios';
import config from '../config';

const client = axios.create({
  baseURL: `${config.archeoBaseUrl}/api/private/v3/layer/`,
});

client.interceptors.request.use((conf) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    conf.headers.Authorization = `Bearer ${token}`;
    conf.headers.accept = "*/*";
  }
  return conf; // This should return conf, not config
}, (error) => {
  return Promise.reject(error);
});


export const getLayer = async (layerId) => {
  const response = await client.get(`/get/${layerId}`); 
  return response.data;
};

