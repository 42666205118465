import React, { useState } from "react";
import CustomModal from "../../CustomModal";
import Select from "react-select";
import "./style.css";

const AddGroup = ({
  groups = [],
  userGroups = [],
  show,
  handleClose,
  handleAddGroup,
}) => {
  const [selectedGroup, setSelectedGroup] = useState(null);

  /* *************************************************************** */
  // ALL HANDLERS 

  const onSelectedGroup = (group) => {
    setSelectedGroup(group);
  };

  const onAddGroup = (e) => {
    e.preventDefault();
    handleAddGroup(userGroups.find( g => g.name === selectedGroup.label));
    setSelectedGroup(null);
  };


  /* *************************************************************** */

  const possibleNewGroups = userGroups.filter((group) => !groups.find((g) => g.name === group.name));

  return (
    <CustomModal isVisible={show} onClose={handleClose}>
      <form className="groups-add-modal" onSubmit={onAddGroup}>
        <h2 className="groups-add-modal-title">Aggiungi un gruppo di lavoro</h2>
        <div className="groups-add-modal-content">
          <div className="groups-add-modal-form">
            <Select
              options={possibleNewGroups.map((group) => { return { label: group.name, value: group.name } })}
              required
              value={selectedGroup ? selectedGroup : ""}
              placeholder="Select an option"
              onChange={onSelectedGroup}
            ></Select>
          </div>
        </div>
        <div className="groups-add-modal-btns">
          <button className="btn" type="submit">
            Aggiungi
          </button>
          <button className="btn" onClick={handleClose}>
            Cancella
          </button>
        </div> 
      </form>
    </CustomModal>
  );
};

export default AddGroup;
