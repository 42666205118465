import axios from 'axios';
import config from '../config';

const client = axios.create({
  baseURL: `${config.archeoBaseUrl}/api/private/v3/cassetta/`,
});

client.interceptors.request.use((conf) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    conf.headers.Authorization = `Bearer ${token}`;
    conf.headers.accept = "*/*";
  }
  return conf; // This should return conf, not config
}, (error) => {
  return Promise.reject(error);
});


export const getCassette = async (filter) => {
  let path = `/get?`;
  filter.forEach((f) => {
    if(f.value) path += `${f.key}=${f.value}&`;
  });
  const response = await client.get(path);
  return response.data;
};

export const getCassetta = async (id) => {
  const response = await client.get(`/get/${id}`);
  return response.data.cassetta;
};

export const createCassetta = async (data) => {
  const response = await client.post(`/save`, data);
  return response.data;
};


export const updateCassetta = async (data) => {
  const response = await client.put(`/update`, data);
  return response.data;
};

export const deleteCassetta = async (id) => {
  const response = await client.delete(`/delete/${id}`);
  return response.data;
};