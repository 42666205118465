import React, { useState,useEffect } from "react";
import "./AddGroupModal.scss";
import { Select } from "../index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog.jsx";
import { useNavigate } from "react-router-dom";
const AddUserModal = ({ isOpen, onClose, onSave, memberList, getGroupData, groupFlag, setEditing }) => {
  const navigate = useNavigate();
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false); // State for confirmation dialog
  const [actionType, setActionType] = useState(null);
  const token = localStorage.getItem("authToken");
  groupFlag=true;
  const [formData, setFormData] = useState({
    groupname: "",
    members: []
  });
  useEffect(() => {
    if (isOpen) {
      localStorage.removeItem("selectedValue");
      localStorage.removeItem("multiSelectedValues");
    }
  }, [isOpen])
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  // saving group data
  const saveGroupData = async () => {
    const url = "https://archeo.loreb.it/api/private/v3/group/save";
    try {
      const response = await fetch(url, {
        method: "POST", 
        headers: {
          "Content-Type": "application/json", 
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: formData. groupname 
        }),
      });
  
      if (!response.ok) {
        if (response.status === 401) {
          navigate("/signin");
          throw new Error(
            "Your session has ended. Redirecting you to the login page..."
          );
        } else {
          throw new Error("Network response was not ok");
        }
      } else if (response.ok) {
        toast.success("Successfully added a Group!");
        getGroupData()
        setEditing(false)
      }
  
      const apiData = await response.json();
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      setEditing(false)
    }
  };
  
  const handleSave = () => {
    saveGroupData()
    onSave(formData);
    onClose();
  };
  const handleConfirmation = () => {
    if (actionType === "save") {
      handleSave();
    } 
    setConfirmationDialogOpen(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationDialogOpen(false); 
  };
  return (
    isOpen && (
      <div className="modal">
        <div className="modal-content">
          <h2>Add New User</h2>
          <form className = "form">
            <label>Groupname:</label>
            <input type="text" name="groupname" value={formData.groupname} onChange={handleChange} />
            {/* <label>Members:</label>
            <input type="text" name="members" value={formData.members} onChange={handleChange} /> */}

            {/* <label>Roles:</label>
            <Select
              options={rolesDropdown.props.options}
              value={formData.roles}
              onChange={(value) => setFormData({ ...formData, roles: value })}
              multiSelect
            /> */}

            <label>Members:</label>
            <Select
              options={memberList.props.options}
              value={formData.groups}
              onChange={(value) => setFormData({ ...formData, groups: value })}
              multiSelect
            />
          </form>
          <div className="modal-actions">
            <button className = "cancel-button" onClick={onClose}>Cancel</button>
            <button className = "save-button"onClick={() => {
              setActionType("save");
              setConfirmationDialogOpen(true);
            }}>Save</button>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          onConfirm={handleConfirmation}
          onClose={handleCancelConfirmation}
          message={
            actionType === "delete"
              ? "Are you sure you want to delete the selected groups?"
              : "Are you sure you want to save the changes?"
          }
        />
      </div>
    )
  );
};

export default AddUserModal;
