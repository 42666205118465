export const RotateLeft = ({
  height = "24px",
  width = "24px",
  fillColor = "#fff",
}) => {
  return (
    <svg
      style={{ height, width }}
      viewBox="0 0 24 24"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM12 18.58C8.83 18.58 6.25 16 6.25 12.83C6.25 11.69 6.58 10.58 7.22 9.64C7.45 9.3 7.92 9.2 8.26 9.43C8.6 9.66 8.7 10.13 8.47 10.47C8 11.17 7.76 11.99 7.76 12.83C7.76 15.17 9.67 17.08 12.01 17.08C14.35 17.08 16.26 15.17 16.26 12.83C16.26 10.59 14.52 8.75 12.32 8.59L12.73 8.89C13.06 9.13 13.14 9.6 12.89 9.94C12.65 10.27 12.18 10.35 11.84 10.1L9.9 8.69C9.83 8.64 9.77 8.58 9.72 8.51C9.71 8.5 9.7 8.49 9.69 8.47C9.65 8.4 9.62 8.32 9.6 8.24C9.58 8.14 9.58 8.05 9.59 7.95C9.6 7.91 9.61 7.87 9.62 7.83C9.64 7.76 9.68 7.7 9.72 7.63C9.74 7.62 9.76 7.6 9.78 7.58L11.44 5.68C11.71 5.37 12.19 5.34 12.5 5.61C12.81 5.88 12.84 6.36 12.57 6.67L12.2 7.1C15.28 7.2 17.76 9.74 17.76 12.85C17.75 16 15.17 18.58 12 18.58Z"
        fill={fillColor}

      />
    </svg>
  );
};
