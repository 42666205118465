import "./SignInForm.scss";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputComponent } from "../InputComponent/InputComponent";
import { LeftArrowIcon } from "../../icons/leftArrowIcon";
import config from "../../config";
import { useState } from "react";
import { useAppContext } from "../Context/AppContext";


export function SignInForm() {
  // const { updateProfileName } = useGeneralContext();
  const { setUserProfile } = useAppContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  // updateProfileName(email);
  localStorage.removeItem("ambientiLid");
  localStorage.removeItem("sitiLid");
  localStorage.removeItem("saggiLid");
  localStorage.removeItem("repertiLid");
  const handleOnChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleOnChangePassword = (e) => {
    setPassword(e.target.value);
  };
  localStorage.removeItem("cbcValue")
  localStorage.removeItem("AREA_SCAVO");
  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await fetch(
        config.archeoBaseUrl + "/api/public/v3/auth/login",
        {
          mode: "cors",
          method: "POST",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "accept": "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            password: password,
          }),
        }
      );

      const text = await response.text();
      let data;
      try {
        data = text ? JSON.parse(text) : {};
      } catch (e) {
        throw new Error("Failed to parse response as JSON");
      }

      if (response.ok) {
        setUserProfile(data.user);
        const token = data.token;
        const roles = data.roles;
        const authorities = data.authorities;
        const groups = data.groups;

        // const scope = data.scope;
        localStorage.setItem("authToken", token);
        // localStorage.setItem("expiresIn", expiresIn);
        localStorage.setItem("username", username);
        localStorage.setItem("permissions", { roles, authorities, groups });
        navigate("/Home");

        // Handle Logout
        /*
        setTimeout(() => {
          handleLogout();
        }, expiresIn * 1000);
        */
      } else {
        switch (response.status) {
          case 401:
            setError("Login or password does not match.");
            break;
          case 404:
            setError("API endpoint not found.");
            break;
          case 500:
            setError("Internal server error. Please try again later.");
            break;
          default:
            setError(data.message || "Login failed. Please try again.");
            break;
        }
      }
    } catch (error) {
      setError("Server error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("username");
    navigate("/signin");
  };

  return (
    <>
    <form className="sign-in-form">
      <div className="header-container">
        <h1 className="title">Sign In</h1>
        <p className="description">
          Enter your details to access your account.
        </p>
      </div>
      {error && <p className="error-message">{error}</p>}
      <InputComponent
        label={"Nome utente"}
        type={"username"}
        placeholder="Inserire nome utente"
        onChange={handleOnChangeUsername}
        labelClassName={"input-label"}
      />
      <div className="password-container">
        <div className="label-container">
          <label htmlFor="password" className="password-text">
            Password
          </label>
          <Link to={"/resetpassword"} href="#" className="forgot-password">
            Forgot Password?
          </Link>
        </div>
        <InputComponent
          type="password"
          placeholder="Inserire password"
          htmlFor={"password"}
          labelClassName={"input-label"}
          onChange={handleOnChangePassword}
        />
      </div>
      <button onClick={handleLogin} className="button-login-container">
        <span className="button-text">Login </span>
        <LeftArrowIcon />
      </button>
    </form>
    
    {/* <TokenRemoval */}
    {/* // exp = {5}> */}

    {/* </TokenRemoval> */}
    </>
  );
}
