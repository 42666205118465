import React  from "react";
import "../GroupsFormField.scss";
import isColorDark from '../../../helpers/utils/isColorDark';


const GroupsSpace = ({ groups, handleDeleteGroup, edit }) => {
  return (
    <div className="groups-space-container">
      {groups.map((group, index) => {
        const backgroundColor = group.color ? group.color : "#ccc";
        const textColor = isColorDark(backgroundColor) ? "#fff" : "#000";

        return (
          <div
            className="group-name-container"
            key={index}
            style={{ backgroundColor }}
          >
            <p className="group-name-text" style={{ color: textColor }}>
              {group.name}
            </p>
            {edit && groups.length > 1 && (
              <button
                className="delete-button"
                onClick={() => handleDeleteGroup(group)}
                style={{ color: "#000", backgroundColor: "#fff" }}
              >
                x
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GroupsSpace;