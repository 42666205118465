import React from 'react'

const Bar = ({ animationDuration, progress }) => (
  <div
    style={{
      background: '#29d',
      height: 4,
      left: 0,
      marginLeft: `${(-1 + progress) * 90}%`,
      position: 'fixed',
      top: 80,
      transition: `margin-left ${animationDuration}ms linear`,
      width: '100%',
      zIndex: 1031,
    }}
  ></div>
)

export default Bar