import "./SocialIcon.scss";
export const SocialIcon = ({ icon, isActive, onClick, label="" }) => {
  return (
    <div
      className={`sidebar-social-icon-container ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      {icon}
      {!isActive && <div className="show-preview-label">{label}</div>}
    </div>
  );
};
