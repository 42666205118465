export const ArchiveIcon = ({
  height = "24px",
  width = "24px",
  fillColor = "#fff"}) => {
  return (
    <svg
      viewBox="0 0 980.75 1000"
      fill={fillColor}
      height={height}
      width={width}
    >
      <path d="M839.75 250v50h-696v-50c0-14.667 4.333-26.333 13-35 8.667-8.667 17-13.667 25-15h608c4 0 8.667.333 14 1s12.667 5.333 22 14 14 20.333 14 35m-148-150c4 0 8.667.333 14 1s12.667 5.333 22 14 14 20.333 14 35h-498c0-14.667 4.333-26.333 13-35 8.667-8.667 17-13.667 25-15h410m248 200c22.667 21.333 35.333 36.667 38 46 4 12 4 30 0 54l-76 450c-2.667 14.667-9.333 26.333-20 35-10.667 8.667-20 13.667-28 15h-14-696c-34.667 0-54.667-16.667-60-50-4-17.333-17-91.667-39-223s-35-207-39-227c-6.667-14.667-7.667-29.333-3-44 4.667-14.667 8-23.333 10-26 2-2.667 9-9.333 21-20l10-10 30-30v80h836v-80l30 30m-248 270V470h-70v80h-260v-80h-68v100c0 33.333 16 50 48 50h300c14.667 0 26.333-4 35-12 8.667-8 13-16 13-24l2-14" />
    </svg>
  );
};
