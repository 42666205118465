const XLSX = require("xlsx");
const jsonToXlsx = (jsonArray, outputPath) => {
  const formattedData = jsonArray.map((row) => {
    const newRow = {};
    for (const key in row) {
      newRow[key] = row[key].value;
    }
    return newRow;
  });
  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  XLSX.writeFile(workbook, outputPath);
}

export default jsonToXlsx;

