export const LayerIcon = ({
  height = "24px",
  width = "24px",
  fillColor = "#fff",
}) => {
  return (
    <svg
      style={{ height, width }}
      viewBox="0 0 24 24"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.37 4.89L13.51 2.28C12.65 1.9 11.35 1.9 10.49 2.28L4.62999 4.89C3.14999 5.55 2.92999 6.45 2.92999 6.93C2.92999 7.41 3.14999 8.31 4.62999 8.97L10.49 11.58C10.92 11.77 11.46 11.87 12 11.87C12.54 11.87 13.08 11.77 13.51 11.58L19.37 8.97C20.85 8.31 21.07 7.41 21.07 6.93C21.07 6.45 20.86 5.55 19.37 4.89Z"
        fill={fillColor}
      />
      <path
        d="M12 17.04C11.62 17.04 11.24 16.96 10.89 16.81L4.15001 13.81C3.12001 13.35 2.32001 12.12 2.32001 10.99C2.32001 10.58 2.65001 10.25 3.06001 10.25C3.47001 10.25 3.80001 10.58 3.80001 10.99C3.80001 11.53 4.25001 12.23 4.75001 12.45L11.49 15.45C11.81 15.59 12.18 15.59 12.5 15.45L19.24 12.45C19.74 12.23 20.19 11.54 20.19 10.99C20.19 10.58 20.52 10.25 20.93 10.25C21.34 10.25 21.67 10.58 21.67 10.99C21.67 12.11 20.87 13.35 19.84 13.81L13.1 16.81C12.76 16.96 12.38 17.04 12 17.04Z"
        fill={fillColor}
      />
      <path
        d="M12 22C11.62 22 11.24 21.92 10.89 21.77L4.15001 18.77C3.04001 18.28 2.32001 17.17 2.32001 15.95C2.32001 15.54 2.65001 15.21 3.06001 15.21C3.47001 15.21 3.80001 15.54 3.80001 15.95C3.80001 16.58 4.17001 17.15 4.75001 17.41L11.49 20.41C11.81 20.55 12.18 20.55 12.5 20.41L19.24 17.41C19.81 17.16 20.19 16.58 20.19 15.95C20.19 15.54 20.52 15.21 20.93 15.21C21.34 15.21 21.67 15.54 21.67 15.95C21.67 17.17 20.95 18.27 19.84 18.77L13.1 21.77C12.76 21.92 12.38 22 12 22Z"
        fill={fillColor}
      />
    </svg>
  );
};
