import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { layersStyle } from '../../constants/layersStyle';


// Define the mapStyle function
export const mapStyle = (layerType, geometryType) => {
  const style = layersStyle[layerType.toLowerCase()];

  const styleOptions = {
    stroke: new Stroke({
      color: style.stroke.color ? style.stroke.color : "rgba(0, 0, 255, 1)",
      width: style.stroke.width ? style.stroke.width : 1
    }),
  };

  // Apply fill only to polygon geometries
  if (geometryType.toLowerCase() === 'polygon' || geometryType.toLowerCase() === 'multipolygon') {
    styleOptions.fill = new Fill({
      color: style.fill.color ? style.fill.color : "rgba(255, 255, 255, 0.4)",
    });
  }

  // Apply circle style to point geometries
  if (geometryType.toLowerCase() === 'point' || geometryType.toLowerCase() === 'multipoint') {
    styleOptions.image = new CircleStyle({
      radius: style.circle?.radius ? style.circle.radius : 5,
      fill: new Fill({
        color: style.circle?.fillColor ? style.circle.fillColor : "rgba(0, 0, 0, 0.5)",
      }),
      stroke: new Stroke({
        color: style.circle?.strokeColor ? style.circle.strokeColor : "rgba(0, 0, 0, 1)",
        width: style.circle?.strokeWidth ? style.circle.strokeWidth : 1
      })
    });
  }

  return new Style(styleOptions);
};

export default mapStyle