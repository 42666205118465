import { useState, useEffect } from "react";
import { getBibliografia } from "../../api/bibliografia.js";

export const useBibliografia = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchBiografia = async () => {
      try {
        const response = await getBibliografia();
        setData(response);
      } catch (error) {
        console.log("fetch-biblografia-->>", error);
      }
    };

    fetchBiografia();
  }, []);

  return data;
};