import { useLocation} from "react-router-dom";
import cassetta  from "../../constants/dataStructure/cassetta";

export default function PopUpList({
    key,
    value,
    currentItems,
    onSave,
    type,
    onChange,
    disabled,
  }) {
    const location = useLocation();
    const pathname = location.pathname;
  
    //if (pathname !== "/cassetteRecord/edit") {
    //   return null;
    //}
    const handleEditInputChange = (e) => {
      onChange(e.target.value);
    };
  
    return (
      <div className="input-field">
        <div className="input-label">{cassetta[key] ? cassetta[key].label : ""}</div>
        <input
          className="input-value"
          value={value}
          type={type}
          onChange={handleEditInputChange}
          disabled={disabled}
        />
      </div>
    );
  }