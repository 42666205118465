export const ExclamationCircleIcon = ({
  strokeWidth = 1.5,
  height = "24px",
  width = "24px",
  viewBox = "0 0 24 24",
  fillColor = "#E15615",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    style={{ height, width }}
    viewBox={viewBox}
    strokeWidth={strokeWidth}
    stroke={fillColor}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
    />
  </svg>
);
