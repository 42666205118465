export const layersStyle = {
  comune: { 
    fill: { color: "rgba(255, 255, 255,0.4)" },
    stroke: { color: "rgba(0, 0, 0,0.8)", width: 1 },
    circle: { radius: 5, fillColor: "rgba(255, 0, 0, 0.8)", strokeColor: "rgba(0, 0, 0, 1)", strokeWidth: 1 }
  },
  sito: { 
    fill: { color: "rgba(0, 0, 0,0.2)" },
    stroke: { color: "rgba(0, 0, 0, 0.8)", width: 1 },
    circle: { radius: 5, fillColor: "rgba(0, 0, 255, 0.8)", strokeColor: "rgba(0, 0, 0, 1)", strokeWidth: 1 }
  },
  ambiente: { 
    fill: { color: "rgba(0, 0, 0,0.2)" },
    stroke: { color: "rgba(0, 0, 0, 0.8)", width: 1 }
  },
  saggio: { 
    fill: { color: "rgba(0, 0, 0,0.2)" },
    stroke: { color: "rgba(0, 0, 0, 0.8)", width: 1 }
  },
}
