import React from "react";
import "./Breadcrumbs.scss";
import { LeftIcon } from "../../icons";

function Breadcrumbs({ breadcrumbsData = [] }) {
  return (
    <>
      {" "}
      <div className="archeological-data">
        <div
          className="single-bread-crum"
          key={breadcrumbsData[breadcrumbsData.length - 1].id}
        >
          {".. "}
          <LeftIcon />
          <div className="data-label">
            {breadcrumbsData[breadcrumbsData.length - 1].label}
          </div>
        </div>
        {breadcrumbsData.map((data) => (
          <div className="multi-bread-crum" key={data.id}>
            {" "}
            <div className="data-label">{data.label}</div>{" "}
            {data.iconUrl && <LeftIcon />}{" "}
          </div>
        ))}{" "}
      </div>{" "}
    </>
  );
}

export default Breadcrumbs;
