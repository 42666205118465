import regex from "../../regex";

const validatePubblicazioni = (data) => {
  let issues = [];
  if (regex.uuid.test(data["id"]) === false)
    issues.push("Il campo 'ID' della tua pubblicazione manca o non è valido.");
  return issues;
};

export default validatePubblicazioni;
