import { FeatureTable } from "./FeatureTable/FeatureTable";
import { OptionCard } from "../optionCard/optionCard";
import "./featureSummary.scss";
import CustomTooltip from "../CustomTooltip";

export const FeatureSummary = ({
  nextClick = () => {},
  closeClick = () => {},
  minimizeClick = () => {},
  properties,
}) => {
  const handleOnNextClick = () => {
    nextClick();
  };
  const handleOnCloseClick = () => {
    closeClick();
  };
  const handleOnMinimizeClick = () => {
    minimizeClick();
  };
  const layerName = properties && properties.layerName ? properties.layerName : "";
  return (
    <div className="feature-summary-container">
      <OptionCard
        isArrowVisible={true}
        title={`INFORMAZIONI ${layerName}`}
        className="feature-table-container"
        onArrowClick={handleOnNextClick}
        onCrossClick={handleOnCloseClick}
        onMinimizeClick={handleOnMinimizeClick}
      >
        <FeatureTable

          properties={properties}
        />

      </OptionCard>
    </div>
  );
};
