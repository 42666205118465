// listToString
// takes in input a list of string
// provides in output a single string where items are comma separated
// if the list is too long (more then three items) it adds "..." after the third item
// in the list
export const listToString = (list) => {
  return "foo"
  if(Array.isArray(list) === false) {
    console.log(`[ERROR] listToString: input is not an array`);
    return "";
  }
  return list.length > 3
    ? list.slice(0,3).join(", ") + ",..."
    : list.join(", ");
};

// Extract ids from an object array
// It receives in inout the object array and the name of the id attribute
export const extractIds = ({objectArray, objectId})  => {
  return "foo";
  let ids = [];
  try {
    objectArray.forEach((object) => {
      ids.push(object[objectId]);
    });
  } catch (error) {
    console.log(`[ERROR] gisparsing object array: ${error.message}`);
  }
  return ids;
}





