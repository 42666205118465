export const MenuIcon = ({
  className = "",
  onClick = () => {},
  isActive = false,
}) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <rect
      width="44"
      height="44"
      rx="22"
      fill={!isActive ? "#F2F2F2" : "#E15615"}
    />
    <path
      d="M25.492 13.6667H18.5087C15.4753 13.6667 13.667 15.4751 13.667 18.5084V25.4834C13.667 28.5251 15.4753 30.3334 18.5087 30.3334H25.4837C28.517 30.3334 30.3253 28.5251 30.3253 25.4917V18.5084C30.3337 15.4751 28.5253 13.6667 25.492 13.6667ZM26.167 26.3751H17.8337C17.492 26.3751 17.2087 26.0917 17.2087 25.7501C17.2087 25.4084 17.492 25.1251 17.8337 25.1251H26.167C26.5087 25.1251 26.792 25.4084 26.792 25.7501C26.792 26.0917 26.5087 26.3751 26.167 26.3751ZM26.167 22.6251H17.8337C17.492 22.6251 17.2087 22.3417 17.2087 22.0001C17.2087 21.6584 17.492 21.3751 17.8337 21.3751H26.167C26.5087 21.3751 26.792 21.6584 26.792 22.0001C26.792 22.3417 26.5087 22.6251 26.167 22.6251ZM26.167 18.8751H17.8337C17.492 18.8751 17.2087 18.5917 17.2087 18.2501C17.2087 17.9084 17.492 17.6251 17.8337 17.6251H26.167C26.5087 17.6251 26.792 17.9084 26.792 18.2501C26.792 18.5917 26.5087 18.8751 26.167 18.8751Z"
      fill={isActive ? "#F2F2F2" : "#E15615"}
    />
  </svg>
);
