
import { OptionCard } from "../optionCard/optionCard";
import "./legend.scss";

// Inside the Legend component
export const Legend = ({ legends, onCrossClick, legendStyle }) => {
  return (
    <OptionCard
      title="Legend"
      className="legend-info-card-container"
      onCrossClick={onCrossClick}
      childrenClassName="legend-children-container"
    >
      {legends?.map((legend, idx) => (
        <div className="layer-icon-and-text-wrappper" key={idx}>
           <div className="legend-box" style={{ backgroundColor: legendStyle, height: "50px", width: "50px" }} />
           <img
            src={legendStyle}
            alt="Map Legend"
            style={{ position: "absolute", top:"80px", left: "10px" }}
          />
        </div>
      ))}
    </OptionCard>
  );
};
