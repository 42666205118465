import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Header from "./Components/Header/Header";

// HOME PAGE
import HomePage from "./Components/HomePage/HomePage";

// USER ACCOUNT HANDLING
import ResetPasswordForm from "./Components/PasswordChangePage/PasswordChangePage.jsx";
import PasswordChangePage from "./Components/PasswordChangePage";
import LoginSection from "./Components/LoginPageText/LoginPageText";

// MAP VIEW
import MapViewPage from "./Components/MapViewPage/MapViewPage";

// CASSETTE
import Cassette from "./Components/Cassette/Cassette";
import AddCassetta from "./Components/Cassette/AddCassetta/AddCassetta.jsx";
import EditCassetta from "./Components/Cassette/EditCassetta/EditCassetta";

// REPERTI
import Reperti from "./Components/Reperti/Reperti";
import AddReperto from "./Components/Reperti/AddReperto";
import EditReperto from "./Components/Reperti/EditReperto";

// PUBBLICAZIONI
import Pubblicazioni from "./Components/Pubblicazioni";
import EditPublicazioni from "./Components/Pubblicazioni/EditPubblicazioni/EditPublicazioni.jsx";
import AddPublicazioni from "./Components/Pubblicazioni/AddPubblicazioni/AddPubblicazioni.jsx";

// ARCHIVIO STORICO
import Archivio from "./Components/Archivio";

// Movimentazione
import Movimentazione from "./Components/Movimentazione";

import UsersDetailsTable from "./Components/UsersDetailsTable/UsersDetailsTable";

import RolesDetailsTable from "./Components/RolesDetailsTable/RolesDetailsTable";
import GroupsDetailsTable from "./Components/GroupsDetailsTable/GroupsDetailsTable";
import { AuthPage } from "./Components/AuthPage/AuthPage";
import { SignInForm } from "./Components/SignInForm/SignInForm";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";


function App() {

  return (
    <div className="App">
       <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
       />
      <Header/>
         {/* ###### END-USER ROUTES ##### */}
         {/* ***** PROFILE HANDLING ****** */}
        <Routes>
          <Route
            exact
            path="/resetpassword"
            element={
              <AuthPage>
                <ResetPasswordForm />
              </AuthPage>
            }
          />
          <Route
            exact
            path="/"
            element={
              <AuthPage>
                <LoginSection />
              </AuthPage>
            }
          />
        <Route
            exact
            path="/passwordchange"
            element={
              <AuthPage>
                <PasswordChangePage />
              </AuthPage>
            }
          />
          <Route
            exact
            path="/signin"
            element={
              <AuthPage>
                <SignInForm/>
              </AuthPage>
            }
          />

        <Route
          exact
          path="/home"
          element={
            <AuthPage>
              <HomePage />
            </AuthPage>
          }
        />

        {/* ***** MAP VIEW ****** */}
        <Route
          exact
          path="/MapView"
          element={<PrivateRoute element={MapViewPage} />}
        />

        {/* ***** CASSETTE ****** */}
        <Route
          exact
          path="/cassette"
          element={<PrivateRoute element={Cassette} />}
        />
        <Route
          exact
          path="/cassette/view"
          element={<PrivateRoute element={EditCassetta} />}
        />
        <Route
          exact
          path="/cassette/edit"
          element={<PrivateRoute element={EditCassetta} />}
        />
        <Route
          exact
          path="/cassette/add"
          element={<PrivateRoute element={AddCassetta} />}
        />

        {/* ***** REPERTI ****** */}
        <Route
          exact
          path="/reperti"
          element={<PrivateRoute element={Reperti} />}
        />
        <Route
          exact
          path="/reperti/edit"
          element={<PrivateRoute element={EditReperto} />}
        />
        <Route
          exact
          path="/reperti/view"
          element={<PrivateRoute element={EditReperto} />}
        />
        <Route
          exact
          path="/reperti/add"
          element={<PrivateRoute element={AddReperto} />}
        />

        {/* ***** PUBBLICAZIONI ****** */}
        <Route
          exact
          path="/pubblicazioni"
          element={<PrivateRoute element={Pubblicazioni} />}
        />
        <Route
          exact
          path="/pubblicazioni/view"
          element={<PrivateRoute element={EditPublicazioni} />}
        />
        <Route
          exact
          path="/pubblicazioni/add"
          element={<PrivateRoute element={AddPublicazioni} />}
        />

        {/* ***** ARCHIVIO STORICO ****** */}
        <Route
          exact
          path="/archivio"
          element={<PrivateRoute element={Archivio} />}
        />

        {/* ***** MOVIMENTAZIONE****** */}
        <Route
          exact
          path="/movimentazione"
          element={<PrivateRoute element={Movimentazione} />}
        />

        {/* ##### ADMIN ROUTES  ##### */}
        <Route
          exact
          path="/users"
          element={<PrivateRoute element={UsersDetailsTable} />}
        />

        <Route
          exact
          path="/roles"
          element={<PrivateRoute element={RolesDetailsTable} />}
        />
        <Route
          exact
          path="/groups"
          element={<PrivateRoute element={GroupsDetailsTable} />}
        />
      </Routes>
    </div>
  );
}

export default App;
