export const CloudIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1604 15.1963C15.9035 12.8996 12.2078 12.8674 9.91115 15.1243C9.29538 15.7294 8.84373 16.4349 8.57313 17.1916C8.48876 17.4053 8.42089 17.6192 8.36953 17.8333C7.86619 19.7346 8.35089 21.8591 9.83916 23.3735C11.3274 24.888 13.4431 25.4097 15.3529 24.9396C15.5678 24.892 15.7829 24.8279 15.9981 24.7473C16.7593 24.4899 17.4727 24.0507 18.0884 23.4455C20.3851 21.1886 20.4173 17.4929 18.1604 15.1963ZM13.8867 21.8496C13.6392 21.8475 13.4256 21.7466 13.2702 21.5885C13.1148 21.4304 13.0177 21.2151 13.0199 20.9676L13.0275 20.0932L12.1201 20.0852C11.8726 20.0831 11.659 19.9822 11.5036 19.8241C11.3482 19.666 11.2511 19.4507 11.2533 19.2032C11.2575 18.7247 11.6569 18.3322 12.1353 18.3364L13.0428 18.3443L13.0504 17.4699C13.0546 16.9914 13.454 16.5989 13.9324 16.6031C14.4109 16.6073 14.8034 17.0067 14.7992 17.4852L14.7916 18.3596L15.6165 18.3668C16.095 18.371 16.4875 18.7704 16.4833 19.2488C16.4791 19.7273 16.0797 20.1198 15.6013 20.1156L14.7763 20.1084L14.7687 20.9828C14.7562 21.4695 14.3651 21.8538 13.8867 21.8496Z"
      fill="#E15615"
    />
    <path
      d="M23.5669 20.1716C23.1469 20.545 22.6919 20.86 22.2019 21.1166C21.7819 21.3266 21.3619 20.9183 21.4319 20.4516C21.4785 20.1133 21.5019 19.7633 21.5019 19.4016C21.5019 15.225 18.0952 11.8183 13.9185 11.8183C9.74187 11.8183 6.3352 15.225 6.3352 19.4016C6.3352 19.9383 6.39354 20.4516 6.49854 20.9533C6.5802 21.3616 6.2652 21.77 5.85687 21.665C1.24854 20.545 1.2252 13.4516 6.38187 13.0783H6.4402C2.6952 2.67162 18.4919 -1.49338 20.3002 9.44996C25.3519 10.0916 27.3935 16.8233 23.5669 20.1716Z"
      fill="#E15615"
    />
  </svg>
);
