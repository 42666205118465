import { useEffect, useRef } from "react";

const useOnOutsideClick = (
  elementRef,
  callback,
  eventType = "click"
) => {
  const callbackRef = useRef();
  callbackRef.current = callback;

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        elementRef.current &&
        !elementRef.current.contains(e.target) &&
        callbackRef.current
      ) {
        callbackRef.current(e);
      }
    };

    document.addEventListener(eventType, handleClickOutside);

    return () => {
      document.removeEventListener(eventType, handleClickOutside);
    };
  }, [callbackRef, elementRef, eventType]);
};

export default useOnOutsideClick;
