import { useState, useEffect } from "react";
import ClassSummaryHeader from "./Header";
import ClassSummaryTable from "./SummaryTable";
import ClassSummaryAddModal from "./AddModal";
import ClassSummaryEditModal from "./EditModal";
import { mergeObjectsByProperty } from "../../../helpers/utils/utils";

const ClassSummary = ({
  classList = [],
  data = [],
  edit = false,
  onSave,
  onClose,
}) => {
  const [displayData, setDisplayData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [viewMode, setViewMode] = useState(true);

  //useEffect(() => {
  //  if (!edit) setViewMode(true);
  //}, [edit]);

  useEffect(() => {
    if(Array.isArray(data)) setDisplayData(data);
    console.log("data", data);
  }, [data])

  const handleAddModal = () => {
    setShowAddModal(true);
  };

  const handleAddClass = (selectedOption, quantity) => {
    setShowAddModal(false);

    setDisplayData((prev) => {
      let newData = [...prev, { class: selectedOption.value, quantity }];
      let updatedData = mergeObjectsByProperty(newData, "class");
      return updatedData;
    });
  };

  const handleEditClass = (selectedOption, quantity, index ) => {
    setShowEditModal(false);

    setDisplayData((prev) => {
      const newData = [...prev];
      newData[index] = {
        class: selectedOption.value,
        quantity,
      };
      let updatedData = mergeObjectsByProperty(newData, "class");
      return updatedData;
    });
  };

  const handleDeleteItem = (index) => {
    setDisplayData((prev) => {
      const newData = [...prev];
      newData.splice(index, 1);
      return newData;
    });
  };

  const handleEditItem = (index) => {
    setShowEditModal(true);
    setSelectedData({...displayData[index], index});
  };

  const handleCloseAddModal = (e, newState) => {
    setShowAddModal(newState);
  };

  const handleSave = () => {
    onSave(displayData);
    setViewMode(true);
  };


  const onEditMode = () => {
    setViewMode(false);
  };
  return (
    <div>
      <ClassSummaryHeader
        edit={edit}
        viewMode={viewMode}
        onSave={handleSave}
        onClose={onClose}
        onEditMode={() => onEditMode()}
        handleAddClass={handleAddModal}
      />
      <ClassSummaryTable
        edit={!viewMode}
        data={displayData}
        handleDeleteItem={handleDeleteItem}
        handleEditItem={handleEditItem}
      />
      <ClassSummaryAddModal
        show={showAddModal}
        classList={classList}
        handleClose={handleCloseAddModal}
        handleAddClass={handleAddClass}
      />
      <ClassSummaryEditModal
        show={showEditModal}
        classList={classList}
        handleClose={() => setShowEditModal(false)}
        handleEditClass={handleEditClass}
        selectedData={selectedData}
      />
    </div>
  );
};

export default ClassSummary;
