import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./RolesDetailsTable.scss";
import ItemsTable from "../ItemsTable/ItemsTable";
import DataTableSubHeader from "../DataTableSubHeader/DataTableSubHeader";
import Dropdown from "../Dropdown/Dropdown";
import { Select, SubHeaderMobileOption } from "../index";
import {
  RightArrowIcon,
  TrashIcon,
  AddIcon,
  EditIcon,
  FiltersIcon,
  CrossIcon
} from "../../icons";
import AddRoleModal from "./AddRoleModal.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog.jsx";
const rolesDropdown = (
  <Select
    options={[
      { value: "Super Admin" },
      { value: "Invoice Manager" },
      { value: "Data Scientist" },
    ]}
    overlap
  />
);

const selectedHeaders = [
  { key: "rolename", label: "Nome ruolo", width: "300px", sortable: true }, 
  { key: "cassette", label: "Cassette", width: "300px", sortable: false },
  { key: "reperti", label: "Reperti", width: "300px", sortable: false },
  { key: "pubblicazioni", label: "Pubblicazioni", width: "300px", sortable: false },
];




const headers = [
  {
    label: "No.",
    key: "id",
    icon: <FiltersIcon />,
    className: "description",
  },
  {
    label: "Role Name",
    key: "rolename",
    icon: <FiltersIcon />,
    className: "description",
  },
  {
    label: "CASSETTE",
    key: "cassette",
    icon: <FiltersIcon />,
    className: "description cassette",
  },
  {
    label: "REPERTI",
    key: "reperti",
    className: "description cassette",
    icon: <FiltersIcon />,
  },
  {
    label: "PUBBLICAZIONI",
    key: "pubblicazioni",
    className: "description cassette",
    icon: <FiltersIcon />,
  },
];

const breadcrumbsData = [
  {
    id: 1,
    label: `Administration ${localStorage.getItem("username")}`,
    iconUrl: true,
  },
  {
    id: 2,
    label: "ROLES",
    iconUrl: "",
  },
];

const cassetteDropdown = (
  <Select
    options={[
      { value: "Select All", selectAll: true, id:"cassette"  },
      { value: "Read", id: "cassette-1" },
      { value: "Write", id: "cassette-2" },
      { value: "Create", id: "cassette-3" },
      { value: "Delete", id: "cassette-4" },
    ]}
    overlap
    multiSelect
  />
);
const repetiDropdown = (
  <Select
    options={[
      { value: "Select All", selectAll: true, id:"repeti"  },
      { value: "Read", id: "repeti-1" },
      { value: "Write", id: "repeti-2" },
      { value: "Create", id: "repeti-3" },
      { value: "Delete", id: "repeti-4" },
    ]}
    overlap
    multiSelect
  />
);
const pubblicazioniDropdown = (
  <Select
    options={[
      { value: "Select All", selectAll: true, id:"pubblicazioni" },
      { value: "Read", id: "pubblicazioni-1"},
      { value: "Write", id: "pubblicazioni-2" },
      { value: "Create", id: "pubblicazioni-3" },
      { value: "Delete", id: "pubblicazioni-4" },
    ]}
    overlap
    multiSelect
  />
);

function RolesDetailsTable() {
  const navigate = useNavigate();
  const [isEditing, setEditing] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMenuActive, setMenuActive] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [getAPIData, setGetAPIData] = useState();
  const [selectedItem,setSelectedItem] = useState()
  const length =  Object.values(checkedItems).filter(value => value === true).length;
  const [isDataFetched, setDataFetched] = useState(false);
  const token = localStorage.getItem("authToken");

  // const transformNewData = (users) => {
  //   return users.map((user, index) => ({
  //     id: { value: (index + 1).toString().padStart(3, "0"), component: "" },
  //     rolename: { value: user.name, component: "" },
  //     cassette: { value: user.authorities, component: "" },
  //     repeti: { value: user.authorities, component: "" },
  //     pubblicazioni: { value: user.authorities, component: "" },
  //     userId: user.id,
  //   }));
  // };
  const transformNewData = (users) => {
    return users.map((user, index) => ({
      id: { value: (index + 1).toString().padStart(3, "0"), component: "" },
      rolename: { value: user.name, component: "" },
      
      cassette: {
        value: user.authorities
          .filter(authority => authority.startsWith("CASSETTA_"))
          .map(authority => authority.split("_")[1])
          .join(", ") || "No authority value",
        component: (
          <Dropdown
            dropdownTexts={
              user.authorities
                .filter(authority => authority.startsWith("CASSETTA_")).length
                ? user.authorities
                    .filter(authority => authority.startsWith("CASSETTA_"))
                    .map(authority => ({
                      label: authority.split("_")[1],
                      onClick: () => {
                        console.log(`Cassette clicked: ${authority}`);
                      },
                    }))
                : [{ label: "No authority value", onClick: () => {} }]
            }
            defaultSelected={user.authorities.find(authority => authority.startsWith("CASSETTA_"))?.split("_")[1] || "No authority value"}
          />
        ),
      },
  
      reperti: {
        value: user.authorities
          .filter(authority => authority.startsWith("REPERTO_"))
          .map(authority => authority.split("_")[1])
          .join(", ") || "No authority value",
        component: (
          <Dropdown
            dropdownTexts={
              user.authorities
                .filter(authority => authority.startsWith("REPERTO_")).length
                ? user.authorities
                    .filter(authority => authority.startsWith("REPERTO_"))
                    .map(authority => ({
                      label: authority.split("_")[1],
                      onClick: () => {
                        console.log(`Repeti clicked: ${authority}`);
                      },
                    }))
                : [{ label: "No authority value", onClick: () => {} }]
            }
            defaultSelected={user.authorities.find(authority => authority.startsWith("REPERTO_"))?.split("_")[1] || "No authority value"}
          />
        ),
      },
  
      pubblicazioni: {
        value: user.authorities
          .filter(authority => authority.startsWith("PUBBLICAZIONI_"))
          .map(authority => authority.split("_")[1])
          .join(", ") || "No authority value",
        component: (
          <Dropdown
            dropdownTexts={
              user.authorities
                .filter(authority => authority.startsWith("PUBBLICAZIONE_")).length
                ? user.authorities
                    .filter(authority => authority.startsWith("PUBBLICAZIONE_"))
                    .map(authority => ({
                      label: authority.split("_")[1],
                      onClick: () => {
                        console.log(`Pubblicazioni clicked: ${authority}`);
                      },
                    }))
                : [{ label: "No authority value", onClick: () => {} }]
            }
            defaultSelected={user.authorities.find(authority => authority.startsWith("PUBBLICAZIONE_"))?.split("_")[1] || "No authority value"}
          />
        ),
      },
  
      userId: user.id,
    }));
  };
  
  // get API for roles
  const getRoleData = async () => {
    const url = "https://archeo.loreb.it/api/private/v3/role/get";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/signin");
          throw new Error(
            "Your session has ended. Redirecting you to the login page..."
          );
        } else {
          throw new Error("Network response was not ok");
        }
      }
      const apiData = await response.json();
      if (apiData.roles) {
        const transformedData = transformNewData(apiData.roles);
        setTableData(transformedData);
        setGetAPIData(transformedData);
        setDataFetched(true);
      }
      const data = await response.json();
      setGetAPIData(data);
      setDataFetched(true);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    getRoleData();
  }, [tableData.length]);

  //deleteing records of roles table
  const handleDelete = async () => {
    const remainingData = tableData.filter(async (item, index) => {
      if (checkedItems[index]) {
        const idValue = item.userId;

        const url = `https://archeo.loreb.it/api/private/v3/role/delete/${idValue}`;

        try {
          const response = await fetch(url, {
            method: "DELETE",
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            if (response.status === 401) {
              navigate("/signin");
              throw new Error(
                "Your session has ended. Redirecting you to the login page..."
              );
            } else {
              throw new Error("Network response was not ok");
            }
          } else if (response.ok) {
            toast.success(`Successfully deleted a user: ${idValue}`);
            // getRoleData()
          }
        } catch (error) {
          toast.error(`Error: ${error.message}`);
        }

        return false;
      }
      return true;
    });

    // Update state with the remaining data
    setTableData(remainingData);
    setCheckedItems({});
  };
  const handleUpdate = () => {
    const itemsToUpdate = tableData.filter((item, index) => checkedItems[index]);
    if (itemsToUpdate.length === 1) {
      setModalOpen(true); 
      setSelectedItem(itemsToUpdate[0]);
    }
  };
  
  
  // const handleDelete = () => {
  //   const remainingData = tableData.filter((_, index) => !checkedItems[index]);
  //   setTableData(remainingData);
  //   setCheckedItems({});
  //   console.log("deleted");

  // };

  const handleAddUser = (newUser) => {
    const newTableData = [
      ...tableData,
      {
        id: {
          value: (tableData.length + 1).toString().padStart(3, "0"),
          component: "",
        },
        rolename: { value: newUser.rolename, component: "" },
        cassette: { value: newUser.cassette, component: cassetteDropdown },
        repeti: { value: newUser.repeti, component: repetiDropdown },
        pubblicazioni: {
          value: newUser.pubblicazioni,
          component: pubblicazioniDropdown,
        },
        // description: { value: newUser.groups.join(", "), component: groupsDropdown },
      },
    ];
    setTableData(newTableData);
  };

  const editingOptions = (
    <div className="editing-tools-button-wrapper">
      <button
        className="action-button cancel-button"
        onClick={() => setEditing(false)}
      >
        <CrossIcon />
        <span>Cancel</span>
      </button>
      <button
        className="action-button save-button"
        onClick={() => setEditing(false)}
      >
        <AddIcon fillColor="white" />
        <span>Save</span>
      </button>
    </div>
  );

  const subHeaderRightNavOptions = {
    icons: isEditing
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            // onClick: () => {},
            onClick: () => setEditing(false),
            label: "Back",
          },
          {
            icon: <AddIcon onClick={() => setModalOpen(true)} />,
            onClick: () => {
              setModalOpen(true);
            },
            label: "Add",
          },
          length>0 &&{
            icon: (
              <TrashIcon
                onClick={() => {
                  setActionType("delete");
                  setConfirmationDialogOpen(true);
                }}
              />
            ),
            // onClick: () => {},
            label: "Delete",
          },
          length ===1 &&{
            icon: <EditIcon fillColor="#E15615" />,
            onClick: () => {
              handleUpdate()
            },
            label: "Edit",
          },
        ]
      : [
          {
            icon: <EditIcon fillColor="#E15615" height="28" width="28" />,
            onClick: () => setEditing(!isEditing),
            label: "Edit",
          },
        ],

    components: isEditing
      ? [editingOptions]
      : [
          <Dropdown
            dropdownTexts={[
              {
                label: "UTENTI",
                onClick: () => {
                  navigate("/users");
                },
              },
              {
                label: "GRUPPI",
                onClick: () => {
                  navigate("/groups");
                },
              },
              {
                label: "RUOLI",
                onClick: () => {
                  navigate("/roles");
                },
              },
            ]}
            defaultSelected="RUOLI"
          />,
        ],
  };
  const setComponentInData = () => {
    const newData = tableData.map((info) => ({
      ...info,
      // rolename: {...info.rolename, component:"" },
      cassette: { ...info.cassette, component: cassetteDropdown },
      repeti: { ...info.repeti, component: repetiDropdown },
      pubblicazioni: {
        ...info.pubblicazioni,
        component: pubblicazioniDropdown,
      },
      // lastName: { ...info.lastName, component: rolesDropdown },
    }));
    setTableData(newData);
  };
  // useEffect(() => {
  //   isEditing ? setComponentInData() : setTableData(data);
  //   // eslint-disable-next-line
  // }, [isEditing]);

  useEffect(() => {
    if (isDataFetched) {
      if (isEditing) {
        // setComponentInData();
        setTableData(getAPIData);
      } else {
        setTableData(getAPIData);
        localStorage.removeItem("selectedValue");
        localStorage.removeItem("multiSelectedValues");
      }
    }
    // eslint-disable-next-line
  }, [isEditing]);

  const handleConfirmation = () => {
    if (actionType === "delete") {
      handleDelete();
      // getRoleData()
    } else if (actionType === "save") {
      // Handle save action here
      setEditing(false);
    }
    setConfirmationDialogOpen(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationDialogOpen(false);
  };
  return (
    <>
      <div className="roles-container">
        <main className="roles-main-content">
          <DataTableSubHeader
            title={"ROLES"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <ItemsTable
            data={tableData}
            headers={selectedHeaders}
            isEditing={isEditing}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            components={!isEditing ? subHeaderRightNavOptions?.components : []}
            buttons={isEditing ? [editingOptions] : []}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
        <AddRoleModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleAddUser}
          pubblicazioniDropdown={pubblicazioniDropdown}
          repetiDropdown={repetiDropdown}
          cassetteDropdown={cassetteDropdown}
          // groupsDropdown={groupsDropdown}
          getRoleData={getRoleData}
          itemsToUpdate={selectedItem}
          setEditing={setEditing}
        />
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          onConfirm={handleConfirmation}
          onClose={handleCancelConfirmation}
          message={
            actionType === "delete"
              ? "Are you sure you want to delete the selected groups?"
              : "Are you sure you want to save the changes?"
          }
        />
      </div>
    </>
  );
}

export default RolesDetailsTable;
