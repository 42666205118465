import * as React from "react"
export const LegendIcon = ({
    height = "24px",
  width = "24px",
  fillColor = "#fff",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ height, width }}
    fill="none"
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M18.888 2.333H9.112c-4.247 0-6.779 2.532-6.779 6.779v9.776c0 4.247 2.532 6.779 6.779 6.779h9.776c4.247 0 6.779-2.532 6.779-6.779V9.112c0-4.247-2.532-6.779-6.779-6.779Zm1.599 17.057h-6.125a.881.881 0 0 1-.875-.875c0-.479.396-.875.875-.875h6.125c.49 0 .875.396.875.875a.874.874 0 0 1-.875.875Zm0-8.167h-6.125a.881.881 0 0 1-.875-.875c0-.478.396-.875.875-.875h6.125c.49 0 .875.397.875.875a.874.874 0 0 1-.875.875Zm-9.695-1.16a1.896 1.896 0 1 1-3.792 0 1.896 1.896 0 0 1 3.792 0ZM8.896 20.124a1.896 1.896 0 1 0 0-3.792 1.896 1.896 0 0 0 0 3.792Z"
      clipRule="evenodd"
    />
  </svg>
)

