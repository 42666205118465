import CustomReactSelect from "../../../CustomReactSelect/CustomReactSelect";
import InputAndSelect from "../InputAndSelect/InputAndSelect";
import { DeleteIcon } from "../../../../icons";
import "./OriginSection.scss";

const OriginSection = ({
  originNo,
  origin,
  tipoJson,
  headers,
  handleTipoChange,
  handleSelectChange,
  handleInputChange,
  handleKeyChange,
  handleDeleteOrigin,
}) => {
  const onSelectChange = (option, label) => {
    handleSelectChange("origin", option, label, origin?.id);
  };

  const onInputChange = (e, label) => {
    handleInputChange(e, "origin", label, origin?.id);
  };

  const onTipoChange = (option) => {
    handleTipoChange("origin", option, origin?.id);
  };

  const onKeyChange = (e) => {
    handleKeyChange(e, origin?.id);
  };

  const onDeleteOrigin = () => {
    handleDeleteOrigin(origin?.id);
  };

  return (
    <div className="modal-upload-section-wrapper">
      <div className="origin-delete-wrapper">
        <button onClick={onDeleteOrigin}>
          <DeleteIcon />
        </button>
      </div>
      <InputAndSelect
        options={headers}
        initSelect={origin?.selectedComune}
        initInput={origin?.comune?.value}
        displayAttr="value"
        label="Comune o sito"
        attr={"comune"}
        placeholder="Select Comune"
        onChange={onSelectChange}
        onInputChange={onInputChange}
      />
      <CustomReactSelect
        options={tipoJson}
        value={origin?.selectedTipo}
        displayAttr={"value"}
        label="Tipo"
        placeholder="Select tipo"
        onChange={onTipoChange}
      />
      <InputAndSelect
        options={headers}
        initSelect={origin?.selectedValore}
        initInput={origin?.valore?.value}
        displayAttr="value"
        label="Valore"
        attr={"valore"}
        placeholder="Select Valore"
        onChange={onSelectChange}
        onInputChange={onInputChange}
      />
      <div className="chiave-wrapper">
        <label className="chiave-label" htmlFor="chiave">
          Chiave di associazione GIS
        </label>
        <input
          id="chiave"
          name="chiave"
          type="text"
          value={origin?.chiave}
          onChange={onKeyChange}
        />
      </div>
    </div>
  );
};

export default OriginSection;
