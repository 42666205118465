import axios from 'axios';
import config from '../config';
import removeCircularReferences from "../helpers/utils/removeCircularReferences";

const client = axios.create({
  baseURL: `${config.archeoBaseUrl}/api/private/v3/reperto/`,
});

client.interceptors.request.use((conf) => {
  const token = localStorage.getItem("authToken");

  if (token) {
    conf.headers.Authorization = `Bearer ${token}`;
    conf.headers.accept = "*/*";
  }
  return conf; // This should return conf, not config
}, (error) => {
  return Promise.reject(error);
});


export const createReperto = async (data) => {
  const sanitizedData = removeCircularReferences(data);
  const response = await client.post(`/save`, sanitizedData);
  return response.data;
};

export const getReperti = async (filter) => {
  let path = `/get?`;
  filter.forEach((f) => {
    if(f.value) path += `${f.key}=${f.value}&`;
  });
  const response = await client.get(path);
  return response.data;
};


export const getReperto = async (id) => {
  const response = await client.get(`/get/${id}`);
  return response.data.reperto;
};


export const updateReperto = async (data) => {
  const sanitizedData = removeCircularReferences(data);
  const response = await client.put(`/update`, sanitizedData);
  return response.data;
};

export const deleteReperto = async (id) => {
  const response = await client.delete(`/delete/${id}`);
  return response.data;
};