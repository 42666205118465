export const CheckedLargeIcon = () => {
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="42" cy="42" r="42" fill="#C7E3C7" />
      <path
        d="M41.9998 28.6667C34.6532 28.6667 28.6665 34.6534 28.6665 42.0001C28.6665 49.3467 34.6532 55.3334 41.9998 55.3334C49.3465 55.3334 55.3332 49.3467 55.3332 42.0001C55.3332 34.6534 49.3465 28.6667 41.9998 28.6667ZM48.3732 38.9334L40.8132 46.4934C40.6265 46.6801 40.3732 46.7867 40.1065 46.7867C39.8398 46.7867 39.5865 46.6801 39.3998 46.4934L35.6265 42.7201C35.2398 42.3334 35.2398 41.6934 35.6265 41.3067C36.0132 40.9201 36.6532 40.9201 37.0398 41.3067L40.1065 44.3734L46.9598 37.5201C47.3465 37.1334 47.9865 37.1334 48.3732 37.5201C48.7598 37.9067 48.7598 38.5334 48.3732 38.9334Z"
        fill="#008000"
      />
    </svg>
  );
};
