export const PasswordIcon = () => (
<svg 
  xmlns="http://www.w3.org/2000/svg" 
  viewBox="-0.5 -0.5 16 16" id="Lock-Fill--Streamline-Mingcute-Fill" 
  height={24} width={24} >
    <desc>{"Lock Fill Streamline Icon: https://streamlinehq.com"}</desc>
    <g fill="none" fillRule="evenodd">
      <path d="M15 0v15H0V0h15ZM7.870625 14.536249999999999l-0.006874999999999999 0.00125 -0.044375 0.021875000000000002 -0.0125 0.0025 -0.00875 -0.0025 -0.044375 -0.021875000000000002c-0.00625 -0.0025 -0.011875 -0.000625 -0.015 0.003125l-0.0025 0.00625 -0.010625 0.2675 0.003125 0.0125 0.00625 0.008125 0.065 0.04625 0.009375 0.0025 0.0075 -0.0025 0.065 -0.04625 0.0075 -0.01 0.0025 -0.010625 -0.010625 -0.266875c-0.00125 -0.00625 -0.005625 -0.010625 -0.010625 -0.01125Zm0.16562500000000002 -0.07062500000000001 -0.008125 0.00125 -0.115625 0.058124999999999996 -0.00625 0.00625 -0.001875 0.006874999999999999 0.01125 0.26875 0.003125 0.0075 0.005 0.004375 0.12562500000000001 0.058124999999999996c0.0075 0.0025 0.014374999999999999 0 0.018125000000000002 -0.005l0.0025 -0.00875 -0.02125 -0.38375c-0.001875 -0.0075 -0.00625 -0.0125 -0.0125 -0.013749999999999998Zm-0.44687499999999997 0.00125a0.014374999999999999 0.014374999999999999 0 0 0 -0.016875 0.00375l-0.00375 0.00875 -0.02125 0.38375c0 0.0075 0.004375 0.0125 0.010625 0.015l0.009375 -0.00125 0.12562500000000001 -0.058124999999999996 0.00625 -0.005 0.0025 -0.006874999999999999 0.010625 -0.26875 -0.001875 -0.0075 -0.00625 -0.00625 -0.11499999999999999 -0.057499999999999996Z" strokeWidth={1} /><path fill="#000000" d="M3.75 5a3.75 3.75 0 1 1 7.5 0h0.625a1.25 1.25 0 0 1 1.25 1.25v6.25a1.25 1.25 0 0 1 -1.25 1.25H3.125a1.25 1.25 0 0 1 -1.25 -1.25V6.25a1.25 1.25 0 0 1 1.25 -1.25h0.625Zm3.75 -2.5a2.5 2.5 0 0 1 2.5 2.5H5a2.5 2.5 0 0 1 2.5 -2.5Zm1.25 6.25a1.25 1.25 0 0 1 -0.625 1.0825V10.625a0.625 0.625 0 1 1 -1.25 0v-0.7925A1.25 1.25 0 0 1 7.5 7.5a1.25 1.25 0 0 1 1.25 1.25Z" strokeWidth={1} />
    </g>
</svg>
);

