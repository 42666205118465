import React, { useState, useEffect } from "react";
import GroupsHeader from "./GroupsHeader";
import GroupsSpace  from "./GroupsSpace";
import AddGroup  from "./AddGroup";
import { useAppContext } from "../Context/AppContext";


const GroupsFormField = ({groups = [], edit, onChange, title = "Gruppi di lavoro"}) => {
  
  const { userProfile } = useAppContext();
  const [showAddModal, setShowAddModal] = useState(false);

  
  /* *************************************************************** */
  // ALL HANDLERS
  const handleAddGroup = (newGroup) => {
    onChange([...groups, newGroup]);
    setShowAddModal(false);
  };

  const handleDeleteGroup = (oldGroup) => {
    const newGroups = groups.filter((g) => g !== oldGroup);
    onChange(newGroups);
    setShowAddModal(false);
  };

/* *************************************************************** */

  return (
    <>
    <div className="goroups-container">
      <GroupsHeader
        edit={edit}
        onOpenModal={ () => setShowAddModal(true) }
        title={title}
      />
      <GroupsSpace 
        groups={groups}
        handleDeleteGroup={handleDeleteGroup}
        edit={edit}
      />
      <AddGroup
        show={showAddModal}
        groups={groups}
        userGroups={userProfile.groups}
        handleClose={() => setShowAddModal(false)}
        handleAddGroup={handleAddGroup}
      />
    </div>
    </> 
  );
  }

  export default GroupsFormField;