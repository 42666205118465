import React, { useState } from "react";
import ProvenienzeHeader from "./Header";
import ProvenienzeTable  from "./Table";
import ProvenienzeAddModal  from "./AddModal";

const Provenienze = ({provenienze = [], edit, onChange}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  
  const handleAddProvenienza = (provenienza) => {
    onChange([...provenienze, provenienza]);
    setShowAddModal(false);
  };

  const handleDeleteProvenienza = (provenienza) => {
    const newProvenienze = provenienze.filter((item) => item !== provenienza);
    onChange(newProvenienze);
    setShowAddModal(false);
  };

  return (
    <>
    <div className="provenienze-container">
      <ProvenienzeHeader
      edit={edit}
      onOpenModal={ () => setShowAddModal(true) }
      />
      <ProvenienzeTable 
      provenienze={provenienze} 
      handleDeleteProvenienza={handleDeleteProvenienza}
      edit={edit}
      />
      <ProvenienzeAddModal
      show={showAddModal}
      handleClose={() => setShowAddModal(false)}
      handleAddProvenienza={handleAddProvenienza}
      />
    </div>
    </> 
  );
  }

  export default Provenienze;