import React from "react";
import "./style.css";

const Tooltip = ({ text, visible, style }) => {
  return (
    <div className={`tooltip ${visible ? "visible" : ""}`} style={style}>
      {text}
    </div>
  );
};

export default Tooltip;
