import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import ProfileCard from "./ProfileCard/ProfileCard";
import { LogoIcon } from "../../icons";
import {EmailIcons} from "../../icons/emailIcon.jsx"
import { PhoneIcons } from "../../icons/phoneIcon.jsx";
import { DownIcons } from "../../icons/downIcon.jsx";
import { getMe } from "../../api";
import { useAppContext } from "../Context/AppContext";
import logoFull from "../../images/ArcheoSibari.png";
import logoMobile from "../../images/ArcheoSibariMobile.png";


function Header() {

  const { setUserProfile, userProfile } = useAppContext();
  const [linkClicked, setLinkClicked] = useState(false);
  const currentURL = useLocation();
  const navigate = useNavigate();

  const unProtectedRoutes = {
    "/signin": 1,
    "/": 1,
    "/resetpassword": 1,
  };

  const fetchUserProfile = async (data) => {
    try {
      const user = await getMe();
      setUserProfile(user);
    } catch (error) {
      if( unProtectedRoutes[currentURL.pathname] ) {
        // do nothing
      } else {
        navigate("/signin");
      }
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  function handleNavLinkClick(e) {
    const navOptionName = e.target.name;
    localStorage.removeItem("cbcValue"); 
    localStorage.removeItem("AREA_SCAVO");
    setLinkClicked(navOptionName);
  }    

  return (
    <header className="header">
      {unProtectedRoutes[currentURL.pathname] ? (
        <>
          <div className="logo-container">
            <img className="mobile-view-logo"  src={logoMobile} alt="Logo Archeo" />
            <img className="desktop-view-logo" src={logoFull} alt="Logo Archeo" />

            {/*
            <LogoIcon className="desktop-view-logo" />
            <LogoIcon className="mobile-view-logo" mobileView={true} />
            <div className="logo-text">
              <h1 className="logo-title">SISTEMA INFORMATIVO SIBARI</h1>
              <h2 className="logo-subtitle">
                ARCHEO: Gestione Dati Archeologici
              </h2>
            </div>
            */}
          </div>
          <div className="contact-info">
            <div className="email-container" >
              <EmailIcons />
              <span className="email">archeo@parcosibari.it</span>
            </div>
            <div className="phone-container">
              <PhoneIcon />
              <span className="phone">+39 0981* ****8</span>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="logo-container">
          <img className="mobile-view-logo" src={logoMobile} alt="Logo Archeo" />
            <img className="desktop-view-logo" src={logoFull} alt="Logo Archeo" />

            {/*}
            <LogoIcon className="desktop-view-logo" />
            <div className="logo-text">
              <h1 className="logo-title">SISTEMA INFORMATIVO SIBARI</h1>
              <h2 className="logo-subtitle">
                ARCHEO: Gestione Dati Archeologici
              </h2>
            </div>
            */}
          </div>
          <nav className="navigation">
            {/*<LogoIcon className="mobile-view-logo" mobileView /> */}
            <Link
              onClick={handleNavLinkClick}
              name="homepage"
              to="/Mapview"
              className={`nav-link ${
                (linkClicked === "homepage" ||
                  currentURL.pathname === "/Mapview") &&
                "active"
              }`}
            >
              Web GIS
            </Link>
            <Link
              onClick={(e) => {
                localStorage.removeItem("cbcValue");
                localStorage.removeItem("AREA_SCAVO");
                handleNavLinkClick(e); 
                
              }}
              name="dati"
              to="/Cassette"
              className={`nav-link ${
                (linkClicked === "dati" || currentURL.pathname === "/Cassette") &&
                "active"
              }`}
              
            >
              Archivio Dati
            </Link>
            <ProfileCard user={userProfile} />
          </nav>
        </>
      )}
    </header>
  );
}


const PhoneIcon = () => (
  <div className="icon-container">
    <PhoneIcons/>
  </div>
);

export default Header;
